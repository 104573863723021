<div id="content">
  <h2>退会手続き</h2>
  <h3>注意事項をご確認ください</h3>
  <div class="white-area">
    <p>
      退会されると、ロッカー利用履歴や決済情報などのお客様情報を全て破棄いたします。
      復旧はできませんので、ご注意ください。
    </p>

    <!-- 通常　ボタン非活性ここにチェック入ったら、ボタン活性 -->
    <input
      class="checkstatus"
      type="checkbox"
      name="kakunin"
      (click)="onClick()"
    />注意事項を確認しました
  </div>

  <div class="gray-area">
    <p>サービス改善のためアンケートにご協力ください</p>

    <p><input type="checkbox" name="nolocker" />近くにロッカーがない</p>
    <p>
      <input type="checkbox" name="expensive" />利用（予約）金額が高いと感じる
    </p>
    <p><input type="checkbox" name="how-use" />使い方がよくわからない</p>
    <p>
      <input type="checkbox" name="not-use" />ロッカーを使う機会があまりない
    </p>
    <p>ご意見・ご要望など（任意）</p>
    <textarea></textarea>

    <!-- <label for="switch">表示：</label> -->
    <!--［2］チェックボックスをshowプロパティにひも付け-->

    <!-- <div *ngIf="view_id; then eachContentView; else listView">###</div> -->
    <div id="button" *ngIf="checkstatus; then able else disable;">#</div>
      <ng-template #disable>
        <button class="large-white">
          退会する
        </button>
      </ng-template>
      <ng-template #able>
        <button (click)="unsubscribe()" class="large-black">
          退会する
        </button>
      </ng-template>
  </div>
</div>
<app-global-menu-component></app-global-menu-component>
