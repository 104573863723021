import {Component, AfterViewInit, ElementRef, OnInit} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { PhoneNumberAuthParameter } from '../authentication/telephone-number-auth/phone-number-auth-parameter';
import { PhoneNumberAuthenticationService } from '../authentication/services/phone-number-authentication-service';
import { Country } from '../country';
import { SpacerErrorHandleService } from '../lib/spacer-error-handle/spacer-error-handle.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SpacerWebappAuthenticationService } from '../authentication/services/spacer-webapp-authentication-service';
import {UserOperationLoggerService} from '../user-operation-logger.service';
import {environment} from '../../environments/environment';
import {ComponentPortal} from '@angular/cdk/portal';
import {MatSpinner} from '@angular/material/progress-spinner';
import { CommonService } from "../service/common.service";

@Component({
  selector: 'app-phone-number-form',
  templateUrl: './regist-phone-num.component.html',
  styleUrls: ['./regist-phone-num.component.scss'],
})
export class RegistPhoneNumComponent implements OnInit, AfterViewInit {
  public parameters = new PhoneNumberAuthParameter();
  private _listCountries: Array<Country>;
  private viewName: string;
  public get listCountries(): Iterable<Country> {
    return this._listCountries;
  }
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  // 開発環境での動作確認専用変数　ここから
  // (旧アカウントからの移行を、開発環境で試験するときに利用する)
  returnUrl: string;
  checkbox: string;
  other: string;
  private customKeyURL;
  private is_key;
  private is_login;
  private customRestrictURL;
  private is_restrict;
  private spacerId;
  private customConfirmURL;
  private is_confirm;
  // 開発環境での動作確認専用変数　ここまで
  constructor(
    private commonService: CommonService,
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private phoneNumberAuthService: PhoneNumberAuthenticationService,
    private spacerWebappAuthenticationService: SpacerWebappAuthenticationService,
    private http: HttpClient,
    private route: Router,
    private getroute: ActivatedRoute,
    private userOpeLogger: UserOperationLoggerService,
    private el: ElementRef,
    private errorHandleService: SpacerErrorHandleService
  ) {
    this.returnUrl = this.getroute.snapshot.queryParams['returnUrl'];
    this.checkbox = this.getroute.snapshot.queryParams['checkbox'];
    this.other = this.getroute.snapshot.queryParams['other'];
    this.returnUrl = this.returnUrl + "/" + this.checkbox + "@/" + this.other;
    this.commonService.setMessege(this.returnUrl);
  }
  // private i18nClientService: I18nClientService,
  // 現時点では i18nClientService は利用していないが、
  // 将来的には、国を選択するドロップダウンリストの中身を、
  // i18nClientService を用いて取得するようにしたい

  async sendPhoneNumber(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    if (!this.parameters.isAgreeTermOfUseAndPrivacyPolicy) {
      return;
    }
//     public now = new Date();
//     await this.db
//     .object('used/'+ now)
//      .set({ device: this.returnUrl,  fromId:this.checkbox,other:this.other}); 
   
    try {
      this.phoneNumberAuthService.completelyPhoneNumber = this.parameters.completelyPhoneNumber();
      this.spacerWebappAuthenticationService.confirmationResult = await this.phoneNumberAuthService.sendPhoneNumber(
        this.recaptchaVerifier
      );
      // // console.log('test');
      this.route.navigateByUrl('login/confirmation-code');
    } catch (error) {
      // console.log(error);
      this.errorHandleService.handle(error, alert);
    }
  }

  async ngOnInit() {
    if (this.showDebugView) {
      // 開発環境での動作確認用　ここから
      // (旧アカウントからの移行を、開発環境で試験するときに利用
      if (this.returnUrl) {
             const returnUrlArr = this.returnUrl.split('/');
        if (returnUrlArr[1] === 'key') {
          this.customKeyURL = 'spacerapp://spacer.live/login?returnUrl=%2Fkey%2F' + returnUrlArr[2];
          this.is_key = true;
          this.is_login = false;
        } else if (returnUrlArr[1] === 'restrict') {
          this.customRestrictURL = 'cm-app://spacer.live/login?returnUrl=%2Frestrict%2F' + returnUrlArr[2];
          this.is_restrict = true;
          this.spacerId = returnUrlArr[2];
        } else if (returnUrlArr[1] === 'confirm') {
          this.customConfirmURL = 'cm-app://spacer.live/login?returnUrl=%2Fconfirm%2F' + returnUrlArr[2];
          this.is_confirm = true;
        }
      }
      // 開発環境での動作確認用　ここまで
    }
  }

  ngAfterViewInit() {
    this.viewName = this.el.nativeElement.querySelectorAll('h2')[0].innerText;
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: function () {},
      }
    );
    // this.fetchListCountries();
    this.fetchListCountriesMock();
  }

  private fetchListCountries(): void {
    const i18nURL = 'http://192.168.55.10';
    const getObservable = this.http.get(i18nURL + '/countries.csv', {
      responseType: 'text',
    });
    const successProc = (response) => {
      this._listCountries = [];
      const listLine = response.split('\n');
      let lineIdx = 0;
      listLine.forEach((line) => {
        if (line) {
          if (lineIdx <= 0) {
            // ドロップダウンリストの先頭になにか表示する場合は、以下のようにすればよさそう
            // this._listCountries.push(new Country('言語を選択してください', ''));
          } else {
            const cells = line.split(',');
            this._listCountries.push(new Country(cells[0], cells[1]));
          }
        }
        lineIdx += 1;
      });
    };
    const errorProc = (error) => {
      this.errorHandleService.handle(error, alert);
    };
    getObservable.subscribe(successProc, errorProc);
  }

  private fetchListCountriesMock(): void {
    this._listCountries = [];
    this._listCountries.push(new Country('en', '+1'));
    this._listCountries.push(new Country('ja', '+81', 'selected'));
  }

  userOpeLog(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
  }

  get showDebugView(): boolean {
    return environment.showDebugView
  }

  // 開発環境での動作確認用　ここから
  // (旧アカウントからの移行を、開発環境で試験するときに利用
  loginGoogle() {
    // if (this.is_bluetooth) {
    this.socialSignIn(new firebase.auth.GoogleAuthProvider());
    // }
  }

  loginFb() {
    // if (this.is_bluetooth) {
    this.socialSignIn(new firebase.auth.FacebookAuthProvider());
    // }
  }

  private socialSignIn(provider) {
    return this.angularFireAuth
      .signInWithPopup(provider)
      .then(credential => {
        if (this.returnUrl) {
          this.route.navigateByUrl(this.returnUrl);
        } else {
          this.route.navigateByUrl('/');
        }
        //          this.authState = credential.user
        //          this.updateUserData()
      })
      .catch(error => console.log(error)
      );
  }
  // 開発環境での動作確認用　ここまで
}
