<div id="content">
  <h2>{{productForm.deviceNum}}の金額を設定する</h2>
  <p>
    金額を設定することで、鍵を共有する相手に代金をSPACER上で請求することができます。
  </p>
  <ng-container *ngIf="true">
    <mat-form-field class="input-item" appearance="outline" >
      <mat-label>送り主名</mat-label>
      <input id="senderName"
             matInput
             [(ngModel)]="productForm.senderName"
      />
    </mat-form-field>

    <mat-form-field class="input-item" appearance="outline">
      <mat-label>金額</mat-label>
      <input id="price"
             matInput
             pattern="^[0-9]+$"
             placeholder="1234"
             [(ngModel)]="productForm.price"
      />
    </mat-form-field>

    <mat-form-field class="input-item" appearance="outline">
      <mat-label>コメント（任意）</mat-label>
      <textarea id="desc"
                matInput
                placeholder="入力してください"
                [(ngModel)]="productForm.desc"
      ></textarea>
    </mat-form-field>

    <button class="large-black" (click)="clickShare()" >鍵を共有する</button>
    <button class="large-white" (click)="clickCancel()">キャンセル</button>
  </ng-container>
</div>
