import { Component, OnInit, Input } from '@angular/core';
import { Duration } from "moment";

@Component({
  selector: 'app-tick-timer',
  templateUrl: './tick-timer.component.html',
  styleUrls: ['./tick-timer.component.scss']
})
export class TickTimerComponent implements OnInit {
  @Input() diff;

  constructor() { }

  ngOnInit(): void {
  }

  get canShow() {
    // TODO implement
    return true;
  }

  get hours() {
    const days = this.diff.get('days');
    return Math.abs(days * 24 + this.diff.get('hours'));
  }

  get minutes() {
    return Math.abs(this.diff.get('minutes'));
  }

  get seconds() {
    return Math.abs(this.diff.get('seconds'));
  }
}
