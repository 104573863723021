import { Directive, Input, HostListener } from "@angular/core";
import { environment } from '../environments/environment';
import {DialogService} from './dialogs/dialog.service';

@Directive({
  selector: "[text-copy]"
})
export class TextCopyDirective {
  // Parse attribute value into a 'text' variable
  @Input("text-copy") text: string;

  constructor(private _dialog: DialogService) {}

  @HostListener("click") copyText() {
    // We need to create a dummy textarea with the text to be copied in the DOM
    const textArea = document.createElement("textarea");

    // Hide the textarea from actually showing
    textArea.style.position = "fixed";
    textArea.style.top = "-999px";
    textArea.style.left = "-999px";
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = "0";
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";

    // Set the texarea's content to our value defined in our [text-copy] attribute
    if (this.text.match("undefined")) {
      // alert("コピーに失敗しました。再度お試し下さい。");
      return;
    }

    // TODO : 鍵のURLを正しいものに変更する
    textArea.value = environment.apiUrl + 'keys/' + this.text;
    document.body.appendChild(textArea);
    // console.log(textArea.value);

    // This will select the textarea
    textArea.select();

    try {
      // Most modern browsers support execCommand('copy'|'cut'|'paste'), if it doesn't it should throw an error
      const successful = document.execCommand("copy");
      const msg = successful
        ? "URLの鍵をコピーしました。他の人に共有できます。"
        : "鍵のコピーに失敗しました。";
      // Let the user know the text has been copied, e.g toast, alert etc.
      if (successful) {
        this._dialog.copiedKeyURLDialog();
      } else {
        alert(msg);
      }
    } catch (err) {
      // Tell the user copying is not supported and give alternative, e.g alert window with the text to copy
      // alert("鍵コピーに失敗しました。");
    }

    // Finally we remove the textarea from the DOM
    document.body.removeChild(textArea);
  }
}

export const TEXT_COPY_DIRECTIVES = [TextCopyDirective];
