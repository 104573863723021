import { Term } from "../value-object/term";
import { Box } from "./box";
import { IRole } from "../interface/i-role";
import * as moment from 'moment';

export class Unit implements IRole {
  term: Term
  boxes: Box[]
  id: string;
  constructor() {
    this.term = new Term();
    this.boxes = [];
  }

  isPermitted(boxId: string, targetDate: moment.Moment): boolean {
    // console.log('DEBUG START Unit#isPermitted')
    // console.log('DEBUG START this.id');
    // console.log(this.id);
    // console.log('DEBUG END this.id');
    // console.log('DEBUG START boxId');
    // console.log(boxId);
    // console.log('DEBUG END boxId');


    // console.log('DEBUG START this.boxes');
    // console.log(this.boxes);
    // console.log('DEBUG END this.boxes');

    if (this.boxes.length < 1) {
      // console.log('DEBUG before return this.term.isPermitted(boxId, targetDate)')
      const termIsPermitted = this.term.isPermitted(boxId, targetDate);
      // console.log('DEBUG START termIsPermitted');
      // console.log(termIsPermitted);
      // console.log('DEBUG END termIsPermitted');
      // console.log('DEBUG START replacedThisId');
      // console.log(replacedThisId);
      // console.log('DEBUG END replacedThisId');

      const replacedArgId = boxId.replace('SPACER', '').slice(0, -1);
      // console.log('DEBUG START replacedArgId');
      // console.log(replacedArgId);
      // console.log('DEBUG END replacedArgId');

      // リファクタリングしたい
      // ↓ slice の第２引数にマイナスを指定することで、末尾から任意の桁を削除可能
      const boxIdIsValid = this.id === replacedArgId;
      // console.log('DEBUG START boxIdIsValid');
      // console.log(boxIdIsValid);
      // console.log('DEBUG END boxIdIsValid');
      // console.log('DEBUG START termIsPermitted');
      // console.log(termIsPermitted);
      // console.log('DEBUG END termIsPermitted');
      // console.log('DEBUG START boxIdIsValid');
      // console.log(boxIdIsValid);
      // console.log('DEBUG END boxIdIsValid');

      return termIsPermitted && boxIdIsValid;
    }
    return this.boxes.some(box => {
      // console.log('DEBUG this.boxes.some');
      // console.log('DEBUG START box');
      // console.log(box);
      // console.log('DEBUG END box');
      // console.log('DEBUG START boxId');
      // console.log(boxId);
      // console.log('DEBUG END boxId');

      const retVal = ( box.id === boxId && box.term.isPermitted(boxId, targetDate))
      // console.log('DEBUG START retval');
      // console.log(retVal);
      // console.log('DEBUG END retval');

      return retVal;
    });
  }
}
