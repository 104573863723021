import { Injectable } from '@angular/core';
import { HttpJsonService } from "./http-json.service";
import { ProductForm } from "./product-form";
import { environment } from "../environments/environment";
import * as firebase from "firebase";

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {

  constructor(
    private httpJsonService: HttpJsonService
  ) {
  }

  async sendProduct(product: ProductForm) {
    const url = `${environment.apiUrl}myLocker/product`;
    const uid = firebase.auth().currentUser.uid;
    const json = {
      spacerId: product.deviceNum,
      senderName: product.senderName,
      price: product.price,
      desc: product.desc,
    };
    return await this.httpJsonService.postJson(url, json);
  }
}
