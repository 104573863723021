<div id="content">
  <h2>旧アカウントからの移行</h2>
  <div *ngIf="email" class="auth-view">
    <p>既に連携済みです。</p>
    <hr /><br />
    <p>お名前： {{ name }}</p>
    <p>メールアドレス： {{ email }}</p>
    <p>旧チケット枚数： {{ h12 }}</p>
    <p>旧チケット返金額： ¥{{ amount }}</p>
    <hr /><br />
    <p>こちらの画面をスクリーンショットして頂き、振込先口座情報を下記メールアドレスまでご連絡ください。</p>
    <p>spacer.info2017@gmail.com</p>
  </div>
  <div *ngIf="!email" class="auth-view">
    <p>過去にAndroid版SPACERにてログインを行われていた方は、ログインに利用されていたアカウントとの連携を行ってください。
    連携を行うことで、過去に送受信したSPACERの鍵や、購入したチケットのご返金をする事が出来ます。</p>
    <!-- <button class="fb">facebook</button>
    <button class="google">Google</button> -->
    <div *ngFor="let nameMap of authProviderNameMap | keyvalue">
      <button class="btn-submit" [ngClass]="nameMap.key" (click)="addAuthProvider(nameMap.value)">
        {{ nameMap.key }}アカウントでログイン
      </button>
    </div>
  </div>
</div>
<app-global-menu-component></app-global-menu-component>
