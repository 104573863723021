import {Component, ElementRef, OnInit} from '@angular/core';
import { LockerService } from '../../locker.service';
import { DialogService } from '../../dialogs/dialog.service';
import { ActivatedRoute } from '@angular/router';
import { MembersRepositoryService } from '../../members-repository-service';
import { Router } from '@angular/router';
import {UserOperationLoggerService} from '../../user-operation-logger.service';

@Component({
  selector: 'app-detect',
  templateUrl: './detect.component.html',
  styleUrls: ['./detect.component.scss']
})
export class DetectComponent implements OnInit {
  h2 = 'ロッカーの鍵を検出します';
  id = '';
  button_able = true;
  private viewName: string;

  constructor(
    private _locker: LockerService,
    private _route: ActivatedRoute,
    private _dialog: DialogService,
    private _member: MembersRepositoryService,
    private userOpeLogger: UserOperationLoggerService,
    private el: ElementRef,
    private router: Router
  ) { }
  // 施錠()
  // 1.this.idがない場合(通常利用)
  // 2.qrから読み取りの場合
  // 3.予約してから通常利用の場合

  async detect(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    this.button_able = false;
      // 1.this.idがない場合(通常利用)
    if (!this.id) {
      // console.log('DEBUG__A1');
      if (await this._locker.detectSpacer()) {
        // console.log('DEBUG__A2');
        this._dialog.openedLockerDialog();
      }
    } else {

      // TODO implement ↓
      // await this._member.getSpacer(this.id); の戻り地に
      // startAt が必要。
      // members repository 内の
      // repository['myLockers'] に
      // startAt が存在するはずだが、
      // それがどこかしらのタイミングで削除されてしまっている、もしくは空値で上書きされている。
      // repository['myLockers'] の startAt が保持され続けるようにするか、
      // もしくは、sendKeyURL から値を取得するよう変更する。
      const spacer = await this._member.getSpacer(this.id);
      // // console.log(spacer);
      // 2.qrから読み取りの場合
      // console.log('DEBUG__X1');
      if (!spacer) {
        // console.log('DEBUG__B1 !spacer');
        if (await this._locker.detectSpacer(this.id)) {
          // console.log('DEBUG__B2 !spacer');
          this._dialog.openedLockerDialog();
        }
      } else {
        // 3.予約してから通常利用の場合
        // console.log('DEBUG__C1');
        if (!spacer['startAt']) {
          // console.log('DEBUG__C2 3.予約してから通常利用の場合');
          // console.log('DEBUG START spacer');
          // console.log(spacer);
          // console.log('DEBUG END spacer');
          if (await this._locker.detectSpacer(this.id)) {
            // console.log('DEBUG__C3 ab002');
            // console.log('DEBUG START spacer');
            // console.log(spacer);
            // console.log('DEBUG END spacer');
            this._dialog.openedLockerDialog();
          }
          // console.log('DEBUG__C4 ab002');
          // console.log('DEBUG START spacer');
          // console.log(spacer);
          // console.log('DEBUG END spacer');
        } else {
          // console.log('DEBUG__D1 START spacer');
          // console.log(spacer);
          // console.log('DEBUG END spacer');
          // console.log('DEBUG else');
          // 解錠の場合
          // console.log('DEBUG__D2');
          const urlkey = spacer['sendKeyURL'];
          // console.log('DEBUG__D3');
          const paidAt = await this._member.getCurrentHistoryBySpacerid(this.id);
          // console.log('DEBUG__D4');
          // console.log('DEBUG START spacer');
          // console.log(spacer);
          // console.log('DEBUG END spacer');
          // console.log('DEBUG__D5');
          if (await this._locker.openSpacer(this.id, urlkey, paidAt)) {
            this.router.navigateByUrl('thankyou/used');
          }
        }
      }
    }
    this.button_able = true;
  }
  private deviceForUserOpeLog(): string {
    return `対象ロッカー:SPACER${this.deviceNum}`;
  }
  get deviceNum(): string {
    return this.id;
  }
  clickSharingKey(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.router.navigateByUrl(`choice-share-type/${this.id}`)
  }
  clickSharingLastKey(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.router.navigateByUrl(`choice-share-type-last/${this.id}`)
  }

  clickOpenFree(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.router.navigateByUrl(`mylockers/detect/SPACER${this.deviceNum}`);
  }

  ngOnInit() {
    this.id = this._route.snapshot.paramMap.get('id');
    if (this.id) {
      this.h2 = this.id + 'の荷物に関して';
    }
    // this.viewName = this.el.nativeElement.querySelectorAll('h2')[0].innerText;
    this.viewName = 'ロッカーの鍵を検出します';
  }
}
