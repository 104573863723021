import { Component } from '@angular/core';
import { SpacerErrorHandleService } from '../lib/spacer-error-handle/spacer-error-handle.service';
import { UnsubscribeService } from '../authentication/services/unsubscribe-service';
import { CheckboxControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-confirmation-code-form',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent {
  checkstatus = false;
  onClick() {
    this.checkstatus = !this.checkstatus;
  }

  constructor(
    private unsubscribeService: UnsubscribeService,
    private errorHandleService: SpacerErrorHandleService
  ) {}

  async unsubscribe() {
    const errorHandleService = this.errorHandleService;
    try {
      await this.unsubscribeService.unsubscribe();
      alert('退会しました');
    } catch (error) {
      errorHandleService.handle(error, alert);
    }
  }
}
