<div id="content">
    <h2>その他のメニュー</h2>
    <br />check_with_text_gray.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/check_with_text_gray.svg" />
    <br />check_with_text_green.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/check_with_text_green.svg" />
    <br />cross_with_text_gray.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/cross_with_text_gray.svg" />
    <br />icon_arrow_right.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_arrow_right.svg" />
    <br />icon_card.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_card.svg" />
    <br />icon_change_notification_settings.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_change_notification_settings.svg" />
    <br />icon_change_payment_method.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_change_payment_method.svg" />
    <br />icon_check.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_check.svg" />
    <br />icon_check_opacity0-5.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_check_opacity0-5.svg" />
    <br />icon_copy_url.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_copy_url.svg" />
    <br />icon_cross.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_cross.svg" />
    <br />icon_current_location.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_current_location.svg" />
    <br />icon_in_use.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_in_use.svg" />
    <br />icon_information.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_information.svg" />
    <br />icon_information_opacity0-5.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_information_opacity0-5.svg" />
    <br />icon_locker.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_locker.svg" />
    <br />icon_locker_opacity0-5.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_locker_opacity0-5.svg" />
    <br />icon_map.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_map.svg" />
    <br />icon_map_opacity0-5.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_map_opacity0-5.svg" />
    <br />icon_menu.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_menu.svg" />
    <br />icon_menu_opacity0-5.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_menu_opacity0-5.svg" />
    <br />icon_phone_number.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_phone_number.svg" />
    <br />icon_reserved.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_reserved.svg" />
    <br />icon_search.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_search.svg" />
    <br />icon_spacer.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_spacer.svg" />
    <br />icon_unsubscribed.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_unsubscribed.svg" />
    <br />icon_use_history.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/icon_use_history.svg" />
    <br />pictgram_attention.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_attention.svg" />
    <br />pictgram_bluetooth.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_bluetooth.svg" />
    <br />pictgram_locker_closed.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_locker_closed.svg" />
    <br />pictgram_locker_opened.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_locker_opened.svg" />
    <br />pictgram_payment_completion.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_payment_completion.svg" />
    <br />pictgram_put_into_locker.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_put_into_locker.svg" />
    <br />pictgram_sync_locker_and_phone_large.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_sync_locker_and_phone_large.svg" />
    <br />pictgram_sync_locker_and_phone_small.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_sync_locker_and_phone_small.svg" />
    <br />pictgram_thanks_for_reserved.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_thanks_for_reserved.svg" />
    <br />pictgram_thankyou.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_thankyou.svg" />
    <br />pictgram_url_copied.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/pictgram_url_copied.svg" />
    <br />sign_check.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/sign_check.svg" />
    <br />sign_cross.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/sign_cross.svg" />
    <br />sign_in_use.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/sign_in_use.svg" />
    <br />sign_pin_check_gray.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/sign_pin_check_gray.svg" />
    <br />sign_pin_check_green.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/sign_pin_check_green.svg" />
    <br />sign_pin_cross.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/sign_pin_cross.svg" />
    <br />sign_reserved.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/sign_reserved.svg" />
    <br />splash.png ↓<br /><br /><img src="../../assets/images/ui-ver2.0/splash.png" />
    <br />tutorial_booking.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/tutorial_booking.svg" />
    <br />tutorial_payment.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/tutorial_payment.svg" />
    <br />tutorial_phone.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/tutorial_phone.svg" />
    <br />tutorial_share_key.svg ↓<br /><br /><img src="../../assets/images/ui-ver2.0/tutorial_share_key.svg" />
    
        
    <app-global-menu-component></app-global-menu-component>
</div>