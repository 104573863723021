import { Component, OnInit } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute } from "@angular/router";
import { HttpJsonService } from "../http-json.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"]
})
export class ConfirmComponent implements OnInit {
  is_iOS = false;
  uid: string;

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private httpjson: HttpJsonService,
    private route: ActivatedRoute
  ) {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      this.is_iOS = true;
    }
  }

  ngOnInit() {
    this.auth.onAuthStateChanged(async user => {
      if (!user) {
        return;
      }
      if (this.is_iOS) {
        alert("iPhoneアプリで使用して下さい。");
        return;
      }
      this.uid = user.uid;
      const id = this.route.snapshot.paramMap.get("id");
      const url = "https://spacer.live/confirm";

      let params = new HttpParams();
      params = params.append("confirm_id", id);
      params = params.append("uid", this.uid);

      const data = await this.httpjson.getJsonByPost(url, params);
      let message = "";
      if (data["result"] === "accept") {
        if (data["service_name"] === "crowdwash") {
          message =
            "SPACERとCrowdwashのID連携が完了しました\n引き続きCrowdwashをご利用になられる場合は、Crowdwashに改めてログインしていただきますようにお願いします";
        } else {
          message =
            "IDの連携が完了しました\n連携済みID一覧画面よりご確認下さい";
        }
      } else if (data["result"] === "time_over") {
        message = "時間の過ぎたIDです。再度登録をお願い致します。";
      } else if (data["result"] === "invalid_param") {
        message = "不正な値です";
      } else if (data["result"] === "id_error") {
        message = "確認用IDがありません";
      } else if (data["result"] === "already_exists") {
        message = "既に連携済みのユーザーです";
      }
      this.router.navigateByUrl("?message=" + message);
    });
  }
}
