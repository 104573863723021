<div class="device">{{ device }}</div>
<h2 class="share-key">QRコードをリーダーに読み込ませて開けてください<br>

<qr-code value="  {{urlKey}}  " [size]=250></qr-code>
</h2>
<div class="icons">
  <div class="container" >
  </div>
  <hr />

  <button class="large-black" (click)="clickMylocker()">マイロッカーへ</button>
</div>

