import { Component, OnInit, NgZone } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PaymentService } from "../payment.service";
import { PaymentAlertDialog } from "./payment-alert-dialog";
import { MatDialog } from "@angular/material/dialog";
import {MembersRepositoryService} from '../members-repository-service';
import {AngularFireAuth} from '@angular/fire/auth';
import {SpacerErrorAnnouncer} from '../lib/spacer-error-handle/spacer-error-handle.service';
import * as firebase from 'firebase';
import {AngularFireDatabase} from '@angular/fire/database';
import {last, map} from 'rxjs/operators';

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"]
})
export class PaymentComponent implements OnInit {
  loading: boolean = false;
  uid: string;
  errorInfo: string = null;
  status: string = null;
  currentCard = null; // 取得済のクレジットカード情報

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pService: PaymentService,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private auth: AngularFireAuth,
    private membersRepository: MembersRepositoryService,
    private db: AngularFireDatabase
  ) {
    // キャリア決済完了時 Spacer-Server-APIからのリダイレクト時の処理
    // statusとerrorInfoをURLパラメータから取得
    const cb = this.route.snapshot.queryParams.cb;
    const status = this.route.snapshot.queryParams.status;
    const errorInfo = this.route.snapshot.queryParams.errorinfo;
    if (typeof cb !== "undefined" && typeof status !== "undefined") {
      this.status = status;
      if (typeof errorInfo !== "undefined") {
        this.errorInfo = errorInfo;
      }
    }
  }

  ngOnInit() {
    this.auth.onAuthStateChanged(async user => {
      if (!user) {
        // console.log('DEBUG no user!!!');
        return;
      }
      // キャリア決済完了時 Spacer-Server-APIからのリダイレクト時の処理
      if (this.status) {
        this.onFinichedPaymentForCarrier();
      }
    });

    // PaymentService設定
    // この画面に遷移する前に、以下をそれぞれ設定してください
    // uid = ログイン中のユーザのuid (ex, ggZSYQHabBeoZOLb8T1Xs25HJhe2)
    // amount = 決済する金額 (ex, 1200)
    // type = Androidブラウザの場合は web, iOSブラウザの場合は iOS (ex, web)
    // spacerid = 決済するロッカーのspacerid (ex, SPACER9803)
    this.pService.uid = 'ggZSYQHabBeoZOLb8T1Xs25HJhe2'; // debug
    this.pService.amount = 1000; // debug
    this.pService.type = 'web'; // debug
    this.pService.spacerid = 'SPACER9803'; // debug
  }

  get amount() {
    return this.pService.amount;
  }

  /**
   * クレジットカード取得
   */
  async fetchCard() {
    this.currentCard = null;
    const result = await this.pService.fetchCreditCard();
    // console.log('fetchCard', result);
    if (result) {
      this.currentCard = {
        cardSeq: String(result['cardSeq']),
        cardNo: String(result['cardNo']),
        expire: String(result['expire'])
      };
    }
  }

  /**
   * クレジットカード決済
   */
  async paymentCreditCard() {
    if (!this.currentCard) {
      return;
    }
    this.loading = true;
    const paymentResult = await this.pService.execPaymentCreditCard(this.currentCard.cardSeq, false);
    let message = '';
    if (paymentResult.result) {
      // 決済成功時の処理
      message = '決済処理が成功しました。';
      // this.showAlert('決済処理が成功しました。');
    } else {
      // 失敗時の処理
      // this.showAlert(paymentResult.message);
      message = paymentResult.message;
    }
    new SpacerErrorAnnouncer(message).announce();
  }

  /**
   * クレジットカードの登録/変更/削除画面へ遷移
   */
  toEditCreditcard() {
    this.router.navigate(["/menu/creditcard"]);
  }

  /**
   * auケータイ払い決済処理開始
   */
  async toPaymentAu() {
    await this.execPaymentForCarrier("au");
  }

  /**
   * ドコモケータイ払い処理開始
   */
  async toPaymentDocomo() {
    await this.execPaymentForCarrier("docomo");
  }

  /**
   * ソフトバンクケータイ払い処理開始
   */
  async toPaymentSoftbank() {
    await this.execPaymentForCarrier("softbank");
  }

  /**
   * キャリア決済処理開始
   * @param carrierType キャリア au / docomo / softbank
   */
  async execPaymentForCarrier(carrierType) {
    this.loading = true;
    const paymentResult = await this.pService.execPaymentCarrier(carrierType, false);
    if (paymentResult.result) {
      // API成功時
      // 各キャリアの決済画面ページにリダイレクトします。
      // 各キャリアの決済画面での決済処理が完了したとき、
      // Spacer-Server-APIの `/payment/{キャリア}/callback` へとコールバックされます。
      // その後、WebAppの `/payment` にリダイレクトします。
      // リダイレクト時には、`cb=1&status={ステータスコード}` のURLパラメータがセットされます。
      // `payment.component.ts->constructor()->ngOnInit()->onFinichedPaymentForCarrier()`の中でステータスコードによる決済の成功/失敗処理を実装しています。
      const redirectParams = paymentResult.redirectParams;
      // console.log('redirect', redirectParams);
      this.redirectForCarrier(redirectParams.startUrl, redirectParams.asccessId, redirectParams.token);
    } else {
      // 失敗時の処理
      new SpacerErrorAnnouncer(paymentResult.message).announce();
      // this.showAlert(paymentResult.message);
    }
  }

  /**
   * キャリア決済画面へリダイレクト
   * @param url
   * @param accessId
   * @param token
   */
  redirectForCarrier(url, accessId, token) {
    const form = document.createElement("form");
    document.body.appendChild(form);

    const accessIdInput = document.createElement("input");
    accessIdInput.setAttribute("type", "hidden");
    accessIdInput.setAttribute("name", "AccessID");
    accessIdInput.setAttribute("value", accessId);
    form.appendChild(accessIdInput);

    const tokenInput = document.createElement("input");
    tokenInput.setAttribute("type", "hidden");
    tokenInput.setAttribute("name", "Token");
    tokenInput.setAttribute("value", token);
    form.appendChild(tokenInput);
    form.setAttribute("method", "post");

    form.setAttribute("action", url);
    form.submit();
  }

  /**
   * キャリア決済完了時
   */
  async onFinichedPaymentForCarrier() {
    const successMessage = "決済処理が成功しました。"
    let message = successMessage;
    let nextUrl = 'choice-share-type-last';
    if (this.status === "cancel") {
      message = "決済がキャンセルされました。";
      new SpacerErrorAnnouncer(message).announce();
      this.router.navigateByUrl(nextUrl);
      return;
    } else if (this.status === "error") {
      let errorCode = "";
      if (this.errorInfo) {
        errorCode = "\n\n エラーコード:" + this.errorInfo;
      }
      message =
        "決済処理に失敗しました。\n\n時間をおいて再度お試し下さい。" + errorCode;
      new SpacerErrorAnnouncer(message).announce();
      this.router.navigateByUrl(nextUrl);
      return;
    }

    // 支払履歴の取得
    const currentUser = firebase.auth().currentUser;
    const dbpath = `members/${currentUser.uid}/history`;
    const firstHistory = this.db.list(dbpath, ref2 => ref2.orderByKey().limitToLast(1));
    // const firstHistory = this.db.list(dbpath, ref2 => ref2.orderByKey().limitToFirst(1));

    firstHistory.snapshotChanges().pipe(
      map(changes => {
        return changes.map(async c => {
          const device = c.payload.val()['device'];
          const locker = await this.membersRepository.getMylockerBySpacerid(device);
          if (locker) {
            // console.log('DEBUG locker ', locker);
            nextUrl = this.resolveNextUrl(locker);
            this.router.navigateByUrl(nextUrl);
          } else {
            // console.log('DEBUG NO locker...');
          }
        })
      })
    ).toPromise().then(res => {
    }).catch(err => {
    })
    // console.log('DEBUG nextUrl:', nextUrl);
    // this.router.navigateByUrl(nextUrl);
  }

  private resolveNextUrl(val: any) {
    const date = new Date();
    const current =
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2) +
      ':' +
      ('0' + date.getSeconds()).slice(-2);

    if (val['expired'] < current) {
      return 'thankyou/afterManualCanceled';
    }
    if (val['isReserved'] === 'true') {
      return 'thankyou/afterReserve';
    } else {
      return `mylockers/detect/${val['device']}`;
    }
  }

  /**
   * メッセージダイアログを表示
   * @param message
   */
  showAlert(message) {
    this.hideLoading(() => {
      this.dialog.open(PaymentAlertDialog, { data: { message: message } });
    });
  }

  /**
   * ローディングを非表示にする
   * @param func
   */
  hideLoading(func) {
    this.ngZone.run(() => {
      this.loading = false;
      func();
    });
  }
}
