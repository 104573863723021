/**
 * 内部API /member/info のレスポンスを表す
 */
export class MemberInfo {
  readonly isFirstPaymentFree: boolean

  constructor(values: any) {
    if(values['isFirstPaymentFree']) {
      this.isFirstPaymentFree = values['isFirstPaymentFree']
    } else {
      this.isFirstPaymentFree = false
    }
  }
}
