import { Injectable } from "@angular/core";
import * as firebase from "firebase";

@Injectable({
  providedIn: "root"
})
export class SpacerWebappAuthenticationService {
  // 電話番号認証、Google や Facebook による認証に
  // 共通する処理やデータをまとめるための Service です。
  // コードクローンを減らす目的で、credential や confirmationResult を保持しているのですが、
  // 疎結合かつ凝集度を上げるためには、各 Service で個別に保持するように
  // リファクタリングするという手もあります。
  credential: firebase.auth.AuthCredential;
  confirmationResult;
  credentialsForPhoneNumberChange = {};
  constructor() {}

  // mainCredential へ subCredential を関連付ける。
  // 関連付けた後は、mainCredential のユーザだけが残り、subCredential のユーザは削除される。
  async linkCredentials(
    mainCredential: firebase.auth.AuthCredential,
    subCredential: firebase.auth.AuthCredential
  ) {
    try {
      const auth = firebase.auth();
      // サブとなるアカウントでログインする
      await auth.signInWithCredential(subCredential);
      // サブとなるアカウントを削除する
      await auth.currentUser.delete();
      // メインとなるアカウントでログインする
      await auth.signInWithCredential(mainCredential);
      // メインとなるアカウントに、サブとなるアカウントを関連付ける
      await auth.currentUser.linkWithCredential(subCredential);
    } catch (error) {
      // console.log(error);
      alert(error);
    }
  }
}
