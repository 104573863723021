<p id="purchase-text">お住まいの方へ荷物を渡す</p>
<p id="purchase-price-text">お住まいの方を選択して下さい</p>
<form>
  <select class="theme-purchase-select-btn" name="room" [(ngModel)]="room">
    <option *ngFor="let item of values | keyvalue" [value]="item.key"
      >{{item.key}}</option
    > </select
  ><br /><br />
  <button class="theme-purchase-btn" (click)="request()">カギを送る</button>
</form>
