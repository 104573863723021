import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, delay, retry, retryWhen, shareReplay, take} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

@Injectable()
export class HttpJsonRetryService {
  // クレジットカード情報の取得は、ログインユーザのUIDの取得後でないと失敗する。
  // それを考慮するため、リトライするための delay と count の数値はやや大きめに設定してある。
  // この数値が小さすぎると(リトライ試行回数が少なすぎると)、
  // クレジットカード情報が登録済みであるにも関わらず、未登録と判定される恐れがある。
  delay = 1000;
  count = 5;

  constructor(private http: HttpClient) {}

  // async getJson(url: string) {
  //   const response = await this.http.get(url).toPromise();
  //   if (response) {
  //     return response;
  //   } else {
  //     return;
  //   }
  // }

  async getJson(url: string) {
    // const response = this.http.get(url).pipe(
    //   retry(this.retryCount),
    //   catchError(() => {
    //     return EMPTY;
    //   }),
    //   shareReplay()
    // ).toPromise();
    const response = this.http.get(url).pipe(
      retryWhen(errors => errors.pipe(delay(this.delay), take(this.count)))
    ).toPromise();
    if (response) {
      return response;
    } else {
      return;
    }
  }

  /*  getJson(url: string) {
    return this._http.get(url).map(res => res.json());
  }*/

  // まだリトライできない　必要になったら実装する
  // async getJsonByPost(url: string, data: HttpParams) {
  //   return await this.http
  //     .post(url, data)
  //     .toPromise()
  //     .catch(err => {
  //       console.error('An error occurred:', err.error);
  //     });
  // }

  // まだリトライできない　必要になったら実装する
  // async postJson(url: string, json: Object) {
  //   const _json = JSON.stringify(json);
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   return await this.http
  //     .post( url, _json, { headers: headers })
  //     .toPromise()
  //     .catch(err => {
  //       console.error('An error occurred:', err.error);
  //     });
  // }
}
