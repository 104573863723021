<div id="content">
  <h2>電話番号の変更</h2>
  <div class="change-phone-num-conf-view">
    <form>
      <label for="confirmationCode"
        >{{ phone }} に送信された、6桁のコードを入力してください。</label
      >
      <div class="form-group">
        <input
          class="text-area"
          type="text"
          id="confirmationCode"
          [(ngModel)]="parameters.confirmationCode"
          name="confirmationCode"
        />
      </div>
      <button class="large-black" id="resendConfirmationCode">
        ログイン
      </button>
      <div class="flex">
        <h6 class="code-icon" type="submit" (click)="sendConfirmationCode()">
          認証コードの再送
        </h6>
        <img
          (click)="sendConfirmationCode()"
          class="icon"
          src="../../assets/images/ui-ver2.0/icon_arrow_right.svg"
        />
      </div>
      <div id="recaptcha-container"></div>
    </form>
  </div>
</div>
<app-global-menu-component></app-global-menu-component>
