import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { BluetoothRefService } from '../bluetooth-ref.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
// MEMO この Component の用途は不明です。推測ですが2019年以前のものかと。
export class LoginComponent implements OnInit {
  customKeyURL: string;
  customRestrictURL: string;
  customConfirmURL: string;
  returnUrl: string;
  email: string;
  password: string;
  spacerId: string;
  is_login = true;
  is_key = false;
  is_restrict = false;
  is_confirm = false;
  is_iOS = false;
  isKotlin = false;
  telephoneNumber = new FormControl('');

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    private bluetoothRef: BluetoothRefService
  ) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.auth.onAuthStateChanged(user => {
      if (user) {
        if (this.returnUrl) {
          if (this.returnUrl.match('tutorial')) {
            // // console.log('test');
            this.router.navigateByUrl('/put');
          }
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.router.navigateByUrl('/put');
        }
      }
    });

    if (this.returnUrl.match(/key/i) || this.returnUrl.match(/restrict/i) || this.returnUrl.match(/confirm/i)) {
      // iPad OS13移行の場合、、サファリでは、macと同様の表記になる。
      // UserAgent Sample ↓
      // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.129 Safari/537.36
      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        this.is_iOS = true;
      }

      // webで認証されていない場合、androidアプリと判断する
      if (this.returnUrl.match(/key/i) && navigator.userAgent.indexOf('Android') > 0) {
        this.isKotlin = true;
        return;
      }

      if (navigator.userAgent.match(/chrome|Chrome/i)) {
        if (this.is_iOS) {
          alert('Safariでこのリンクを開いてください。');
        }
      } else if (navigator.userAgent.match(/Safari/i)) {
        this.is_iOS = true;
      }
      if (this.bluetoothRef.bluetooth) {
        // Line ブラウザの場合、Chrome
        if (navigator.userAgent.match(/line|Line/i)) {
          alert('Google Chromeでこのリンクを開いてください。');
        }
        // androidのバージョンが6以上の確認。
        if (navigator.userAgent.indexOf('Android') > 0) {
          if (parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf('Android') + 8)) < 6) {
            alert('AndroidのOSのバージョンが古くうまく動作しない可能性があります。');
          }
        }
      } else {
        if (!navigator.userAgent.match(/Safari/i)) {
        }
      }
    } else {
      if (navigator.userAgent.indexOf('Android') > 0) {
        if (parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf('Android') + 8)) < 6) {
          alert('AndroidのOSのバージョンが古く、カギの開け閉めがうまく動作しない可能性があります。');
        }
      }
      if (!this.bluetoothRef.bluetooth) {
      }
    }
  }
  confirmed(confirm: string) {
    if (confirm === 'true') {
      this.is_login = true;
      this.is_key = false;
    };
  }

  ngOnInit() {
    if (this.returnUrl) {
      const returnUrlArr = this.returnUrl.split('/');
      if (returnUrlArr[1] === 'key') {
        this.customKeyURL = 'spacerapp://spacer.live/login?returnUrl=%2Fkey%2F' + returnUrlArr[2];
        this.is_key = true;
        this.is_login = false;
      } else if (returnUrlArr[1] === 'restrict') {
        this.customRestrictURL = 'cm-app://spacer.live/login?returnUrl=%2Frestrict%2F' + returnUrlArr[2];
        this.is_restrict = true;
        this.spacerId = returnUrlArr[2];
      } else if (returnUrlArr[1] === 'confirm') {
        this.customConfirmURL = 'cm-app://spacer.live/login?returnUrl=%2Fconfirm%2F' + returnUrlArr[2];
        this.is_confirm = true;
      }
    }
  }

  customUrl() {
    const returnUrlArr = this.returnUrl.split('/');
    if (returnUrlArr[1] === 'key') {
      window.open('spacerapp://spacer.live/login?returnUrl=%2Fkey%2F' + returnUrlArr[2], '_blank');
    } else if (returnUrlArr[1] === 'restrict') {
      window.open('cm-app://spacer.live/login?returnUrl=%2Frestrict%2F' + returnUrlArr[2], '_blank');
    } else if (returnUrlArr[1] === 'confirm') {
      window.open('cm-app://spacer.live/login?returnUrl=%2Fconfirm%2F' + returnUrlArr[2], '_blank');
    }
  }

}
