import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReserveApiService } from '../reserve-api.service';
import {CalcPriceApiService} from '../calc-price-api.service';
import {PaymentService} from '../payment.service';
import {ReserveCancelApiService} from '../reserve-cancel-api.service';
import {PrepaidService} from '../prepaid.service';
import {Overlay} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {MatSpinner} from '@angular/material/progress-spinner';
import { HttpJsonService } from '../http-json.service';
import {UserOperationLoggerService} from '../user-operation-logger.service';
import { environment } from '../../environments/environment';
import { SpacersUnitsRepositoryService} from '../spacers-units-repository-service';

@Component({
  selector: 'app-about-key-shareing',
  templateUrl: './about-key-shareing/about-key-shareing.component.html',
  styleUrls: ['./about-key-shareing/about-key-shareing.component.scss']
})
export class AboutKeyShareingComponent {
  constructor(
    public dialogRef: MatDialogRef<AboutKeyShareingComponent>,
    private userOpeLogger: UserOperationLoggerService,
    @Inject(MAT_DIALOG_DATA) public data: { test: 'testaa'; test2: 'test2' }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-about-prepaid',
  templateUrl: './about-prepaid/about-prepaid.component.html',
  styleUrls: ['./about-prepaid/about-prepaid.component.scss']
})
export class AboutPrepaidComponent {
  constructor(
    public dialogRef: MatDialogRef<AboutPrepaidComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-about-payment',
  templateUrl: './about-payment/about-payment.component.html',
  styleUrls: ['./about-payment/about-payment.component.scss']
})
export class AboutPaymentComponent {
  constructor(
    public dialogRef: MatDialogRef<AboutPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-opend-locker',
  templateUrl: './opend-locker/opend-locker.component.html',
  styleUrls: ['./opend-locker/opend-locker.component.scss']
})
export class OpenedLockerComponent {
  constructor(
    public dialogRef: MatDialogRef<OpenedLockerComponent>,
    private userOpeLogger: UserOperationLoggerService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { test: 'testaa'; test2: 'test2' }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  forword() {
    this.userOpeLogger.logViewAndButtonName('ロッカーの鍵が開きました', '次へ');
    this.router.navigateByUrl('/mylockers/kept');
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-reserve-caution',
  templateUrl: './reserve-caution/reserve-caution.component.html',
  styleUrls: ['./reserve-caution/reserve-caution.component.scss']
})
export class ReserveCautionComponent {
  readonly viewName = '予約前のご注意ください'
  constructor(
    public dialogRef: MatDialogRef<ReserveCautionComponent>,
    private userOpeLogger: UserOperationLoggerService,
    private router: Router,
    private reserveApiService: ReserveApiService,
    private calcPriceApiService: CalcPriceApiService,
    @Inject(MAT_DIALOG_DATA) public data: { test: 'testaa'; test2: 'test2' }
  ) {}

  onNoClick(): void {
    this.userOpeLogger.logViewAndButtonName(this.viewName, '戻る');
    this.dialogRef.close();
    this.router.navigateByUrl('maps');
  }
  async ok() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'OK');
    try {
      // console.log('DEBUG reserve 001');
      // 予約した瞬間から料金の計算が始まる。しかし、このタイミングで決済が行われるわけではない。よってコメントアウト
      // await this.paymentService.execPaymentCreditCard(this.paymentService.currentCard.cardSeq);
      // console.log('DEBUG reserve 002');
      // 予約を実行する(マイロッカーへデータを追加する)
      const response = await this.reserveApiService.reserve();
      // console.log('DEBUG reserve 003');
      // console.log('DEBUG result...');
      // console.log(response);
      if (response['result'] === 'true') {
        this.router.navigateByUrl('thankyou/afterReserve');
      } else if (response['result'] === 'false') {
        // console.log(response['error']);
        // console.log(response['error']['code']);
        if (response['error']['code'] === 'already_used') {
          alert('既に利用されているロッカーのため、予約できませんでした。');
          this.router.navigateByUrl('maps');
        }
      }
      this.dialogRef.close();

    } catch (error) {
      // console.log('ERROR....');
      // console.log(error);
    }
  }
}

@Component({
  selector: 'app-copied-key-url',
  templateUrl: './copied-key-url/copied-key-url.component.html',
  styleUrls: ['./copied-key-url/copied-key-url.component.scss']
})
export class CopiedKeyURLComponent {
  readonly viewName = 'URLをコピーしました';
  constructor(
    public dialogRef: MatDialogRef<ReserveCautionComponent>,
    private userOpeLogger: UserOperationLoggerService,
    @Inject(MAT_DIALOG_DATA) public data: { test: 'testaa'; test2: 'test2' }
  ) {}

  onNoClick(): void {
    this.userOpeLogger.logViewAndButtonName(this.viewName, '戻る');
    this.dialogRef.close();
  }
  ok() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'OK');
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-view-qr-code',
  templateUrl: './view-qr-code/view-qr-code.component.html',
  styleUrls: ['./view-qr-code/view-qr-code.component.scss']
})
export class ViewQRCodeComponent {
  readonly viewName = 'URLをコピーしました';
  qrurl = '';
  constructor(
    public dialogRef: MatDialogRef<ViewQRCodeComponent>,
    private userOpeLogger: UserOperationLoggerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.qrurl = this.data.url;
  }

  onNoClick(): void {
    this.data.url;

    this.userOpeLogger.logViewAndButtonName(this.viewName, '戻る');
    this.dialogRef.close();
  }
  ok() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'OK');
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method/select-payment-method.component.html',
  styleUrls: ['./select-payment-method/select-payment-method.component.scss']
})
export class SelectPaymentMethodComponent {
  constructor(
    public dialogRef: MatDialogRef<ReserveCautionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { test: 'testaa'; test2: 'test2' }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ok() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-caution-before-open',
  templateUrl: './caution-before-open/caution-before-open.component.html',
  styleUrls: ['./caution-before-open/caution-before-open.component.scss']
})
export class CautionBeforeOpenComponent {
  readonly viewName = 'ロッカーを開ける前のご注意ください';
  constructor(
    public dialogRef: MatDialogRef<ReserveCautionComponent>,
    private userOpeLogger: UserOperationLoggerService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'キャンセル');
    this.dialogRef.close();
  }
  ok() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'OK');
    this.router.navigateByUrl(this.data.url);
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-manual-cancel-caution',
  templateUrl: './manual-cancel-caution/manual-cancel-caution.component.html',
  styleUrls: ['./manual-cancel-caution/manual-cancel-caution.component.scss']
})
export class ManualCancelCautionComponent {
  readonly viewName = 'ロッカーの予約をキャンセルします。よろしいですか？';
  constructor(
    public dialogRef: MatDialogRef<ReserveCautionComponent>,
    private userOpeLogger: UserOperationLoggerService,
    private router: Router,
    private cancelApiService: ReserveCancelApiService,
    @Inject(MAT_DIALOG_DATA) public data: { test: 'testaa'; test2: 'test2' }
  ) {}

  onNoClick(): void {
    this.userOpeLogger.logViewAndButtonName(this.viewName, '戻る');
    this.dialogRef.close();
  }
  ok() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'お支払へ');
    const deviceNum = this.cancelApiService.id.replace(/\t/g, "")
    this.router.navigateByUrl(`confirm-payment/${deviceNum}/manualCanceled`);
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-require-card',
  templateUrl: './require-card/require-card.component.html',
  styleUrls: ['./require-card/require-card.component.scss']
})
export class RequireCardComponent {
  readonly viewName = 'クレジットカード情報を登録してください';
  constructor(
    public dialogRef: MatDialogRef<ReserveCautionComponent>,
    private userOpeLogger: UserOperationLoggerService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { test: 'testaa'; test2: 'test2' }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ok() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'OK');
    this.router.navigateByUrl('menu/creditcard');
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-about-cancel-price',
  templateUrl: './about-cancel-price/about-cancel-price.component.html',
  styleUrls: ['./about-cancel-price/about-cancel-price.component.scss']
})
export class AboutCancelPriceComponent {
  readonly viewName = '予約のキャンセルについて';
  constructor(
    public dialogRef: MatDialogRef<ReserveCautionComponent>,
    private userOpeLogger: UserOperationLoggerService,
    @Inject(MAT_DIALOG_DATA) public data: { test: 'testaa'; test2: 'test2' }
  ) {}

  onNoClick(): void {
    this.userOpeLogger.logViewAndButtonName(this.viewName, '戻る');
    this.dialogRef.close();
  }
  ok() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'OK');
    // this.router.navigateByUrl('payment/creditcard');
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-before-remove-card',
  templateUrl: './before-remove-card/before-remove-card.component.html',
  styleUrls: ['./before-remove-card/before-remove-card.component.scss']
})
export class BeforeRemoveCardComponent {
  readonly viewName = 'クレジットカード情報の削除';

  // ローディング中であることを表すオブジェクト
  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });

  constructor(
    public dialogRef: MatDialogRef<BeforeRemoveCardComponent>,
    private userOpeLogger: UserOperationLoggerService,
    private router: Router,
    private paymentService: PaymentService,
    private overlay: Overlay,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.userOpeLogger.logViewAndButtonName(this.viewName, '戻る');
    this.dialogRef.close();
  }
  async ok() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'OK');
    // console.log('DEBUG dialog this.data.cardSeq');
    // console.log(this.data.cardSeq);
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    try {
      await this.paymentService.removeCreditCard(this.data.cardSeq);
      alert('クレジットカードを削除しました。');
    } catch (error) {
      // console.log(error);
    } finally {
      this.overlayRef.detach();
    }
    // 削除後の画面描画が困難なため、ひとまずメニューへ遷移させている
    this.router.navigateByUrl('/menu');
    this.dialogRef.close();
  }
}

@Component({
  selector: "cleaning-dialog",
  templateUrl: './cleaning-dialog/cleaning-dialog.html',
  styleUrls: ['./cleaning-dialog/cleaning-dialog.scss']
})
export class CleaningDialog {
  days = this.setSelectDays();
  day: string;
  cleaningShopName: string;
  cleaningShopNumber: string;

  constructor(
    private router: Router,
    private _httpjson: HttpJsonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    alert('依頼が完了すると、' + data.device + 'が使えなくなります。');
    this.cleaningShopName = data.cleaningShopName;
    this.cleaningShopNumber = data.cleaningShopNumber;
  }

  async request() {
    if (this.day === undefined) {
      alert('返却希望日をご選択下さい。');
      return;
    }
    if (new Date().getTime() > Date.parse(this.data.expired)) {
      alert('延長してからご依頼下さい。');
      return;
    }
    alert(this.day + 'で依頼します');
    const arrySelected = this.day.split('/');
    const selectedDate = (
      arrySelected[0] +
      arrySelected[1] +
      arrySelected[2]
    ).slice(0, 8);
    const url = environment.apiUrl + 'collaborateCleaning/' +
      this.data.url +
      '/' +
      selectedDate;
    const res = await this._httpjson.getJson(url);
    if (res) {
      const result = res['result'];
      if (result === 'accept') {
        alert(this.day + 'で承りました。');
        this.router.navigateByUrl('mylockers');
      } else if (result === 'deny') {
        alert('対応していない日程です。再度選択下さい。');
      } else if (result === 'overTime') {
        alert('時間が過ぎているため選択できませんでした。再度選択下さい。');
      } else if (result === 'urlError') {
        alert('指定されたカギが不正です。');
      } else if (result === 'unsupported') {
        alert('クリーニングがサポートされていません。');
      } else if (result === 'expired') {
        alert('延長してからご依頼下さい。');
      }
    }
  }

  private setSelectDays() {
    const days = [];
    let yyyy, mm, dd;
    const today = new Date();
    today.setDate(today.getDate() + 2);
    let is_today_available = true;

    if (today.getHours() <= 11) {
      is_today_available = true;
    } else {
      is_today_available = false;
    }
    for (let i = 0; i <= 12; i++) {
      const tempDate = new Date();
      tempDate.setDate(today.getDate() + i);
      const weekday = tempDate.getDay();
      let weekDayJ = '';
      if (weekday === 0) {
        weekDayJ = '(日)';
      } else if (weekday === 1) {
        weekDayJ = '(月)';
      } else if (weekday === 2) {
        weekDayJ = '(火)';
      } else if (weekday === 3) {
        weekDayJ = '(水)';
      } else if (weekday === 4) {
        weekDayJ = '(木)';
      } else if (weekday === 5) {
        weekDayJ = '(金)';
      } else if (weekday === 6) {
        weekDayJ = '(土)';
      }
      yyyy = tempDate.getFullYear();
      mm = tempDate.getMonth() + 1;
      dd = tempDate.getDate();
      if (!is_today_available) {
        is_today_available = true;
        continue;
      }
      days.push(
        yyyy +
          '/' +
          ('0' + mm).slice(-2) +
          '/' +
          ('0' + dd).slice(-2) +
          weekDayJ +
          '閉店後'
      );
    }
    return days;
  }
}

export class PickupDialog {
  collaborationName: string;
  collaborationPhoneNumber: string;
  urlkey: string;

  // ローディング中であることを表すオブジェクト
  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });

  constructor(
    public dialogRef: MatDialogRef<PrepaidDialogComponent>,
    private router: Router,
    private _httpjson: HttpJsonService,
    private overlay: Overlay,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    alert(`依頼が完了すると、${data.device}が使えなくなります。`);
    this.collaborationName = data.collaborationName;
    this.collaborationPhoneNumber = data.collaborationPhoneNumber;
    this.urlkey = data.url
  }

  async request() {
    const url = environment.apiUrl + 'collaboration/shareWithPickup'

    const json = {
      urlKey: this.urlkey,
    };
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    const res = await this._httpjson.postJson(url, json)
    if (res.result) {
      this.overlayRef.detach();
      this.router.navigateByUrl('mylockers');
      this.dialogRef.close();
      alert('ご依頼承りました。\n以後依頼店のみ開けられます')
    } else {
      this.overlayRef.detach();
      alert(`code: ${res.error.code}\nmessage: ${res.error.message}`);
    }
  }
}

@Component({
  selector: 'app-prepaid-dialog',
  templateUrl: './prepaid-dialog/prepaid-dialog.component.html',
  styleUrls: ['./prepaid-dialog/prepaid-dialog.component.scss']
})
export class PrepaidDialogComponent {
  prepaidAddHours: string;
  prepaidAddCost: string;
  prepaidStartAt: string;
  prepaidEndAt: string;
  priceText: string;
  private firstPrepaidHours: string;
  private lockObject = false;

  constructor(
    public dialogRef: MatDialogRef<PrepaidDialogComponent>,
    private prepaidService: PrepaidService,
    private spacersUnitsService: SpacersUnitsRepositoryService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.init();
  }

  get prevBtnDisabled(): boolean {
    if (!this.firstPrepaidHours){
      return true;
    }
    return this.firstPrepaidHours === this.prepaidAddHours;
  }

  private async init(): Promise<void>{
    this.lock((async () => {
      const result = await this.prepaidService.initPrice(this.data.device);
      if (result.error){
        return alert(result.error.message);
      }
      this.bindData(result);
      // マイナスボタン非活性化の制御の為、最初の時間を保持
      this.firstPrepaidHours = this.prepaidAddHours;
    }));
    this.spacersUnitsService.getUnitBySpacerId(this.data.device).then((unit) => {
      const unitDetail = unit.getDetail(this.data.device);
      if (unitDetail){
        this.priceText = unitDetail.getPerHWithFirstTimesText();
      }
    });
  }

  async onPrevClick(): Promise<void> {
    this.lock((async () => {
      const result = await this.prepaidService.prevPrice(this.data.device);
      if (result.error){
        return alert(result.error.message);
      }
      this.bindData(result);
    }));
  }

  async onNextClick(): Promise<void> {
    this.lock((async () => {
      const result = await this.prepaidService.nextPrice(this.data.device);
      if (result.error){
        return alert(result.error.message);
      }
      this.bindData(result);
  }));
  }

  private bindData(result: any): void{
    this.prepaidAddHours = `${result.addPrepaidHours}時間`;
    this.prepaidAddCost = result.addPrepaidCost;
    this.prepaidStartAt = result.prepaidStartAt;
    this.prepaidEndAt = result.prepaidEndAt;
  }

  private async lock(func: () => Promise<void>){
    if (this.lockObject){
      return;
    }

    this.lockObject = true;
    await func();
    this.lockObject = false;
  }

  ok() {
    this.router.navigateByUrl(this.data.url);
    this.dialogRef.close();
  }
}
