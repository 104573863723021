<div *ngIf="fetched" class="gray-area">
  <ng-container *ngIf="isAutoCanceled">
    <div class="cancel-alart">自動キャンセルしました</div>
  </ng-container>
  <!-- 画面表示TOPここから -->
  <!-- 共有中のカードヘッダー表示部ここから -->
  <ng-container *ngIf="isShared" class="flex">
    <div class="shared-card-header">
      共有中
    </div>
  </ng-container>
  <!-- 共有中のカードヘッダー表示部ここまで -->
  <div class="white-area">
    <div class="text-area">
      <!-- ロッカーの状態を表すアイコンと文言の表示ここから -->
      <ng-container [ngSwitch]="status">
        <ng-container *ngSwitchCase="'sellProduct'">
          <div class="flex">
            <img src="../../../assets/images/ui-ver2.0/icon_in_use.svg" />
            <div class="device">{{ item.device }}</div>
          </div>
          <div class="using">
            利用中
          </div>
          <div>{{ item.address }}</div>
        </ng-container>
        <ng-container *ngSwitchCase="'buyProduct'">
          <div class="flex">
            <img src="../../../assets/images/ui-ver2.0/icon_in_use.svg" />
            <div class="device">{{ item.device }}</div>
          </div>
          <div class="using">
            利用中
          </div>
          <div>{{ item.address }}</div>
        </ng-container>
        <ng-container *ngSwitchCase="'use'">
          <div class="flex">
            <img src="../../../assets/images/ui-ver2.0/icon_in_use.svg" />
            <div class="device">{{ item.device }}</div>
          </div>
          <div class="using">
            利用中
          </div>
          <div>{{ item.address }}</div>
        </ng-container>

        <ng-container *ngSwitchCase="'free'">
          <div class="flex">
            <img src="../../../assets/images/ui-ver2.0/icon_in_use.svg" />
            <div class="device">{{ item.device }}</div>
          </div>
          <div class="using">
            利用中
          </div>
          <div>{{ item.address }}</div>
        </ng-container>

        <ng-container *ngSwitchCase="'reserved'">
          <div class="flex">
            <img src="../../../assets/images/ui-ver2.0/icon_reserved.svg" />
            <div class="device">{{ item.device }}</div>
          </div>
          <div class="reserved">
            予約中
          </div>
          <div>{{ item.address }}</div>
        </ng-container>

        <ng-container *ngSwitchCase="'autoCanceled'">
          <div class="flex">
            <div class="device">{{ item.device }}</div>
          </div>
          <div>{{ item.address }}</div>
          <div class="cancel-msg">
            このロッカーはご予約から48時間以上経過後もご利用が確認できなかったため、自動的にキャンセルとなりました。
          </div>
        </ng-container>
      </ng-container>
      <!-- ロッカーの状態を表すアイコンと文言の表示ここまで -->
      <!--
        <div class="address">{{ item.value.address }}</div> -->
      <!-- 仕様メモ : XDファイルを見ると詳細ボタンが存在しますが、Android
        版では、詳細ボタンは不要です(技術的に実装が難しいため) -->
      <hr />
<!--      時間表示部　ここから-->
      <ng-container *ngIf="!hasProduct">
        <div class="available-item">
          <div *ngIf="!is_paid" class="flex">
            <ng-container *ngIf="item.reservedTime else showStartAt">
              <div class="available-disp">利用終了まで</div>
              <div class="available-disp">{{message}}</div> 
            </ng-container>
            <ng-template #showStartAt>
              <div class="available-disp">利用終了まで</div>
              <div class="available-disp">{{message}}</div> 
             </ng-template>
          </div>
          <div *ngIf="item.prepaid" class="flex">
          </div>
          <div *ngIf="is_paid" class="flex">
          </div>
        </div>
      <hr *ngIf="!is_paid" />
<!--      時間表示部　ここまで-->
      </ng-container>

<!--      料金表示部　ここから-->
      <div *ngIf="!is_paid; else valueAreaWhenPaid" class="fee-item-box">
        <div class="usage-fee-item">
            <ng-container *ngIf="usePrice > 0" >
              <div class="flex">
                <div class="usage-fee-disp">利用料金</div>
                <div class="usage-fee-price">¥{{ usePrice }}</div>
              </div>
            </ng-container>
        </div>
        <div class="booking-fee-item">
          <div *ngIf="reservedPrice > 0" class="flex">
              <div class="booking-fee-disp">予約料金</div>
              <div class="booking-fee-price">¥{{ reservedPrice }}</div>
          </div>
        </div>
        <div class="booking-fee-item">
          <div *ngIf="payedPrice > 0" class="flex">
              <div class="booking-fee-disp">支払い済料金</div>
              <div class="booking-fee-price">¥{{ payedPrice }}</div>
          </div>
        </div>
        <div *ngIf="hasProduct" class="product-fee-item">
          <div class="flex">
            <div class="product-fee-disp">下記へのお支払い金額</div>
            <div class="product-fee-price">¥{{ productPrice | number }}</div>
          </div>
          <div class="product-fee-desc">
            お支払い内容<br>
            お支払先: {{senderName}}<br>
            {{ productDesc }}
          </div>
        </div>
        <div class="total-fee-item">
            <ng-container *ngIf="totalPrice > 0">
              <div class="flex">
                <div class="total-fee-disp">合計</div>
                <div class="total-fee-price">¥{{ totalPayPrice }}</div>
              </div>
            </ng-container>
          </div>
      </div>
      <ng-template #valueAreaWhenPaid >
        <div *ngIf="hasProduct" class="product-fee-item">
          <div class="product-fee-desc">
            <div class="product-fee-desc">
              お支払い内容<br>
              お支払先: {{senderName}}<br>
              {{ productDesc }}
            </div>
          </div>
        </div>
      </ng-template>
<!--      料金表示部　ここまで-->
<!--      ボタン表示部　ここから-->
      <ng-container [ngSwitch]="status" class="button-status">
        <ng-container *ngSwitchCase="'sellProduct'">
          <button (click)="clickOpen($event)" class="large-black">
            ロッカーから送る
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'buyProduct'">
          <ng-container *ngIf="totalPayPrice == 0; else shouldPayProductPrice">
            <button (click)="clickOpen($event)" class="large-black">
            ロッカーから送る
            </button>
          </ng-container>
          <ng-template #shouldPayProductPrice>
            <button (click)="clickPayAndOpen($event)" class="large-black">
              決済する
            </button>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'use'">
          <div *ngIf="!is_paid">
            <button (click)="clickPayAndOpen($event)" class="large-black">
              決済する
            </button>
           </div>
          <div *ngIf="is_paid">
            <button (click)="clickOpen($event)" class="large-black">
            ロッカーから送る
            </button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'reserved'">
          <button (click)="clickOpen($event)" class="large-black">
            予約したロッカーを利用する
          </button>
          <button (click)="clickCancel($event)" class="large-white-red">
            予約をキャンセルする
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'autoCanceled'">
          <button (click)="clickPayAutoCanceled($event)" class="large-white">
            キャンセル料のお支払へ
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'free'">
<meta http-equiv="refresh" content="0;URL=https://spacer.live/choice-share-type-last/{{ item.device }}">
          <button (click)="clickOpenFree($event)" class="large-black">
            ロッカーから送る
          </button>
        </ng-container>
      </ng-container>
<!--      ボタン表示部　ここまで-->
      <div *ngIf="status=='reserved'" class="flex">
        <p class="cancel-comment" (click)="clickReserveCalcel($event)" >
          キャンセル時にかかる料金について
        </p>
        <img
          class="question-icon"
          src="../../../assets/images/ui-ver2.0/icon_question.svg"
        />
      </div>
      <div *ngIf="status=='use' || status=='free'" class="flex">
     </div>
    </div>
  </div>
</div>
