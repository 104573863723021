export class SpacersUnitDetail {
  id: string;
  price: any;

  getPerHText(): string {
    if (this.price) {
      return `${this.price.paidPerH[1]}時間ごとに${this.price.paidPerH[0]}円`;
    }
    return null;
  }

  getFirstTimesText(): string {
    if (this.price.times) {
      const hours = Object.keys(this.price.times[0])[0];
      return `(最初の${hours}時間${this.price.times[0][hours]}円)`;
    }
    return null;
  }

  getPerHWithFirstTimesText(): string {
    const perHText = this.getPerHText();
    const firstTimesText = this.getFirstTimesText();

    if (perHText && firstTimesText) {
      return `${perHText}${firstTimesText}`;
    }
    if (perHText) {
      return perHText;
    }
    return null;
  }
}
