<p id="purchase-text">チケット購入</p>
<p id="purchase-price-text">1チケット(6時間) = 240円</p>
<form>
  <select class="theme-purchase-select-btn" name="ticket" [(ngModel)]="ticket">
    <option *ngFor="let item of ticketNum" [value]="item">{{item}}</option>
  </select>
  <p id="select-right-text">チケット</p>
  <button class="theme-purchase-btn" (click)="purchase()">
    購入画面に進む
  </button>
</form>
