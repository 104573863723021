import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KeyComponent } from './key/key.component';
import { RestrictComponent } from './restrict/restrict.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth.service';
import { LoginComponent } from './login/login.component';
import { MapsComponent } from './maps/maps.component';
import { IDLinkComponent } from './idlink/idlink.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentCreditcardComponent } from './payment-creditcard/payment-creditcard.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { AuthProvidersComponent } from './auth-providers/auth-providers.component';
import { RegistPhoneNumConfComponent } from './regist-phone-num-conf/regist-phone-num-conf.component';
import { ChangePhoneNumComponent } from './change-phone-num/change-phone-num.component';
import { ChangePhoneNumConfComponent } from './change-phone-num-conf/change-phone-num-conf.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ReserveConfirmComponent } from './reserve/reserve-confirm/reserve-confirm.component';
import { MylockerListComponent } from './mylocker/mylocker-list/mylocker-list.component';
import { PutComponent } from './put/put.component';
import { HistoryComponent } from './history/history.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { OtherMenuComponent } from './other-menu/other-menu.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { FaqComponent } from './faq/faq.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TransactionsLawComponent } from './transactions-law/transactions-law.component';
import { SvgComponent } from './svg/svg.component';
import { DetectComponent } from './put/detect/detect.component';
import { KeptComponent } from './put/kept/kept.component';
import { RootRedirectorComponent } from './root-redirector/root-redirector.component';
import { ChoiceShareTypeComponent } from './choice-share-type/choice-share-type.component';
import { ChoiceShareTypeLastComponent } from './choice-share-type-last/choice-share-type.component';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import {InquiryComponent} from './inquiry/inquiry.component';
import {ShareWithPriceComponent} from "./share-with-price/share-with-price.component";
import {MyboxesComponent} from "./myboxes/myboxes.component";
import {DebugViewComponent} from "./debug-view/debug-view.component";
import {NoProductionGuard} from "./no-production.guard";

const routes: Routes = [
  // rootの場合は、 /put へ
  { path: '', component: RootRedirectorComponent, canActivate: [AuthGuard] },

  // Global Menu
  { path: 'put', redirectTo: 'mylockers'},
  { path: 'put/detect', redirectTo: 'mylockers/detect'},
  { path: 'put/detect/:id', redirectTo: 'mylockers/detect/:id'},
  { path: 'put/kept', redirectTo: 'mylockers/kept'},
  { path: 'maps', component: MapsComponent, canActivate: [AuthGuard] },
  { path: 'mylockers', component: MylockerListComponent, canActivate: [AuthGuard] },
  { path: 'mylockers/detect', component: DetectComponent, canActivate: [AuthGuard] },
  { path: 'mylockers/detect/:id', component: DetectComponent, canActivate: [AuthGuard] },
  { path: 'mylockers/kept', component: KeptComponent, canActivate: [AuthGuard] },
  // マイロッカーのリファクタリング案。完成しなかったため、コメントアウト。
  // { path: 'myboxes', component: MyboxesComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationListComponent, canActivate: [AuthGuard] },
  { path: 'notifications/:id', component: NotificationListComponent, canActivate: [AuthGuard] },
  { path: 'menu', component: OtherMenuComponent, canActivate: [AuthGuard] },

  // 決済確認画面(H1) id はロッカーのBoxID, action は決済の種別
  // 通常利用の決済          confirm-payment/:id/use
  // 予約の決済             confirm-payment/:id/reserved
  // キャンセルの決済        confirm-payment/:id/manualCanceled
  // 自動予約キャンセルの決済 confirm-payment/:id/autoCanceled
  { path: 'confirm-payment/:id/:action', component: ConfirmPaymentComponent, canActivate: [AuthGuard] },

  // -その他のメニュー
  // --開発用メニュー
  { path: 'payment', component: PaymentComponent },
  { path: 'svg', component: SvgComponent },
  // デバッグ用画面
  // { path: 'debug-view', component: DebugViewComponent, canLoad: [NoProductionGuard] },

  // --本番用メニュー (上から順)
  // ---会員登録情報
  { path: 'menu/phone-number-change', component: ChangePhoneNumComponent, canActivate: [AuthGuard] },
  { path: 'menu/phone-number-change/confirmation-code', component: ChangePhoneNumConfComponent, canActivate: [AuthGuard] },
  { path: 'menu/creditcard', component: PaymentCreditcardComponent, canActivate: [AuthGuard] },
  { path: 'menu/notification-settings', component: NotificationSettingsComponent, canActivate: [AuthGuard] },
  { path: 'menu/auth-providers', component: AuthProvidersComponent, canActivate: [AuthGuard] },
  { path: 'menu/history', component: HistoryComponent, canActivate: [AuthGuard] },
  { path: 'menu/unsubscribe', component: UnsubscribeComponent, canActivate: [AuthGuard] },

  // ---ヘルプ/その他(iframe)
  { path: 'menu/faq', component: FaqComponent, canActivate: [AuthGuard] },
  { path: 'menu/inquiry', component: InquiryComponent, canActivate: [AuthGuard] },
  { path: 'menu/terms', component: TermsComponent, canActivate: [AuthGuard] },
  { path: 'menu/privacy', component: PrivacyComponent, canActivate: [AuthGuard] },
  { path: 'menu/transactions-law', component: TransactionsLawComponent, canActivate: [AuthGuard] },

  // その他
  { path: 'login', component: LoginComponent },
  { path: 'login/confirmation-code', component: RegistPhoneNumConfComponent },
  { path: 'tutorial', component: TutorialComponent },
  { path: 'reserve/:id', component: ReserveConfirmComponent, canActivate: [AuthGuard] },
  { path: 'choice-share-type/:device', component: ChoiceShareTypeComponent, canActivate: [AuthGuard]},
  { path: 'choice-share-type-last/:device', component: ChoiceShareTypeLastComponent, canActivate: [AuthGuard]},
  { path: 'share-with-price/:device', component: ShareWithPriceComponent, canActivate: [AuthGuard]},
  { path: 'thankyou/:action', component: ThankYouComponent, canActivate: [AuthGuard]},

  // 旧パス 移行対応済み
  { path: 'restrict/:id', component: RestrictComponent, canActivate: [AuthGuard] },

  // 旧パス 移行未対応
  // REVIEW ここでいう「旧パス」が何を指しているのかわかっていません。推測ですが2019年以前のものかと。
  { path: 'key/:id', component: KeyComponent, canActivate: [AuthGuard] },
  { path: 'confirm/:id', component: ConfirmComponent, canActivate: [AuthGuard] },
  { path: 'id-link', component: IDLinkComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
