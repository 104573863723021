import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';

@Component({
  selector: 'app-count-down-timer',
  templateUrl: './count-down-timer-component.component.html',
  styleUrls: ['./count-down-timer-component.component.scss']
})
export class CountDownTimerComponent implements OnInit, OnDestroy {
  readonly refreshIntervalMilliSec = 1000;

  @Output()
  messageChange = new EventEmitter<string>();

  @Output()
  onTimeOver = new EventEmitter();

  @Input()
  get inputDate() {
    return this.futureString;
  }

  set inputDate(val) {
    this.futureString = val;
    this.future = new Date(this.futureString);
    this.messageChange.emit(this.futureString);
  }

  @Input()
  displayType = 'large';

  private future: Date;
  private futureString: string;
  private diff: number;
  private $counter: Observable<number>;
  private subscription: Subscription;
  public message: string;
  private _hours: number;
  private _seconds: number;
  private _minutes: number;

  get strHours(): string {
    return this._hours + '';
  }

  get strMinutes(): string {
    return this.lpad(this._minutes);
  }

  get strSeconds(): string {
    return this.lpad(this._seconds);
  }

  constructor(private elm: ElementRef) {}

  dhms(t) {
    this._seconds = Math.abs(t % 60);
    this._minutes = Math.abs(Math.floor(t / 60) % 60);
    this._hours = Math.abs(Math.floor(t / 3600));

    if (t < 0) {
      this.onTimeOver.emit();
      return [
        '-' + this._hours + ':',
        this.lpad(this._minutes) + ':',
        this.lpad(this._seconds)
      ].join('');
    } else {
      // return [hours + ":", this.lpad(minutes) + ":", this.lpad(seconds)].join("");
      return [this.lpad(this._minutes) + '分', this.lpad(this._seconds) + '秒'].join('');
    }
  }

  lpad(num): string {
    let s = num + '';
    if (s.length < 2) s = '0' + s;
    return s;
  }

  ngOnInit() {
    this.future = new Date(this.futureString);
    this.$counter = Observable.interval(this.refreshIntervalMilliSec).map(x => {
      this.diff = Math.floor(
        (this.future.getTime() - new Date().getTime()) / this.refreshIntervalMilliSec
      );
      return x;
    });

    this.subscription = this.$counter.subscribe(
      x => (this.message = this.dhms(this.diff))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
