import { IRole } from "../interface/i-role";

export class Term implements IRole {
  from: moment.Moment;
  to: moment.Moment;

  constructor() {
  }

  isPermitted(boxId: string, targetDate: moment.Moment): boolean {
    // console.log('DEBUG Term#isPermitted')
    // console.log('DEBUG START this.from');
    // console.log(this.from);
    // console.log('DEBUG END this.from');

    // console.log('DEBUG START this.to');
    // console.log(this.to);
    // console.log('DEBUG END this.to');
    // console.log('DEBUG START targetDate');
    // console.log(targetDate);
    // console.log('DEBUG END targetDate');


    if (this.to) {
      if (targetDate.isAfter(this.to)) {
        // console.log('DEBUG return false targetDate.isAfter');
        return false;
      }
    }

    if (this.from) {
      if (targetDate.isBefore(this.from)) {
        // console.log('DEBUG return false targetDate.isBefore');
        return false;
      }
    }
    return true;
  }
}
// class Term implements IFirebaseEntity {
//   from: moment.Moment;
//   to: moment.Moment;
//
//   isPermitted(targetDate: moment.Moment): boolean {
//     if (this.to) {
//       if (targetDate.isAfter(this.to)) {
//         return false;
//       }
//     }
//
//     // this.from は必須
//     if (targetDate.isBefore(this.from)) {
//       return false;
//     }
//     return true;
//   }
//
//   applyItem(any) {
//
//   }
// }
