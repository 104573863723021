<div id="content">
  <h2>その他のメニュー</h2>
  <div id="menu">
    <ul class="menu-list">
      <li>
        <img src="../../assets/images/ui-ver2.0/icon_user_guide.svg" /><a (click)="clickLink($event)" href="https://www.notion.so/5e2be3fc675242e0818fea38b3d3e06b" target="_blank">ユーザーガイド</a>
      </li>
    </ul>
    <p>会員登録情報</p>
    <ul class="menu-list">
      <!-- iOSのこの機能のリリースに合わせて表示<li>
            <a routerLink="../id-link">連携済みID一覧</a>
        </li> -->
      <li class="logout">
        <a  class="adjuster" (click)="logout()">ログアウト</a>
      </li>
       <li>
        <img src="../../assets/images/ui-ver2.0/icon_phone_number.svg" /><a (click)="clickLink($event)" routerLink="phone-number-change">登録電話番号の変更</a>
      </li>
      <li>
        <img src="../../assets/images/ui-ver2.0/icon_card.svg" /><a (click)="clickLink($event)"  routerLink="creditcard">クレジットカード情報の変更<span *ngIf="!hasCreditCard">(未設定)</span></a>
      </li>
      <!-- <li>
        <img src="../../assets/images/ui-ver2.0/icon_change_notification_settings.svg" /><a routerLink="notification-settings">通知設定の変更</a>
      </li> -->
      <li>
        <img src="../../assets/images/ui-ver2.0/icon_use_history.svg" /><a (click)="clickLink($event)"  routerLink="history">ご利用履歴</a>
      </li>
      <li>
        <img src="../../assets/images/ui-ver2.0/icon_unsubscribed.svg" /><a (click)="clickLink($event)"  routerLink="unsubscribe">退会手続き</a>
      </li>
    </ul>
    <p>ヘルプ/その他</p>
    <ul class="menu-list">
      <li>
        <a (click)="clickLink($event)"  routerLink="faq" class="adjuster" >よくあるご質問</a>
      </li>
      <li>
        <a  (click)="clickLink($event)" href="https://spacer.live/iframe/contact/" class="adjuster" target="_blank">お問合せ</a>
      </li>
      <li>
        <a (click)="clickLink($event)"  routerLink="terms" class="adjuster" >利用規約</a>
      </li>
      <li>
        <a (click)="clickLink($event)"  routerLink="privacy" class="adjuster" >個人情報保護方針</a>
      </li>
      <li>
        <a (click)="clickLink($event)"  routerLink="transactions-law" class="adjuster" >特定商取引法に基づく表記</a>
      </li>
     <!-- <li>
            <a
            href="https://spacer.live/kiyaku.html" 分ける？
            >利用規約・プライバシーポリシー</a>
        </li> -->
    </ul>
    <ng-container *ngIf="false">
      <p>開発環境専用メニュー</p>
<!--      <p>予約のテスト用</p>-->
<!--      <label for="testReserve">テスト用:予約ロッカー番号</label>-->
<!--      <br>-->
<!--      <input id="testReserve" name="testReserve" type="text" [(ngModel)]="testReserveModel.deviceId">-->
<!--      <br>-->
<!--      <button (click)="testReserve()">[予約する]</button>-->
<!--      <br>-->
<!--      <hr>-->

    </ng-container>
  </div>
</div>
<app-global-menu-component></app-global-menu-component>
