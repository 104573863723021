export class PriceValues {
  private _usePrice: number | null;
  private _reservePrice: number | null;
  private _productPrice: number | null;
  private _paidPrice: number | null;
  canSetProduct: CanSetPrice | null;
  canSetPrepaid: CanSetPrice | null;

  constructor(usePrice, reservePrice, productPrice, paidPrice, canSetProduct, canSetPrepaid) {
    this._usePrice = usePrice;
    this._reservePrice = reservePrice;
    this._productPrice = productPrice;
    this._paidPrice = paidPrice;
    this.canSetProduct = new CanSetPrice(canSetProduct);
    this.canSetPrepaid = new CanSetPrice(canSetPrepaid);
  }

  get usePrice(): number | null {
    return this._usePrice;
  }

  get reservePrice(): number | null {
    return this._reservePrice;
  }

  get productPrice(): number | null {
    return this._productPrice;
  }

  get paidPrice(): number | null {
    return this._paidPrice;
  }

  /**
   * 利用料金、予約料金、商品料金の合計
   *
   * @readonly
   * @type {(number | null)}
   * @memberof PriceValues
   */
  get totalPrice(): number | null {
    const usePrice = this._usePrice || 0;
    const reservePrice = this._reservePrice || 0;
    const productPrice = this._productPrice || 0;
    return usePrice + reservePrice + productPrice;
  }

  /**
   * 支払い合計料金
   *
   * @readonly
   * @type {number}
   * @memberof PriceValues
   */
  get totalPayPrice(): number {
    const paidPrice = this._paidPrice || 0;
    const _totalPayPrice = this.totalPrice - paidPrice;
    return _totalPayPrice > 0 ? _totalPayPrice : 0;
  }
}

export class CanSetPrice {
  result = false;
  error: any | null;

  constructor(canPrice: any) {
    this.result = canPrice.result;
    this.error = canPrice.error;
  }
}
