import { AngularFireDatabase } from '@angular/fire/database';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BannerMaster } from './entity/banner-master';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class BannerMasterRepositoryService {
  readonly tableName = 'bannerMaster';

  constructor(private db: AngularFireDatabase) { }

  async getCurrent(): Promise<BannerMaster> {
    const observable =
      this.db.list(`${this.tableName}`)
        .valueChanges()
        .pipe(take(1), map(items => items.map(item => this.parse(item))));

    return new Promise<BannerMaster>((resolve) => {
      observable.subscribe(items => {
        const item = items.find(x => x.enabled) || null;
        return resolve(item);
      });
    });
  }

  private parse(item: any): BannerMaster {
    const banner = new BannerMaster();
    banner.startDate = moment(item.startAt);
    banner.endDate = moment(item.endAt);
    banner.openUrl = item.openUrl;
    banner.imagePath = item.imagePath;
    return banner;
  }
}
