<div id="content">
  <h2>電話番号の登録</h2>
  <form #phoneNumberAuthForm="ngForm">
    <div id="regist-phone">
      <p>携帯電話番号を入力してください。</p>
      <div id="phone">
       <input type="tel" class="phone-num" id="telephoneNumber" [(ngModel)]="parameters.phoneNumber" name="phoneNumber"
          placeholder="09012345678" />
      </div>
<br><br>
      <div id="confirm">
<br><br>
        <input [(ngModel)]="parameters.isAgreeTermOfUseAndPrivacyPolicy" name="isAgreeTermOfUseAndPrivacyPolicy"
          class="checkstatus" type="checkbox" />  
          
        <a href="https://spacer.live/kiyaku/" target="_blank">利用規約</a> /
        <a href="https://spacer.live/privacy/" target="_blank">個人情報保護方針</a>に同意
      </div>
      <div id="recaptcha-container"></div>
      <div id="button" *ngIf="
          parameters.isAgreeTermOfUseAndPrivacyPolicy;
          then able;
          else disable
        ">
        #
      </div>
      <ng-template #disable>
        <button class="large-black-off" id="sign-in-button" type="submit" (click)="userOpeLog($event)">
          SMSに認証コードを送る
        </button>
      </ng-template>
      <ng-template #able>
        <button class="large-black" id="sign-in-button" type="submit" (click)="sendPhoneNumber($event)">
          SMSに認証コードを送る
        </button>
      </ng-template>
    </div>
  </form>
  <ng-container *ngIf="false">
    <p>開発環境での動作確認用</p>
    <button (click)="loginGoogle()">Googleでログイン</button>
    <button (click)="loginFb()">Facebookでログイン</button>
  </ng-container>
</div>
