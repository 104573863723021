import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';
import {SecureParamService} from './secure-param.service';
import * as firebase from 'firebase';
import {AngularFireDatabase} from '@angular/fire/database';
import {SpacerErrorAnnouncer, SpacerErrorHandleService} from './lib/spacer-error-handle/spacer-error-handle.service';
import {MembersRepositoryService} from './members-repository-service';

class UserOperationLog {
  private writeAt: Date;
  constructor(public msg: string) {
    this.writeAt = new Date();
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserOperationLoggerService {
  readonly baseTable = 'userOperationLogs';
  readonly version = '0_0_1'; // Firebase の path にはピリオドが利用できないため、アンダースコアを利用
  // readonly keepMinutes = 30;
  readonly maxLogCount = 30; // 最大で保持するログの件数
  private _loggerImplement;
  private _listUserOperationLog = [];
  constructor(
    private sequreParamService: SecureParamService,
    private db: AngularFireDatabase,
    private memberRepo: MembersRepositoryService,
    private errorHandler: SpacerErrorHandleService
  ) {
    this._loggerImplement = (description: string) => {
      const msg = this.fmtDt(new Date()) + ', ' + description;
      // console.log(msg);
      this._listUserOperationLog.push(new UserOperationLog(msg));
      if ((this._listUserOperationLog.length) > this.maxLogCount) {
        // console.log('Shift....');
        this._listUserOperationLog.shift();
        // console.log(this._listUserOperationLog.length);
      }
    }
  }

  logViewAndButtonName(view: string, buttonName: string, optionalInfo: string = '') {
    // this._loggerImplement(`利用者が「${view}」画面上の「${buttonName}」をクリックした, ${optionalInfo}`);
  }

  logViewAndEventText(view: string, event: any, optionalInfo: string = '') {
    // this._loggerImplement(`利用者が「${view}」画面上の「${event.target.innerText}」をクリックした, ${optionalInfo}`);
  }

  sysLogViewAndDescription(view: string, description: string, optionalInfo: string = '') {
    // this._loggerImplement(`システムが「${view}」画面上で${description}, ${optionalInfo}`);
  }

  private fmtDt(date: Date): string {
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString() + ' ' + this.lpad3(date.getMilliseconds());
  }

  async save(options) {
    switch (environment.logger.userOperation) {
      case 'firebase':
        // const userKey = firebase.auth().currentUser.uid;
        const userKey = await this.memberRepo.getPhoneNum();

        // const dateObj = new Date();
        // dateObj.setHours(dateObj.getMinutes() + this.keepMinutes);
        const clientType = 'web';
        const createdAt = this.dateKey(new Date());
        const dbpath = `${this.baseTable}/${clientType}/v${this.version}/${createdAt}`;
        // const dbpath = `${this.baseTable}/${clientType}/v${this.version}/${userKey}/${createdAt}`;
        // let cnt = 0;
        const writeObj = {}
        let cnt = 0;
        options.forEach(opt => {
          cnt += 1;
          writeObj[cnt] = opt;
        })
        this._listUserOperationLog.filter(log => {
          // return log.writeAt < dateObj;
          return true;
        }).forEach(log => {
          cnt += 1;
          writeObj[cnt] = log.msg;
        });
        this.db.object(dbpath).set(writeObj).then( res => {
          this.flush();
          const errMsg = 'お問い合わせを受け付けました。';
          new SpacerErrorAnnouncer(errMsg).announce();
        }).catch(err => {
          const errMsg = 'お問い合わせの送信に失敗しました。時間を置いて再度お試しください。';
          new SpacerErrorAnnouncer(errMsg).announce();
        })
        break;
      default:
        break;
    };
  }

  private flush() {
    this._listUserOperationLog = [];
  }

  private dateKey(argDate: Date): string {
    const year = argDate.getFullYear();
    const month = this.lpad(argDate.getMonth() + 1);
    const date = this.lpad(argDate.getDate());
    const hour = this.lpad(argDate.getHours());
    const min = this.lpad(argDate.getMinutes());
    const sec = this.lpad(argDate.getSeconds());
    return `${year}${month}${date}${hour}${min}${sec}`;
  }

  private lpad(num): string {
    let s = num + "";
    if (s.length < 2) s = "0" + s;
    return s;
  }

  private lpad3(num): string {
    let s = num + "";
    if (s.length < 3) s = "0" + s;
    return s;
  }
}
