import { Unit } from "./unit";
import { Term } from "../value-object/term"
import { IRole } from "../interface/i-role";
import * as moment from "moment";

export class RoleImpl implements IRole  {
  units: Unit[];
  term: Term;
  constructor() {
    this.term = new Term();
    this.units = [];
  }

  isPermitted(boxId: string, targetDate: moment.Moment): boolean {
    // console.log('DEBUG RoleImpl#isPermitted')
    // 必須項目である from が存在しない場合には、
    // Firebase上のデータが不正であるとみなす。
    // console.log('DEBUG isPermitted 101');

    if(!this.term.from) {
      // console.log('DEBUG isPermitted 102');
      // console.log('DEBUG NO from')
      return false;
    }
    // console.log('DEBUG RoleImpl#isPermitted called');
    // console.log('DEBUG START this.units');
    // console.log(this.units);
    // console.log('DEBUG END this.units');
    // console.log('DEBUG START this.units.length');
    // console.log(this.units.length);
    // console.log('DEBUG END this.units.length');

    if (this.units.length < 1) {
      // console.log('DEBUG isPermitted 103');
      // console.log('DEBUG units.length < 1');
      const result = this.term.isPermitted(boxId, targetDate);
      // console.log('DEBUG START result');
      // console.log(result);
      // console.log('DEBUG END result');
      return result;
    }
    // console.log('DEBUG before some');
    // console.log('DEBUG!!!!!!!!!!!!!!!!!!')
    // console.log('DEBUG isPermitted 104');
    // this.units.forEach(u => {
    //   console.log(u);
    // })
    // console.log('DEBUG isPermitted 105');
    return this.units.some(unit => { return unit.isPermitted(boxId, targetDate) });
  }
}
