import {Component, OnInit, AfterViewInit, NgZone, ElementRef} from '@angular/core';
import { Router } from "@angular/router";
import { PaymentService, CardInfo } from "../payment.service";
import { environment } from "../../environments/environment";
import { PaymentAlertDialog } from "../payment/payment-alert-dialog";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import * as firebase from 'firebase';
import {Overlay} from '@angular/cdk/overlay';
import {MatSpinner} from '@angular/material/progress-spinner';
import {ComponentPortal} from '@angular/cdk/portal';
import {DialogService} from '../dialogs/dialog.service';
import {UserOperationLoggerService} from '../user-operation-logger.service';

export interface SelectItem {
  value: string;
  viewValue: string;
}

/**
 * 画面項目の表示/非表示を制御するためのインタフェース。
 */
interface ICreditcardSettingsMode {
  isFetched(): boolean;
  isNotFetched(): boolean;
  hasCard(): boolean;
  hasNoCard(): boolean;
  shouldShowForm(): boolean;
  shouldNotShowForm(): boolean;
  shouldShowFormOpener(): boolean;
  shouldNotShowFormOpener(): boolean;
}

/**
 * クレジットカード情報をDBから取得している状態を表す。
 */
class Fetching implements ICreditcardSettingsMode {
  isFetched(): boolean {
    return false;
  }

  isNotFetched(): boolean {
    return true;
  }

  hasCard(): boolean {
    return false;
  }

  hasNoCard(): boolean {
    return true;
  }

  shouldShowForm(): boolean {
    return false;
  }

  shouldNotShowForm(): boolean {
    return true;
  }

  shouldShowFormOpener(): boolean {
    return false;
  }

  shouldNotShowFormOpener(): boolean {
    return true;
  }
}

/**
 * クレジットカード情報を登録するためのフォームを表示する状態。
 */
class RegisterMode implements ICreditcardSettingsMode {
  isFetched(): boolean {
    return true;
  }

  isNotFetched(): boolean {
    return false;
  }

  hasCard(): boolean {
    return false;
  }

  hasNoCard(): boolean {
    return true;
  }

  shouldShowForm(): boolean {
    return true;
  }

  shouldNotShowForm(): boolean {
    return false;
  }

  shouldShowFormOpener(): boolean {
    return false;
  }

  shouldNotShowFormOpener(): boolean {
    return true;
  }
}

/**
 * 登録済のクレジットカード情報を表示する状態。
 */
class ShowMode implements ICreditcardSettingsMode {
  isFetched(): boolean {
    return true;
  }

  isNotFetched(): boolean {
    return false;
  }

  hasCard(): boolean {
    return true;
  }

  hasNoCard(): boolean {
    return false;
  }

  shouldShowForm(): boolean {
    return false;
  }

  shouldNotShowForm(): boolean {
    return true;
  }

  shouldShowFormOpener(): boolean {
    return true;
  }

  shouldNotShowFormOpener(): boolean {
    return false;
  }
}

/**
 * クレジットカード情報を上書きするためのフォームを表示する状態。
 */
class EditMode implements ICreditcardSettingsMode {
  isFetched(): boolean {
    return true;
  }

  isNotFetched(): boolean {
    return false;
  }

  hasCard(): boolean {
    return true;
  }

  hasNoCard(): boolean {
    return false;
  }

  shouldShowForm(): boolean {
    return true;
  }

  shouldNotShowForm(): boolean {
    return false;
  }

  shouldShowFormOpener(): boolean {
    return false;
  }

  shouldNotShowFormOpener(): boolean {
    return true;
  }
}

@Component({
  selector: "app-payment-creditcard",
  templateUrl: "./payment-creditcard.component.html",
  styleUrls: ["./payment-creditcard.component.scss"]
})
export class PaymentCreditcardComponent implements OnInit, AfterViewInit {
  loading = false;
  expireMonth: SelectItem[] = []; // 有効期限(月)プルダウンの内容
  expireYear: SelectItem[] = []; // 有効期限(年)プルダウンの内容
  currentCard = null; // 取得済のクレジットカード情報
  formData = {
    cardno: "", // 入力中のカード番号
    expireM: "", // 選択中の有効(月)
    expireY: "", // 選択中の有効(年)
    name: "", // 入力中のカード名義
    code: "", // 入力中のセキュリティコード
  };
  errorMessages: string[] = []; // 画面上に表示するエラーメッセージ
  private _isFetchFinished: boolean;
  _uuid = "" // ログインユーザの UID
  private _shouldOpenForm = false; // 入力フォームを表示するかどうか

  private FETCHING = new Fetching();
  private REGISTER_MODE = new RegisterMode();
  private SHOW_MODE = new ShowMode();
  private EDIT_MODE = new EditMode();

  /**
   * 画面項目の表示/非表示を制御するためのオブジェクト
   */
  viewMode: ICreditcardSettingsMode = this.FETCHING;

  // ローディング中であることを表すオブジェクト
  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });
  private viewName: string;

  constructor(
    private router: Router,
    private pService: PaymentService,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private location: Location,
    private userOpeLogger: UserOperationLoggerService,
    private el: ElementRef,
    private overlay: Overlay
  ) {
    this._isFetchFinished = false;
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      this._uuid = currentUser.uid;
    }
  }

  async ngOnInit() {
    this.viewName = this.el.nativeElement.querySelectorAll('h2')[0].innerText;
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    try {
      // console.log('ngOnInit called ......');
      this.viewMode = this.FETCHING;
      this._isFetchFinished = false;
      this._shouldOpenForm = false;
      // 有効期限の年、月のプルダウン設定
      for (let i = 0; i < 12; i++) {
        const m = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
        this.expireMonth.push({ value: m, viewValue: m });
      }
      const year = new Date().getFullYear();
      for (let i = 0; i < 20; i++) {
        const y = (year + i).toString().slice(2);
        this.expireYear.push({ value: y, viewValue: y });
      }
      // PaymentService設定
      // この画面に遷移する前に、以下をそれぞれ設定してください
      // uid = ログイン中のユーザのuid (ex, ggZSYQHabBeoZOLb8T1Xs25HJhe2)
      // type = Androidブラウザの場合は web, iOSブラウザの場合は iOS (ex, web)
      // this.pService.uid = 'ggZSYQHabBeoZOLb8T1Xs25HJhe2'; // debug
      // this.pService.type = 'web'; // debug

      this.pService.uid = this._uuid;
      this.pService.type = 'web';

      // コンストラクタでuuidが設定できない場合がある
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        this._uuid = currentUser.uid;
      }

      if ( this._uuid ) {
        await this.fetchCard();
        if ( this.currentCard ) {
          this.viewMode = this.SHOW_MODE;
        } else {
          this.viewMode = this.REGISTER_MODE;
        }
        this._isFetchFinished = true;
      } else {
        // this._uuid が null だった場合、
        // クレジットカードの取得ができない。
        // この現象は、クレジットカード情報の変更画面にURL直接入力でアクセスしたり、
        // ブラウザリロードを行った場合に発生する。
        // これを回避するため、暫定的にメニューへ遷移させる。
        // this.overlayRef.detach();
        this.router.navigateByUrl('/menu');
      }
    } catch (error) {
      // console.log('ERROR...');
      // console.log(error);
      // console.log('status ....');
      // console.log(error['status']);
      // console.log('statusText ....');
      // console.log(error['statusText']);
    } finally {
      this.overlayRef.detach();
    }
  }

  ngAfterViewInit() {
    this._isFetchFinished = false;
    // this.pService.uid = firebase.auth().currentUser.uid;
    // this.pService.type = 'web';
    // クレジットカードのGMOトークン取得のライブラリを呼び出す
    const script = document.createElement("script");
    script.async = true;
    script.setAttribute("src", environment.payment.gmoTokenLibUrl);
    script.setAttribute("charset", "utf-8");

    const div = document.getElementById("content");
    div.parentNode.insertBefore(script, div.nextSibling);
    this._isFetchFinished = true;
  }

  // TODO DELETE (呼び出されていないため)
  get isFetchFinished(): boolean {
    return this._isFetchFinished;
  }

  // TODO DELETE (呼び出されていないため)
  get shouldOpenForm(): boolean {
    if (!this._isFetchFinished) {
      // fetch が終わっていない場合、フォームを表示しない
      return false;
    }
    // 登録されているカード情報がない場合、常にフォームを表示する
    if (this.currentCard == null) {
      return true;
    }
    return this._shouldOpenForm;
  }

  get amount() {
    return this.pService.amount;
  }

  /**
   * カードの有効期限をMM/YY形式に変換
   * @param expire YYMM
   */
  cardExpire(expire) {
    if ( expire ) {
      return this.pService.cardExpire(expire);
    }
    return '';
  }

  /**
   * クレジットカード取得
   * MEMO private method にして良いかもしれない
   */
  async fetchCard() {
    // console.log('DEBUG fetch card called (payment-creditcard.compnent.ts ...');
    this.currentCard = null;
    let result = null;
    try {
      result = await this.pService.fetchCreditCard();
    } catch (error) {
      if ( error['status'] === 403 && error['statusText'] === 'OK' ) {
        // クレジットカードを1度も登録したことがない場合、カードが取得できなかったとみなす
        // （正常系として扱う)
        result = null;
      } else {
        // それ以外の例外はスローする
        throw error;
      }
    }
    // console.log('fetchCard', result);
    if (result) {
      this.currentCard = {
        cardSeq: String(result['cardSeq']),
        cardNo: String(result['cardNo']),
        expire: String(result['expire'])
      };
    } else {
      this.currentCard = null;
    }
    if ( this.currentCard ) {
      this.viewMode = this.SHOW_MODE;
    } else {
      this.viewMode = this.REGISTER_MODE;
    }
  }

  async confirmRemoveCard(event) {
    // console.log('DEBUG confirmRemoveCard');
    // console.log(this.currentCard.cardSeq);
    // 再取得
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    this.viewMode = this.FETCHING;
    await this.fetchCard();
    this.overlayRef.detach();
    this.dialogService.beforeRemoveCardComponent(this.currentCard.cardSeq);
  }
  /**
   * クレジットカード削除
   * @param card
   * @param event
   */
  async removeCard() {
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    this.viewMode = this.FETCHING;
    if (!this.currentCard) {
      return;
    }

    // MEMO result はデバッグ用の変数
    const result = await this.pService.removeCreditCard(this.currentCard.cardSeq);
    // console.log('removeCard', result);
    // this._shouldOpenForm = true;

    // 再取得
    await this.fetchCard();
    this.overlayRef.detach();
  }

  /**
   * クレジットカード登録 / 変更
   */
  async registerCard(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    const cardInfo:CardInfo = {
      cardno: this.formData.cardno,
      expireM: this.formData.expireM,
      expireY: this.formData.expireY,
      name: this.formData.name,
      code: this.formData.code
    };
    // バリデーションチェック
    this.errorMessages = [];
    const validateResult = this.pService.validationCreditCard(cardInfo);
    if (!validateResult.result) {
      this.errorMessages = validateResult.errorMessages;
      return;
    }
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    const result = await this.pService.registerCreditCard(cardInfo);
    await this.fetchCard();
    this.overlayRef.detach();
    this.viewMode = this.SHOW_MODE;
    // this._shouldOpenForm = false;
    // console.log('registerCard', result);
  }

  /**
   * メッセージダイアログを表示
   * @param message
   */
  showAlert(message) {
    this.hideLoading(() => {
      this.dialog.open(PaymentAlertDialog, { data: { message: message } });
    });
  }

  /**
   * ローディングを非表示にする
   * @param func
   */
  hideLoading(func) {
    this.ngZone.run(() => {
      this.loading = false;
      func();
    });
  }

  /**
   * 前の画面に戻る
   */
  cancel(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    this.location.back();
    // this.router.navigate(["/payment"]);
  }

  openForm(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    this.currentCard = null;
    this.viewMode = this.EDIT_MODE;
    this._shouldOpenForm = true;
  }
}
