import { SendKeyUrl } from "./send-key-url";

export class Mylocker {
  address;
  device;
  sendKeyURL;
  product;
  startAt;
  status;
  isCleaning;
  cleaningShopName;
  cleaningShopNumber;
  cleaningURL;
  prepaid;
  isReserved;

  constructor() {}

  /**
   * sendKeyUrl に含まれる値を、このオブジェクトに反映する。
   * URL だけは明示する。
   */
  applySendKeyURL(sendKeyUrl: SendKeyUrl, url: string) {
    // console.log('DEBUG applySendKeyURL called ');
    this.address = sendKeyUrl.address;
    this.device = sendKeyUrl.device;
    this.sendKeyURL = url;
    this.product = sendKeyUrl.product;
    this.status = sendKeyUrl.status;
    this.isCleaning = sendKeyUrl.isCleaning;
    this.cleaningShopName = sendKeyUrl.cleaningShopName;
    this.cleaningShopNumber = sendKeyUrl.cleaningShopNumber;
    this.cleaningURL = sendKeyUrl.cleaningURL;
    this.startAt = sendKeyUrl.startAt;
    this.prepaid = sendKeyUrl.prepaid;
    this.isReserved = sendKeyUrl.hasReserved;
    // console.log('DEBUG START sendKeyUrl');
    // console.log(sendKeyUrl);
    // console.log('DEBUG END sendKeyUrl');

    // if (sendKeyUrl['startAt']) {
    //   this.startAt = sendKeyUrl['startAt'];
    // }
  }

  applyItem(item) {
    // console.log('DEBUG ITEMMMMMMMMMMMMMMMMM');
    // console.log('DEBUG START item');
    // console.log(item);
    // console.log('DEBUG END item');

    this.address = item['address'];
    this.device = item['device'];
    this.sendKeyURL = item['sendKeyURL'];
    this.product = item['product'];
    this.startAt = item['startAt'];
    this.status = item['status'];
    this.isCleaning = item['isCleaning'];
    this.cleaningShopName = item['cleaningShopName'];
    this.cleaningShopNumber = item['cleaningShopNumber'];
    this.cleaningURL = item['cleaningURL'];
    this.prepaid = item['prepaid'];
    // console.log('DEBUG START item');
    // console.log(item);
    // console.log('DEBUG END item');
  }
}
