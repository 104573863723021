import { Component, Input, OnInit } from "@angular/core";
import {UserOperationLoggerService} from '../user-operation-logger.service';
// import { GlobalMenuItem } from "./global-menu-item";

@Component({
  selector: "app-global-menu-item",
  templateUrl: "./global-menu-item.component.html",
  styleUrls: ["./global-menu-item.component.scss"]
})
export class GlobalMenuItemComponent implements OnInit {
  @Input() imageCurrentUrl: string;
  @Input() imageDefaultUrl: string;
  @Input() text: string;
  @Input() targetUrl: string;
  private viewName: string;
  constructor(
    private userOpeLogger: UserOperationLoggerService
  ) {}

  ngOnInit(): void {
    this.viewName = 'グローバルメニュー';
  }

  userOpeLog() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, this.text);
  }
}
