import { Injectable } from '@angular/core';
import './web-bluetooth';
import Bluetooth = webbluetooth.Bluetooth;

@Injectable({
  providedIn: 'root'
})
export class BluetoothRefService {

  // Angular ver 9 へアップデート後、bluetooth に関するエラーが発生しました。
  // この Service はエラー対策のために作成しました。
  // 以下、エラー内容と対策時の参考URLです。
  ///////////////////////////////////////////////
  // 発生したエラーのメッセージ：
  // 「Property bluetooth does not exist on type ‘Navigator'」
  // 参考記事URL：
  // https://stackoverflow.com/questions/51298406/property-bluetooth-does-not-exist-on-type-navigator
  // 上記記事を参考に https://www.npmjs.com/package/@types/web-bluetooth をインストールし、
  // bluetooth 利用のためのメソッドを定義しました。

  private readonly mobileNavigatorObject: Navigator = null;
  constructor() {
    this.mobileNavigatorObject = window.navigator;
  }

  get bluetooth(): Bluetooth {
    if (this.mobileNavigatorObject && this.mobileNavigatorObject.bluetooth) {
      return this.mobileNavigatorObject.bluetooth;
    }
    return null;
  }
}
