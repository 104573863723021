import { AfterViewInit, Component } from '@angular/core';
import { TutorialPage } from './tutorial-page';
import { TutorialPages } from './tutorial-pages';
import { Router } from '@angular/router';
import {FirstPaymentFreeService} from "../first-payment-free.service";

@Component({
  selector: 'app-tutorial-component',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements AfterViewInit {
  private _tutorialPages: TutorialPages;

  constructor(
    private router: Router,
    private firstPaymentFreeService: FirstPaymentFreeService,
  ) {
    this.initializeTutorialPages();
  }

  private initializeTutorialPages() {
    this._tutorialPages = new TutorialPages();
  }

  public get tutorialPages(): TutorialPage[] {
    return this._tutorialPages.tutorialPages;
  }

  toNextPage() {
    this._tutorialPages.toNextPage();
  }

  finishTutorial() {
    this.firstPaymentFreeService.isTutorialFinishedAfterLoad = true;
    this.router.navigateByUrl('/put');
  }

  ngAfterViewInit(): void {
    // const footer = document.getElementById('footer');
    // footer.hidden = true;
  }
}
