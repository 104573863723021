import { Injectable } from "@angular/core";
import { md5 } from "./md5";
import { HttpJsonService } from "./http-json.service";
import { HttpParams } from "@angular/common/http";
import { AngularFireDatabase } from "@angular/fire/database";
import { environment } from "../environments/environment";

/**
 * クレジットカード情報
 */
export interface CardInfo {
  cardno: string; // クレジットカード番号
  expireM: string; // 有効期限(月)
  expireY: string; // 有効期限(年)
  name: string; // カード名義
  code: string; // セキュリティコード
}

@Injectable({
  providedIn: "root"
})

// 連想配列に型つけを試みたが失敗。その名残。
// export class ResultFromExecPaymentCarrier {
//   constructor(public errorCode: string,
//               public message: string,
//               public result: boolean,
//               public redirectParams: any) {
//   }
// }

export class PaymentService {
  uid = "";
  type = "web";
  spacerid = "";
  amount = 0;
  _currentCard;
  shouldStop = false;

  constructor(
    private httpjson: HttpJsonService,
    private db: AngularFireDatabase
  ) {}

  /**
   * MD5ハッシュ化
   * @param textq
   */
  getMd5Message(text) {
    return md5(text);
  }

  /**
   * ランダム文字列生成
   */
  getRandom() {
    let random = "";
    const c = "aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ0123456789";
    const cl = c.length;
    for (let i = 0; i < 18; i++) {
      random += c[Math.floor(Math.random() * cl)];
    }
    return random;
  }

  /**
   * DBのcheckStringにチェック用ハッシュを保存
   * @param random
   * @param md5_message
   */
  async setToDBCheckString(random, md5_message) {
    await this.db.object("checkString/" + random).set({ md5: md5_message });
    return true;
  }

  /**
   * カードの有効期限をMM/YY形式に変換
   * @param expire YYMM
   */
  cardExpire(expire) {
    const y = expire.slice(0, 2);
    const m = expire.slice(2);
    return m + "/" + y;
  }

  /**
   * クレジットカード取得APIを呼び出す。
   * 取得したクレジットカード情報は this._currentCard へ保持する。
   * 必要なパラメータ
   * paymentService.uid : ログインユーザのuid
   * paymentService.type : web / iOS
   */
  async fetchCreditCard() {
    const random = this.getRandom();
    const md5_message = this.getMd5Message(
      random + this.uid + this.type
    );
    await this.setToDBCheckString(random, md5_message);

    const url = `${environment.apiUrl}payment/getCard/${random}/${this.uid}/${this.type}/${md5_message}`;
    // console.log('random', random);
    // console.log('this.uid', this.uid);
    // console.log('this.type', this.type);
    // console.log('md5_message', md5_message);
    // console.log('url....');
    // console.log(url);
    // const res = await this.httpJsonRetry.getJson(url);
    if (!this._currentCard) {
        try {
          const res = await this.httpjson.getJson(url);
          if ( res['cardSeq'] || res['cardNo'] || res['expire'] ) {
            this._currentCard = {
              cardSeq: String(res['cardSeq']),
              cardNo: String(res['cardNo']),
              expire: String(res['expire'])
            };
            return res;
          }
        } catch (error) {
          // console.log('error...')
          // console.log(error);
          return null;
        }
    } else {
      return this._currentCard;
    }
  }

  /**
   * 現在のクレジットカード情報
   */
  get currentCard() {
    return this._currentCard;
  }

  /**
   * クレジットカード登録API
   * @param cardInfo クレジットカード情報
   * 必要なパラメータ
   * paymentService.uid : ログインユーザのuid
   * paymentService.type : web / iOS
   */
  async registerCreditCard(cardInfo:CardInfo) {
    // トークンを取得
    const token = await this.getCreditCardToken(cardInfo);
    const random = this.getRandom();
    const md5_message = this.getMd5Message(
      random + this.uid + this.type
    );
    await this.setToDBCheckString(random, md5_message);
    const url = `${environment.apiUrl}payment/registerCredit`;
    let params = new HttpParams();
    params = params.append("random", random);
    params = params.append("uid", this.uid);
    params = params.append("type", this.type);
    params = params.append("token", String(token));
    params = params.append("md5_message", md5_message);
    const res = await this.httpjson.getJsonByPost(url, params);
    return res;
  }

  /**
   * クレジットカード削除API
   * @param cardSeq カード登録連番
   * 必要なパラメータ
   * paymentService.uid : ログインユーザのuid
   * paymentService.type : web / iOS
   */
  async removeCreditCard(cardSeq) {
    if ( this.shouldStop ) {
      this.shouldStop = false;
      return
    }
    const random = this.getRandom();
    const md5_message = this.getMd5Message(
      random + this.uid + this.type + String(cardSeq)
    );
    await this.setToDBCheckString(random, md5_message);
    const url = `${environment.apiUrl}payment/removeCard`;
    let params = new HttpParams();
    params = params.append("random", random);
    params = params.append("uid", this.uid);
    params = params.append("type", this.type);
    params = params.append("cardSeq", cardSeq);
    params = params.append("md5_message", md5_message);
    const res = await this.httpjson.getJsonByPost(url, params);
    this._currentCard = null;
    return res;
  }

  /**
   * クレジットカードのトークン取得
   * @param cardInfo クレジットカード情報
   */
  async getCreditCardToken(cardInfo:CardInfo) {
    window["Multipayment"].init(environment.payment.gmoTokenApiKey);
    const cardObject = {
      cardno: cardInfo.cardno,
      expire: `${cardInfo.expireY}${cardInfo.expireM}`,
      holdername: cardInfo.name,
      securitycode: cardInfo.code,
    };
    return new Promise((resolve, reject) => {
      window["Multipayment"].getToken(cardObject, result => {
        if (result.tokenObject && typeof result.tokenObject.token !== 'undefined') {
          const token = result.tokenObject.token;
          resolve(token);
        } else {
          // console.log('クレジットカードトークン取得エラー', result.resultCode);
          reject();
        }
      });
    });
  }

   /**
    * クレジットカードのバリデーションチェック
    * @param cardInfo クレジットカード情報
    */
  validationCreditCard(cardInfo:CardInfo) {
    let result = true;
    const errorMessages = [];
    const now = new Date();
    const currentDate = Number(
      String(now.getFullYear()) + String("0" + (now.getMonth() + 1)).slice(-2)
    );

    if (!cardInfo.cardno) {
      result = false;
      errorMessages.push("カード番号が入力されていません。");
    } else {
      if (
        cardInfo.cardno.length < 10 ||
        cardInfo.cardno.length > 16
      ) {
        result = false;
        errorMessages.push(
          "カード番号は10桁以上16桁以内で入力してください。"
        );
      } else if (!cardInfo.cardno.match(/[0-9]/g)) {
        result = false;
        errorMessages.push("カード番号は数字で入力してください。");
      }
    }
    if (!cardInfo.expireM || !cardInfo.expireY) {
      result = false;
      errorMessages.push("有効期限が指定されていません。");
    } else {
      const newCardExpire =
        "20" + cardInfo.expireY + cardInfo.expireM;
      if (Number(newCardExpire) < currentDate) {
        result = false;
        errorMessages.push("有効期限が切れています。");
      }
    }
    if (!cardInfo.name || !cardInfo.name.trim().length) {
      result = false;
      errorMessages.push("カード名義が入力されていません。");
    }
    if (!cardInfo.code) {
      result = false;
      errorMessages.push("セキュリティコードが入力されていません。");
    } else {
      if (
        cardInfo.code.length < 3 ||
        cardInfo.code.length > 4
      ) {
        result = false;
        errorMessages.push(
          "セキュリティコードは3桁以上4桁以内で入力してください。"
        );
      } else if (!cardInfo.code.match(/[0-9]/g)) {
        result = false;
        errorMessages.push(
          "セキュリティコード号は数字で入力してください。"
        );
      }
    }
    return {
      result,
      errorMessages
    };
  }

  // TODO DELETE (使用されていません)
  async calcuPriceAndExecPaymentCreditCard(cardSeq) {
    // TODO 料金計算
    // TODO 計算
  }

  /**
   * クレジットカード決済API
   * 必要なパラメータ
   * paymentService.uid : ログインユーザのuid
   * paymentService.type : web / iOS
   * paymentService.amount : 支払い金額
   * paymentService.spacerid : spacerid
   * @param cardSeq カード登録連番
   */
  async execPaymentCreditCard(cardSeq, isPrepaid: boolean) {
    const random = this.getRandom();
    const md5_message = this.getMd5Message(
      random +
      this.uid +
      String(this.amount) +
      this.type +
      this.spacerid
    );
    await this.setToDBCheckString(random, md5_message);
    const url = `${environment.apiUrl}payment/credit`;
    let params = new HttpParams();
    params = params.append("random", random);
    params = params.append("uid", this.uid);
    params = params.append("amount", String(this.amount));
    params = params.append("type", this.type);
    params = params.append("cardSeq", String(cardSeq));
    params = params.append("spacerid", this.spacerid);
    params = params.append("md5_message", md5_message);
    params = params.append("isPrepaid", isPrepaid ? 'true' : 'false');
    let errorCode = '';
    let message = '';
    let result = true;
    try {
      const res = await this.httpjson.getJsonByPost(url, params);
      if (typeof res["error"] !== "undefined") {
        for (let i = 0; i < res["error"]["info"].length; i++) {
          errorCode = errorCode + "[" + res["error"]["info"][i] + "]";
        }
        message = `決済に失敗しました。\n\n時間をおいて再度お試し下さい。\n\n エラーコード:${errorCode}`
        result = false;
      }
    } catch (error) {
      // console.log("error", error);
      message = 'エラーが発生しました。\n\n時間をおいて再度お試し下さい。';
      result = false;
    }
    return {
      result,
      errorCode,
      message
    }
  }

  /**
   * au / ドコモ / ソフトバンク決済/ PayPayAPI
   * 必要なパラメータ
   * paymentService.uid : ログインユーザのuid
   * paymentService.type : web / iOS
   * paymentService.amount : 支払い金額
   * paymentService.spacerid : spacerid
   * @param carrierType au / docomo / softbank / paypay
   */
  async execPaymentCarrier(carrierType, isPrepaid: boolean) {
  // async execPaymentCarrier(carrierType): Promise<ResultFromExecPaymentCarrier> {
    const random = this.getRandom();
    const md5_message = this.getMd5Message(
      random +
        this.uid +
        String(this.amount) +
        this.type +
        this.spacerid
    );
    await this.setToDBCheckString(random, md5_message);

    const url = `${environment.apiUrl}payment/${carrierType}`;
    let params = new HttpParams();
    params = params.append("random", random);
    params = params.append("uid", this.uid);
    params = params.append("amount", String(this.amount));
    params = params.append("type", this.type);
    params = params.append("spacerid", this.spacerid);
    params = params.append("md5_message", md5_message);
    params = params.append("isPrepaid", isPrepaid ? 'true' : 'false');

    let errorCode = '';
    let message = '';
    let result = true;
    let redirectParams = null;
    try {
      const res = await this.httpjson.getJsonByPost(url, params);
      if (typeof res["error"] !== "undefined") {
        for (let i = 0; i < res["error"]["info"].length; i++) {
          errorCode = errorCode + "[" + res["error"]["info"][i] + "]";
        }
        message = `決済に失敗しました。\n\n時間をおいて再度お試し下さい。\n\n エラーコード:${errorCode}`
        result = false;
      } else {
        // リダイレクト用のパラメータをセットする
        redirectParams = {
          startUrl: res["result"]["StartURL"],
          asccessId: res["result"]["AccessID"],
          token: res["result"]["Token"]
        };
      }
    } catch (error) {
      // console.log("error", error);
      message = 'エラーが発生しました。\n\n時間をおいて再度お試し下さい。';
      result = false;
    }
    return {
      result,
      redirectParams,
      errorCode,
      message
    }
    // return new ResultFromExecPaymentCarrier(errorCode, message, result, redirectParams);
  }


}
