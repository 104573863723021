import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root-redirector',
  templateUrl: './root-redirector.component.html',
  styleUrls: ['./root-redirector.component.scss']
})
export class RootRedirectorComponent {

  constructor(private router: Router) {
    this.router.navigateByUrl('/mylockers');
  }
}
