import {Component, ElementRef, OnInit} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { PaymentService } from '../payment.service';
import { Overlay } from '@angular/cdk/overlay';
import { MatSpinner } from '@angular/material/progress-spinner';
import { ComponentPortal } from '@angular/cdk/portal';
import {UserOperationLoggerService} from '../user-operation-logger.service';
import {environment} from '../../environments/environment';
import {PartnerMembersRepositoryImplService} from "../repository-impl/partner-members-repository-impl.service";
import {PartnerMember} from "../entity/partner-member";
import * as moment from 'moment';
import {PartnersRepositoryImplService} from "../repository-impl/partners-repository-impl.service";
import {Partner} from "../entity/partner";

@Component({
  selector: 'app-other-menu',
  templateUrl: './other-menu.component.html',
  styleUrls: ['./other-menu.component.scss'],
})
export class OtherMenuComponent implements OnInit {
  // ローディング中であることを表すオブジェクト
  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically(),
  });
  private viewName: string;
  testReserveModel = {deviceId: '12345'}
  partnerMember = this.repositoryPartnerMember.currentPartnerMemberObservable;
  partner = this.repositoryPartner.currentPartnerObservable;
  // testPartnerMember$ = this.repositoryPartnerMember.partnerMember$.pipe(
  //
  // )

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private el: ElementRef,
    private userOpeLogger: UserOperationLoggerService,
    private overlay: Overlay,
    private paymentService: PaymentService,
    private repositoryPartnerMember: PartnerMembersRepositoryImplService,
    private repositoryPartner: PartnersRepositoryImplService,
  ) {
    // console.log('OtherMenuComponent constructor');
  }

  async ngOnInit() {
    this.viewName = this.el.nativeElement.querySelectorAll('h2')[0].innerText;
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    await this.paymentService.fetchCreditCard();
    this.overlayRef.detach();
    this.router.navigate(['menu']);
    // dummy code start

    // dummy code end
  }

  public logout() {
    this.userOpeLogger.logViewAndButtonName(this.viewName, 'ログアウト');
    this.auth.signOut().then((success) => {
      this.router.navigate(['/login']);
      window.location.reload();
    });
  }

  get hasCreditCard(): boolean {
    if (this.paymentService.currentCard) {
      return true;
    } else {
      return false;
    }
  }

  get showDebugView(): boolean {
    return environment.showDebugView
  }

  clickLink(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
  }

  testReserve() {
    const url = `reserve/R${this.testReserveModel.deviceId}`
    this.router.navigateByUrl(url);
  }

  // get valueFromSnapshot() {
    // return this.repositoryPartnerMember.valueFromSnapshot
  // }

  getName(obj) {
    return obj.name;
  }

  // get testPartnerMemberObject() {
  //   return this.repositoryPartnerMember.currentPartnerMember();
  // }
  //
  // get testPartnerObject(): Partner {
  //   return this.repositoryPartner.currentPartner;
  // }

  mom(string): moment.Moment {
    return moment(string);
  }
}
