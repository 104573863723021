import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReserveService } from '../services/reserve.service';
import { DialogService } from '../../dialogs/dialog.service';
import { ReserveApiService } from '../../reserve-api.service';
import {PaymentService} from '../../payment.service';
import * as firebase from 'firebase';
import {CheckPutLimitApiService} from "../../check-put-limit-api.service";
import {PartnerMembersRepositoryImplService} from "../../repository-impl/partner-members-repository-impl.service";

@Component({
  templateUrl: './reserve-confirm.component.html',
  styleUrls: ['./reserve-confirm.component.scss']
})
export class ReserveConfirmComponent implements OnInit {
  // ロッカーの予約を行うための component。
  private id: string;
  private _partnerMember;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reserveService: ReserveService,
    private reserveApiService: ReserveApiService,
    private paymentService: PaymentService,
    private checkPutLimitApiService: CheckPutLimitApiService, // 預け入れ制限判定 Service
    private partnerMemberRepository: PartnerMembersRepositoryImplService,
    private _dialog: DialogService
  ) {
    this.partnerMemberRepository.refreshObserver();
    this.partnerMemberRepository.currentPartnerMemberObservable.subscribe(partnerMember => {
      this._partnerMember = partnerMember;
    })
  }

  async ngOnInit() {
    // 本来はダイアログ上でこの処理をおこなったほうがよいのかも...
      this.paymentService.uid = firebase.auth().currentUser.uid;
      await this.paymentService.fetchCreditCard();
      this.activatedRoute.params.subscribe( async params => {
      this.id = params['id'];
      this.paymentService.spacerid = params['id'];
      this.reserveApiService.id = params['id'];

      if ( this.paymentService.currentCard ) {
        // クレジットカード情報が存在する場合、特にエラーなどは出さない
      } else {
        // クレジットカード情報が存在しない場合、クレジットカードを要求する画面を表示する
      //  this._dialog.requireCardComponent();
      }

      const checkResult = await this.checkPutLimitApiService.checkPutLimit(this.id);
      if (checkResult) {
        if (checkResult['result'] === 'true') {
          // 預け入れ可能な場合、予約確認ダイアログを表示する
         // this._dialog.reserveCautionDialog();
        } else {
          // 預け入れ制限されている場合
          alert(this.checkPutLimitApiService.messageLimit)
        }
      } else {
        //　預け入れ可否を判定できない場合、預け入れ可能として取り扱う
       // this._dialog.reserveCautionDialog();
      }

//追記＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
//    this.userOpeLogger.logViewAndButtonName(this.viewName, 'OK');
    try {
      // console.log('DEBUG reserve 001');
      // 予約した瞬間から料金の計算が始まる。しかし、このタイミングで決済が行われるわけではない。よってコメントアウト
      // await this.paymentService.execPaymentCreditCard(this.paymentService.currentCard.cardSeq);
      // console.log('DEBUG reserve 002');
      // 予約を実行する(マイロッカーへデータを追加する)
      const response = await this.reserveApiService.reserve();
      this._dialog.reserveCautionDialog();
      // console.log('DEBUG reserve 003');
      // console.log('DEBUG result...');
      // console.log(response);
      if (response['result'] === 'true') {
//        this.router.navigateByUrl('thankyou/afterReserve');
      } else if (response['result'] === 'false') {
        // console.log(response['error']);
        // console.log(response['error']['code']);
        if (response['error']['code'] === 'already_used') {
          alert('既に利用されているロッカーのため、予約できませんでした。');
//          this.router.navigateByUrl('maps');
        }
      }
//      this.dialogRef.close();

    } catch (error) {
      // console.log('ERROR....');
      // console.log(error);
    }


//追記ここまで＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊


    });
  }

  get shouldShowConfirmationArea() {
    return (
      this.reserveService.isBeforeRequest || this.reserveService.isSendRequest
    );
  }

  get shouldEnableOkButton() {
    return this.reserveService.isBeforeRequest;
  }

  get shouldShowReservedArea() {
    return this.reserveService.isReserved;
  }

  get shouldShowNotReservedArea() {
    // TODO : 予約に失敗した場合にエラー画面を表示する。
    // TODO : TBD : エラー画面からの画面遷移。
    return this.reserveService.isNotReserved;
  }
}
