import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { sanitizeIdentifier } from "@angular/compiler";

@Component({
  selector: "app-header-medium",
  templateUrl: "./header-medium.component.html"
  // styleUrls: ['']
})
export class HeaderMediumComponent implements OnInit {
  @Input() text = "";

  constructor() {}

  ngOnInit(): void {}
}
