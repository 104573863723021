import { Component, OnInit } from '@angular/core';
import {MembersRepositoryService} from "../members-repository-service";
import {DialogService} from "../dialogs/dialog.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductForm} from "../product-form";
import {ProductApiService} from "../product-api.service";
import {SpacerErrorAnnouncer} from "../lib/spacer-error-handle/spacer-error-handle.service";
import {ComponentPortal} from "@angular/cdk/portal";
import {MatSpinner} from "@angular/material/progress-spinner";
import {Overlay} from "@angular/cdk/overlay";
import {Mylocker} from "../entity/mylocker";
import {CheckProductSetApiService} from "../check-product-set-api.service";
import {PartnerMembersRepositoryImplService} from "../repository-impl/partner-members-repository-impl.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-share-with-price',
  templateUrl: './share-with-price.component.html',
  styleUrls: ['./share-with-price.component.scss']
})
export class ShareWithPriceComponent implements OnInit {

  productForm: ProductForm;

  // ローディング中であることを表すオブジェクト
  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically(),
  });
  private defaultPartnerName: string;
  sendKeysObservable = this.membersRepository.sendKeysObservable[this.deviceNum];
  fetched = false;

  constructor(private _member: MembersRepositoryService,
              private _dialog: DialogService,
              private activatedRoute: ActivatedRoute,
              private overlay: Overlay,
              private router: Router,
              private membersRepository: MembersRepositoryService,
              private productApiService: ProductApiService,
              private checkProductSetApiService: CheckProductSetApiService,
              private partnerMemberRepository: PartnerMembersRepositoryImplService) {
    this.partnerMemberRepository.refreshObserver();
    this.productForm = new ProductForm();
    this._mylocker = new Mylocker();

    // デフォルトの送り主名をフォームに表示する処理
    this.partnerMemberRepository.currentPartnerMemberObservable.subscribe(partnerMember => {
      this.defaultPartnerName = partnerMember.name;
      this.productForm.senderName = partnerMember.name;
    })

    this.membersRepository.checkRepository()
  }

  private get messageAfterSetPrice(): string {
    return "金額を設定しました。";
  }

  private get deviceNum(): string {
    return `SPACER${this.activatedRoute.snapshot.paramMap.get('device')}`;
  }

  private _mylocker: Mylocker;

  async ngOnInit() {
    let canProductSet;
    try {
      // document['DEBUG_PM'] = this.partnerMemberRepository;
      // document['DEBUG_P'] = this.partnerRepository;
      // console.log('OK DEBUG');
    } catch (e) {
      // console.log('ERRRR');
      // console.log(e);
    }
    // TODO implement productSet を用いた商品設定判定を行い、権限がなければマイロッカーへ遷移する
    canProductSet = await this.checkProductSetApiService.checkProductSet(this.deviceNum);
    if(!canProductSet) {
      await this.router.navigateByUrl('mylockers');
    }
    const mylockerItem = await this.membersRepository.getMylockerBySpacerid(this.deviceNum);

    if (!mylockerItem) {
      await this.router.navigateByUrl('mylockers');
    }
    this._mylocker.applyItem(mylockerItem);

    this.productForm.deviceNum = this.deviceNum;
    if (this._mylocker.product) {
      this.productForm.price = this._mylocker.product.price
      this.productForm.desc = this._mylocker.product.desc
      this.productForm.senderName = this._mylocker.product.senderName
    } else {
      const member = await this.partnerMemberRepository.asyncCurrentPartnerMember();
      this.productForm.senderName = member.name;
    }
  }

  async clickShare() {
    if (!this.productForm.validate) {
      new SpacerErrorAnnouncer(this.productForm.errorForDialog).announce();
      return;
    }
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    let success = false;
    try {
      let apiResponse;
      try {
        apiResponse = await this.productApiService.sendProduct(this.productForm);

        if(apiResponse['error']) {
          const errorMessage = apiResponse['error']['message']
          new SpacerErrorAnnouncer(errorMessage)
            .setSpacerId(this.productForm.deviceNum)
            .announce();
        } else {
          new SpacerErrorAnnouncer(this.messageAfterSetPrice).announce();
          success = true;
        }
      } catch(e) {
        throw e;
      }
    } catch(e) {
      // console.log('ERR....');
      // console.log(e);
      new SpacerErrorAnnouncer('エラーが発生しました。時間をおいて再度お試しください。').announce();
    } finally {
      this.overlayRef.detach();
    }
    if (success) {
      this.router.navigateByUrl(`choice-share-type/${this.productForm.deviceNum}`);
    }
  }

  clickCancel() {
    this.router.navigateByUrl('/mylockers');
  }
}
