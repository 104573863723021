<div *ngFor="let page of tutorialPages; let i = index">
  <div [hidden]="!page.currentPage" class="page-container">
    <div class="skipbutton-flex">
      <p><a (click)="finishTutorial()" class="arrow" >スキップ</a></p>
      <!-- <mat-icon>keyboard_arrow_right</mat-icon> -->
    </div>
    <div class="image-container">
      <img src="assets/images/ui-ver2.0/{{ page.imagePath }}" />
    </div>
    <div class="text-content">
      <h1>{{ page.title }}</h1>
      <h4 [innerHTML]="page.description"></h4>
    </div>
    <!-- ドット -->
    <div class="dots-container">
      <ng-container *ngFor="let dummyPage of tutorialPages; let j = index">
        <div
          *ngIf="i === j; else notSelectedDot"
          class="selected-dot dot"
        ></div>
        <ng-template #notSelectedDot>
          <div class="not-selected-dot dot"></div>
        </ng-template>
      </ng-container>
      <div class="after-dots"></div>
    </div>
    <!-- 次へボタン -->
    <div class="skipbutton-flex" *ngIf="page.hasNext; then nextBlock else finishBlock">
      <!-- <mat-icon>keyboard_arrow_right</mat-icon> -->
    </div>
<ng-template #finishBlock>
 <button class="large-black" (click)="finishTutorial()">はじめる</button>
    </ng-template>
    <ng-template #nextBlock>
      <button class="large-black" (click)="toNextPage()">次 へ</button>
    </ng-template>

 </div>
</div>
