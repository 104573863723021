import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
  messege: string;

  constructor() {}

  setMessege(text: string) {
    this.messege = text;
  }
  getMessege() {
    return this.messege;
  }
  setMessege2(text: string) {
    this.messege = text;
  }
  getMessege2() {
    return this.messege;
  }
  setMessege3(text: string) {
    this.messege = text;
  }
  getMessege3() {
    return this.messege;
  }
 
}

