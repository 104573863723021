<div id="content">
  <h2>マイロッカー</h2>
  <ng-container class="mybox-box" *ngFor="let box of boxes">
    <div class="mybox-box">
      <ng-container [ngSwitch]="box.useType">
        <ng-container *ngSwitchCase="'use'">
          <app-myboxes-box-use [box]="box"></app-myboxes-box-use>
        </ng-container>
        <ng-container *ngSwitchCase="'reserved'">
          <app-myboxes-box-reserved [box]="box"></app-myboxes-box-reserved>
        </ng-container>
        <ng-container *ngSwitchCase="'editableProduct'">
          <app-myboxes-box-editable-product [box]="box"></app-myboxes-box-editable-product>
        </ng-container>
        <ng-container *ngSwitchCase="'withProduct'">
          <app-myboxes-box-with-product [box]="box"></app-myboxes-box-with-product>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
<app-global-menu-component></app-global-menu-component>
