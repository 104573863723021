import { Component, OnInit } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { MatDialog } from "@angular/material/dialog";
import { HttpJsonService } from "../http-json.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { map, take } from "rxjs/operators";
import { md5 } from "../md5";
import { TicketDialog } from "../main-nav/main-nav.component";
import { ApartDialog } from "./apart-dialog";
import { CleaningDialog } from "./cleaning-dialog";
import { CrowdWashDialog } from "./crowd-wash-dialog";
import { LaundryPackDialog } from "./laundry-pack-dialog";
import {BluetoothRefService} from '../bluetooth-ref.service';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  uid: string;
  spacers: Observable<any[]>;
  cw_matches: Observable<any[]>;

  is_default = true;
  is_iOS = false;
  is_expired = false;
  is_clicked = false;
  is_cleaning = false;
  is_apart = false;
  is_laundry_pack = false;
  is_crowdwash = false;
  is_dailyTicket = false;
  apartValues = {};
  data: any;
  urlId: string;
  urlKey: string;
  expired: string;
  cleaningShopName: string;
  cleaningShopNumber: string;
  cleaningURL: string;

  unlockableSpacerNum = 0;
  selectedSpacer: string;
  serviceUuid: number = parseInt("0xff10");
  characteristicUuid: number = parseInt("0xff11");
  readonly fbUsers: string = "members/";
  selectText = "使いたいSPACERを選択";
  findText = "カギを閉めるSPACERを選択";
  openText = "カギを開ける";
  ticketText = "チケットを利用する";
  ticketExplainText = "1チケット = プラス6時間";
  restrictSpacer = "SPACER";

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private _httpjson: HttpJsonService,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private bluetoothRef: BluetoothRefService
  ) {
    this.auth.onAuthStateChanged(user => {
      if (!user) {
        return;
      }
      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        alert("iOSではWebBluetoothの機能はご利用頂けません");
        this.is_iOS = true;
      }

      this.uid = user.uid;
      const ref = db.list(this.fbUsers + this.uid + "/spacers");
      this.spacers = ref.snapshotChanges().pipe(
        map(changes => {
          if (this.unlockableSpacerNum > changes.length) {
            this.is_default = true;
          }
          this.unlockableSpacerNum = changes.length;
          return changes.map(c => ({
            key: c.payload.key,
            url: c.payload.val()
          }));
        })
      );
    });
  }

  ngOnInit() {
    const message = this.route.snapshot.queryParams["message"];
    if (message) {
      alert(message);
      this.router.navigateByUrl("");
    }
    this.restrictSpacer = this.route.snapshot.queryParams["restrict"];
    if (!this.restrictSpacer) {
      this.restrictSpacer = "SPACER";
    } else {
      alert(
        this.restrictSpacer +
          "を開けられます。\n既に" +
          this.restrictSpacer +
          'をご利用の方は、"あなたが開けられるSPACER"からご選択お願い致します。'
      );
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(TicketDialog);

    dialogRef.afterClosed().subscribe(result => {
      //      // console.log(`Dialog result: ${result}`);
    });
  }

  cleaningDialog() {
    // // console.log(this.cleaningURL);
    if (this.cleaningURL) {
      if (this.cleaningURL != "") {
        window.open(this.cleaningURL, "_blank");
      } else {
        return;
      }
    } else {
      const dialogRef = this.dialog.open(CleaningDialog, {
        width: "300px",
        // tslint:disable-next-line: max-line-length
        data: {
          url: this.urlId,
          expired: this.expired,
          spacerid: this.selectedSpacer,
          cleaningShopName: this.cleaningShopName,
          cleaningShopNumber: this.cleaningShopNumber
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        //     // console.log(`Dialog result: ${result}`);
      });
    }
  }
  apartDialog() {
    const dialogRef = this.dialog.open(ApartDialog, {
      width: "300px",
      // tslint:disable-next-line: max-line-length
      data: {
        apartValues: this.apartValues,
        spacerid: this.selectedSpacer,
        myuid: this.uid
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //     // console.log(`Dialog result: ${result}`);
    });
  }
  crowdWashDialog() {
    const dialogRef = this.dialog.open(CrowdWashDialog, {
      width: "300px",
      // tslint:disable-next-line: max-line-length
      data: {
        spacerid: this.selectedSpacer,
        matches: this.cw_matches,
        uid: this.uid
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //     // console.log(`Dialog result: ${result}`);
    });
  }
  laundryPackDialog() {
    const dialogRef = this.dialog.open(LaundryPackDialog, {
      width: "300px",
      // tslint:disable-next-line: max-line-length
      data: {
        spacerid: this.selectedSpacer,
        uid: this.uid,
        url: this.urlId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //     // console.log(`Dialog result: ${result}`);
    });
  }

  timeOver() {
    alert("有効期限を過ぎました 延長して下さい");
  }

  changeSpacer($event) {
    if ($event.target.value === "") {
      return;
    }
    if ($event.target.value === "default") {
      this.is_default = true;
      return;
    }
    if ($event.target.value === undefined) {
      window.location.reload();
      //      this.db.object<any>(this.fbUsers + this.uid + '/spacers' + [spacerId] ).remove();
    }
    this.is_default = false;
    /*		this.kcs.getKeyURLData( this.urlId )
        .then(data => {
    // console.log(data);
            this.data = data;
            this.selectedSpacer = data.device;
            let date = new Date();
            if( date.getTime() > Date.parse( data.expired ) ){
              alert("有効期限が過ぎています 延長して下さい");
            }
        });*/
    this.urlId = $event.target.value;
    this.urlKey = "https://spacer.live/key/" + this.urlId;
    const itemRef = this.db.object<any>("sendKeyURL/" + this.urlId);
    const item = itemRef.valueChanges();
    item.subscribe(data => {
      if (data) {
        this.data = data;
        this.selectedSpacer = data.device;
        this.expired = data.expired;
        const date = new Date();
        const spacersUnit = data.device.replace(/[^0-9^\.]/g, "").slice(0, -1);
        if (
          spacersUnit == "204" ||
          spacersUnit == "205" ||
          spacersUnit == "206" ||
          spacersUnit == "207" ||
          spacersUnit == "208"
        ) {
          this.is_dailyTicket = true;
          this.ticketText = "チケットを購入し開けられるようにする";
          this.ticketExplainText = "1日チケット(300円)";
        } else {
          this.is_dailyTicket = false;
          this.ticketText = "チケットを利用する";
          this.ticketExplainText = "1チケット = プラス6時間";
        }
        if (data.status !== "free") {
          if (date.getTime() > Date.parse(data.expired)) {
            this.is_expired = true;
          } else {
            this.is_expired = false;
          }
        } else {
          this.is_expired = false;
        }
        if (data.isCleaning === "true") {
          this.is_cleaning = true;
          this.cleaningShopName = data.cleaningShopName;
          this.cleaningShopNumber = data.cleaningShopNumber;
          this.cleaningURL = data.cleaningURL;
        } else {
          this.is_cleaning = false;
        }
        //アパート
        const ref = this.db.list("keyreceivers/" + this.selectedSpacer);
        ref
          .valueChanges()
          .pipe(take(1))
          .subscribe(report => {
            const length = report.length / 2;
            if (length === 0) {
              this.is_apart = false;
              return;
            } else {
              this.is_apart = true;
            }
            for (let i = 0; i < length; i++) {
              this.apartValues[String(report[i])] = report[i + length];
            }
          });
        if (data.laundry_pack === "true") {
          this.is_laundry_pack = true;
        } else {
          this.is_laundry_pack = false;
        }
        if (data.nextlaundry === "true") {
          const ref_cw = this.db.list(
            this.fbUsers + this.uid + "/idLink/crowdwash/data"
          );
          this.cw_matches = ref_cw.snapshotChanges().pipe(
            map(changes => {
              return changes.map(c => ({
                key: c.payload.key,
                val: c.payload.val()
              }));
            })
          );
          this.db
            .object(this.fbUsers + this.uid + "/idLink/crowdwash/member_id")
            .valueChanges()
            .pipe(take(1))
            .subscribe(member => {
              if (member) {
                this.is_crowdwash = true;
              } else {
                this.is_crowdwash = false;
              }
            });
          this.is_crowdwash = true;
        } else {
          this.is_crowdwash = false;
        }
      } else {
        for (const item of $event.srcElement) {
          if (item.selected) {
            let spacerid = item.outerText.replace(/\n/g, "");
            spacerid = spacerid.replace(/\t/g, "");
            spacerid = spacerid.replace(/ /g, "");
            this.db
              .object<any>(this.fbUsers + this.uid + "/spacers/" + spacerid)
              .remove();
          }
        }
        //      this.db.object<any>(this.fbUsers + this.uid + '/spacers' + [spacerId] ).remove();
      }
    });
  }

  async extendExpiredTime() {
    if (this.is_iOS) {
      return;
    }
    alert("１チケットを使用し、６時間延長します");
    const random = this._getRandom();
    const md5_message = md5(random + this.uid + this.urlId + "1");
    await this.db
      .object("checkString/" + random)
      .set({ md5: md5_message, timestamp: this._getSpacerTime() });
    const url =
      "http://localhost:8008/extendExpiredTime/" +
      random +
      "/" +
      this.uid +
      "/" +
      this.urlId +
      "/1/" +
      md5_message;
    const data = await this._httpjson.getJson(url);
    if (data["message"] === "notEnough") {
      alert("チケットを購入して下さい");
    }
  }

  async buyDailyTicket() {
    if (this.is_iOS) {
      return;
    }
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const newExpired =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      " 00:00:00";
    const todayTime = new Date(newExpired).getTime();
    const expiredDay = new Date(this.expired);
    const expiredDayReset =
      expiredDay.getFullYear() +
      "-" +
      ("0" + (expiredDay.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + expiredDay.getDate()).slice(-2) +
      " 00:00:00";
    const expiredDayResetTime = new Date(expiredDayReset).getTime();
    const payDays = (todayTime - expiredDayResetTime) / 1000 / 60 / 60 / 24;
    alert(payDays + "日分のチケットを購入します。\n料金：" + payDays * 300);
    const url =
      "http://localhost:8008/redirectToPaymentA/" +
      this.uid +
      "/" +
      payDays * 300 +
      "/web?productType=dailyTicket&spacerid=" +
      this.selectSpacer;
    window.open(url, "_blank");
  }

  async selectSpacer() {
    if (this.is_iOS) {
      return;
    }
    if (this.is_clicked) {
      return;
    }
    const updateMessage = "通信中...";
    try {
      var device = await this.bluetoothRef.bluetooth.requestDevice({
        filters: [
          { services: [this.serviceUuid], namePrefix: this.restrictSpacer }
        ]
      });
      this.is_clicked = true;
      this.selectText = updateMessage;

      var server = await device.gatt.connect();
      var service = await server.getPrimaryService(this.serviceUuid);
      var characteristic = await service.getCharacteristic(
        this.characteristicUuid
      );
      var spacerId = device.name;
    } catch (error) {
      device.gatt.disconnect();
      alert("Bluetoothの接続で問題が発生しました 再度お試しください");
      window.location.reload();
      return;
    }
    const parcentage = 100 / 5;
    let ctr = 1;

    let readdata = this._getReadData(await characteristic.readValue());
    this.selectText =
      updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";

    if (
      readdata == "rwsuccess" ||
      readdata == "wsuccess" ||
      readdata == "using"
    ) {
      alert("使用中のSPACERです");
      this.selectText = "使いたいSPACERを選択";
      this.is_clicked = false;
      device.gatt.disconnect();
      return;
    } else if (readdata == "false") {
      alert("読み込みに失敗しました 再度お試し下さい");
      device.gatt.disconnect();
      window.location.reload();
      return;
    }
    const random = this._getRandom();
    let md5_message = md5(random + this.uid + readdata + spacerId);
    await this.db
      .object("checkString/" + random)
      .set({ md5: md5_message, timestamp: this._getSpacerTime() });
    ctr++;
    this.selectText =
      updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
    try {
      var url =
        "http://localhost:8008/generateKey/" +
        random +
        "/" +
        this.uid +
        "/" +
        readdata +
        "/" +
        spacerId +
        "/" +
        md5_message;
      var data = await this._httpjson.getJson(url);
    } catch (error) {
      alert("サーバーからの処理に失敗しました");
      this.selectText = "使いたいSPACERを選択";
      this.is_clicked = false;
      device.gatt.disconnect();
      return;
    }
    ctr++;
    this.selectText =
      updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
    await characteristic.writeValue(
      new Uint8Array(
        this._stringToCharCode("543214723567xxxrw, " + data["key"])
      )
    );
    ctr++;
    this.selectText =
      updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
    readdata = this._getReadData(await characteristic.readValue());
    md5_message = md5(random + this.urlId + spacerId + readdata);
    try {
      url =
        "http://localhost:8008/resultGenerateKey/" +
        random +
        "/" +
        this.urlId +
        "/" +
        spacerId +
        "/" +
        readdata +
        "/" +
        md5_message;
      await this._httpjson.getJson(url);
    } catch (error) {
      alert("サーバーからの処理に失敗しました!");
      this.selectText = "使いたいSPACERを選択";
      this.is_clicked = false;
      device.gatt.disconnect();
      return;
    }
    device.gatt.disconnect();
    if (readdata != "rwsuccess" && readdata != "doorOpen") {
      alert("読み込みに失敗しました 再度お試し下さい!");
      window.location.reload();
      return;
    }
    if (readdata == "doorOpen") {
      alert("SPACERのドアを閉めて下さい。（自動で施錠されます。）");
      window.location.reload();
      return;
    }
    this.selectText = "使いたいSPACERを選択";
    this.is_clicked = false;
    if (data["closedWait"] === "true") {
      alert("解錠しました。\n※扉を開けて閉めると自動で施錠されます。");
    } else {
      alert("施錠完了\n↑のセレクトボタンから選べます");
    }
    window.location.reload();
  }

  async findSpacer() {
    if (this.is_iOS) {
      return;
    }
    if (this.is_clicked) {
      return;
    }
    const updateMessage = "通信中...";
    try {
      var device = await this.bluetoothRef.bluetooth.requestDevice({
        filters: [{ services: [this.serviceUuid], namePrefix: "SPACER" }]
      });
      this.is_clicked = true;
      this.findText = updateMessage;

      var server = await device.gatt.connect();
      var service = await server.getPrimaryService(this.serviceUuid);
      var characteristic = await service.getCharacteristic(
        this.characteristicUuid
      );
      var spacerId = device.name;
    } catch (error) {
      device.gatt.disconnect();
      alert("Bluetoothの接続で問題が発生しました 再度お試しください");
      window.location.reload();
      return;
    }
    const parcentage = 100 / 5;
    let ctr = 1;

    let readdata = this._getReadData(await characteristic.readValue());
    this.findText =
      updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";

    if (
      readdata == "rwsuccess" ||
      readdata == "wsuccess" ||
      readdata == "using"
    ) {
      alert("使用中のSPACERです");
      this.findText = "カギを閉めるSPACERを選択";
      this.is_clicked = false;
      device.gatt.disconnect();
      return;
    } else if (readdata == "false") {
      alert("読み込みに失敗しました 再度お試し下さい");
      device.gatt.disconnect();
      window.location.reload();
      return;
    }
    const random = this._getRandom();
    let md5_message = md5(random + this.uid + readdata + spacerId);
    await this.db
      .object("checkString/" + random)
      .set({ md5: md5_message, timestamp: this._getSpacerTime() });
    ctr++;
    this.findText =
      updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
    try {
      var url =
        "http://localhost:8008/generateKey/" +
        random +
        "/" +
        this.uid +
        "/" +
        readdata +
        "/" +
        spacerId +
        "/" +
        md5_message;
      var data = await this._httpjson.getJson(url);
    } catch (error) {
      alert("サーバーからの処理に失敗しました");
      this.findText = "カギを閉めるSPACERを選択";
      this.is_clicked = false;
      device.gatt.disconnect();
      return;
    }
    ctr++;
    this.findText =
      updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
    await characteristic.writeValue(
      new Uint8Array(
        this._stringToCharCode("543214723567xxxrw, " + data["key"])
      )
    );
    ctr++;
    this.findText =
      updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
    readdata = this._getReadData(await characteristic.readValue());
    md5_message = md5(random + this.urlId + spacerId + readdata);
    try {
      url =
        "http://localhost:8008/resultGenerateKey/" +
        random +
        "/" +
        this.urlId +
        "/" +
        spacerId +
        "/" +
        readdata +
        "/" +
        md5_message;
      await this._httpjson.getJson(url);
    } catch (error) {
      alert("サーバーからの処理に失敗しました!");
      this.findText = "カギを閉めるSPACERを選択";
      this.is_clicked = false;
      device.gatt.disconnect();
      return;
    }
    device.gatt.disconnect();
    if (readdata != "rwsuccess" && readdata != "doorOpen") {
      alert("読み込みに失敗しました 再度お試し下さい!");
      window.location.reload();
      return;
    }
    if (readdata == "doorOpen") {
      alert("SPACERのドアを閉めて下さい。（自動で施錠されます。）");
      window.location.reload();
      return;
    }
    this.findText = "カギを閉めるSPACERを選択";
    this.is_clicked = false;
    if (data["closedWait"] === "true") {
      alert("解錠しました。\n※扉を開けて閉めると自動で施錠されます。");
    } else {
      alert("施錠完了\n↑のセレクトボタンから選べます");
    }
    window.location.reload();
  }

  async openSpacer() {
    if (this.is_iOS) {
      return;
    }
    if (this.is_expired) {
      alert("チケットで延長して下さい");
      return;
    }
    if (this.is_clicked) {
      return;
    }
    const updateMessage = "通信中...";

    try {
      var device = await this.bluetoothRef.bluetooth.requestDevice({
        filters: [{ services: [this.serviceUuid], name: this.selectedSpacer }]
      });

      this.openText = updateMessage;
      this.is_clicked = true;

      var server = await device.gatt.connect();
      var service = await server.getPrimaryService(this.serviceUuid);
      var characteristic = await service.getCharacteristic(
        this.characteristicUuid
      );
      var spacerId = device.name;
    } catch (error) {
      device.gatt.disconnect();
      alert("Bluetoothの接続で問題が発生しました 再度お試しください");
      window.location.reload();
      return;
    }
    if (spacerId != this.selectedSpacer) {
      alert("正しいSpacerIDを選択して下さい");
      this.openText = "カギを開ける";
      this.is_clicked = false;
      device.gatt.disconnect();
      return;
    }
    const random = this._getRandom();
    let md5_message = md5(random + this.uid + this.urlId + spacerId);
    await this.db
      .object("checkString/" + random)
      .set({ md5: md5_message, timestamp: this._getSpacerTime() });

    const parcentage = 100 / 7;
    let ctr = 1;
    this.openText =
      updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
    try {
      let url =
        "http://localhost:8008/getKey/" +
        random +
        "/" +
        this.uid +
        "/" +
        this.urlId +
        "/" +
        spacerId +
        "/" +
        md5_message;
      const data = await this._httpjson.getJson(url);
      if (data["message"] === "expired") {
        this.is_expired = true;
        alert("有効期限が過ぎています チケットを購入して下さい");
        this.openText = "カギを開ける";
        this.is_clicked = false;
        device.gatt.disconnect();
        return;
      }
      ctr++;
      this.openText =
        updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      await characteristic.writeValue(
        new Uint8Array(
          this._stringToCharCode("543214723567xxxw, " + data["key"])
        )
      );
      ctr++;
      this.openText =
        updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      var readdata = this._getReadData(await characteristic.readValue());
      if (readdata == "using") {
        await characteristic.writeValue(
          new Uint8Array(
            this._stringToCharCode("543214723567xxxw," + data["key"])
          )
        );
        readdata = this._getReadData(await characteristic.readValue());
      }
      ctr++;
      this.openText =
        updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      md5_message = md5(random + this.uid + this.urlId + spacerId + readdata);
      url =
        "http://localhost:8008/resultGetKey/" +
        random +
        "/" +
        this.uid +
        "/" +
        this.urlId +
        "/" +
        spacerId +
        "/" +
        readdata +
        "/" +
        md5_message;
      ctr++;
      this.openText =
        updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      await this._httpjson.getJson(url);
      ctr++;
      this.openText =
        updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      device.gatt.disconnect();
      //ここも治す必要ある？
      if (readdata != "wsuccess" && readdata != "rwsuccess") {
        alert("解錠に失敗しました 再度お試し下さい");
        window.location.reload();
        return;
      }
      alert("ご利用ありがとうございました");
      this.openText = "カギを開ける";
      this.is_default = true;
      this.is_clicked = false;
      window.location.reload();
    } catch (error) {
      alert("サーバーからの処理に失敗しました");
      this.openText = "カギを開ける";
      device.gatt.disconnect();
      return;
    }
  }

  private _stringToCharCode(base: string) {
    const arraystring = [];
    for (let i = 0; i < base.length; i++) {
      arraystring.push(base.charCodeAt(i));
    }
    return arraystring;
  }

  private _getRandom() {
    let random = "";
    const c = "aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ0123456789";
    const cl = c.length;
    for (let i = 0; i < 18; i++) {
      random += c[Math.floor(Math.random() * cl)];
    }
    return random;
  }

  private _getReadData(value: any) {
    const ints = new Int8Array(value.byteLength);
    let readdata = "";
    for (let i = 0; i < ints.length; i++) {
      readdata += String.fromCharCode(value.getInt8(i));
    }
    return readdata;
  }

  private _getSpacerTime() {
    const date = new Date();
    return (
      date.getFullYear() +
      "-" +
      ("0" + Number(date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2)
    );
  }
}
