<!-- IOSアプリ -->
<div *ngIf="is_iOS">
  <div *ngIf="is_key; then keyContent">###</div>
  <ng-template #keyContent>
    <app-ios-view [customURL]="customKeyURL"></app-ios-view>
  </ng-template>
  <div *ngIf="is_restrict; then restrictContent">###</div>
  <ng-template #restrictContent>
    <div style="text-align:center;">
      <br />
      <p>-- iOSで{{ spacerId }}を開く --</p>
      <p>
        Step 1.
        <a href="https://itunes.apple.com/jp/app/id1200289673" target="blank"
        >iPhoneアプリをインストール</a
        >
      </p>
      <p>
        #この機能は最新版のみ利用可能です。必ずアップデートしてお使い下さい。
      </p>
      <p>Step 2. 下記”{{ spacerId }}を開ける”ボタンをタップ</p>
      <p>#既にアプリインストール済の場合、Step 2 のみで鍵を開けます</p>
      <button (click)="customUrl()">{{ spacerId }}を開ける</button>
    </div>
  </ng-template>
  <div *ngIf="is_confirm; then confirmContent">###</div>
  <ng-template #confirmContent>
    <div style="text-align:center;">
      <br />
      <p>-- iOSでSpacerのID連携を行う --</p>
      <p>
        Step 1.
        <a href="https://itunes.apple.com/jp/app/id1200289673" target="blank"
        >iPhoneアプリをインストール</a
        >
      </p>
      <p>
        #この機能は最新版のみ利用可能です。必ずアップデートしてお使い下さい。
      </p>
      <p>Step 2. 下記”SPACERにIDを連携する"をタップ</p>
      <p>#既にアプリインストール済の場合、Step 2 のみで鍵を開けます</p>
      <button (click)="customUrl()">SPACERにIDを連携する</button>
    </div>
  </ng-template>
  <div *ngIf="is_login">
    <app-phone-number-form></app-phone-number-form>
  </div>
</div>

<!-- Androidアプリ -->
<div *ngIf="isKotlin">
  <div *ngIf="is_key; then keyContent">###</div>
  <ng-template #keyContent>
    <app-kotlin-view [customURL]="customKeyURL"></app-kotlin-view>
  </ng-template>
  <div *ngIf="is_restrict; then restrictContent">###</div>
  <ng-template #restrictContent>
    <div style="text-align:center;">
      <br />
      <p>-- Androidで{{ spacerId }}を開く --</p>
      <p>
        Step 1.
        <a href="#" target="blank"
        >Androidアプリをインストール</a
        >
      </p>
      <p>
        #この機能は最新版のみ利用可能です。必ずアップデートしてお使い下さい。
      </p>
      <p>Step 2. 下記”{{ spacerId }}を開ける”ボタンをタップ</p>
      <p>#既にアプリインストール済の場合、Step 2 のみで鍵を開けます</p>
      <button (click)="customUrl()">{{ spacerId }}を開ける</button>
    </div>
  </ng-template>
  <div *ngIf="is_confirm; then confirmContent">###</div>
  <ng-template #confirmContent>
    <div style="text-align:center;">
      <br />
      <p>-- AndroidでSpacerのID連携を行う --</p>
      <p>
        Step 1.
        <a href="#" target="blank">Androidアプリをインストール</a>
      </p>
      <p>
        #この機能は最新版のみ利用可能です。必ずアップデートしてお使い下さい。
      </p>
      <p>Step 2. 下記”SPACERにIDを連携する"をタップ</p>
      <p>#既にアプリインストール済の場合、Step 2 のみで鍵を開けます</p>
      <button (click)="customUrl()">SPACERにIDを連携する</button>
    </div>
  </ng-template>
</div>

<!-- Webアプリ -->
<div *ngIf="!is_iOS && !isKotlin">
  <div *ngIf="is_key">
    <app-android-view (confirm)="confirmed($event)"></app-android-view>
  </div>
  <div *ngIf="is_login">
    <app-phone-number-form></app-phone-number-form>
  </div>
</div>
