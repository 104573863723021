import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import { MembersRepositoryService } from '../members-repository-service';
import { PhoneNumberAuthenticationService } from '../authentication/services/phone-number-authentication-service';
import {UserOperationLoggerService} from '../user-operation-logger.service';
import {MemberInfo} from "../entity/member-info";
import {MemberApiService} from "../member-api.service";
import {FirstPaymentFreeService} from "../first-payment-free.service";

@Component({
  selector: 'app-put',
  templateUrl: './put.component.html',
  styleUrls: ['./put.component.scss']
})
export class PutComponent implements OnInit, AfterViewInit {
  private viewName: string;
  constructor(
    private phoneNumberAuthService: PhoneNumberAuthenticationService,
    private el: ElementRef,
    private _member: MembersRepositoryService,
    private _firstPaymentFreeService: FirstPaymentFreeService,
    private userOpeLogger: UserOperationLoggerService
  ) { }
  async ngOnInit() {
    this.viewName = this.el.nativeElement.querySelectorAll('h2')[0].innerText;
  }

  async ngAfterViewInit() {
    if (await this._firstPaymentFreeService.shouldAlertPaymentFree()) {
      alert(this._firstPaymentFreeService.messagePaymentFree);
    }
  }

  userOpeLog(event: any) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
  }
}
