import { Term } from "../value-object/term";
import { PutLimitedUnit } from "./put-limited-unit";
import { PaymentFree } from "./payment-free";
import * as moment from "moment";
import { ProductSet } from "./product-set";

export class Partner {
  name: string;
  term: Term;
  productSet: ProductSet;
  putLimitedUnit: PutLimitedUnit;
  paymentFree: PaymentFree;
  constructor() {
    this.term = new Term();
    this.productSet = new ProductSet();
    this.putLimitedUnit = new PutLimitedUnit();
    this.paymentFree = new PaymentFree();
  }

  canSetProduct(boxId: string, targetDate: moment.Moment): boolean {
    // console.log('DEBUG Partner#canSetProduct')
    // console.log('DEBUG START boxId');
    // console.log(boxId);
    // console.log('DEBUG END boxId');
    // console.log('DEBUG START targetDate');
    // console.log(targetDate);
    // console.log('DEBUG END targetDate');

    if (this.term.isPermitted(boxId, targetDate)) {
      // console.log('DEBUG Partner#canSetProduct this.term.isPermitted(boxId, targetDate)')
      return this.productSet.isPermitted(boxId, targetDate);
    }
    return false;
  }

  // canPutLimitedUnit(boxId: string, targetDate: moment.Moment): boolean {
  //   if (this.term.isPermitted(boxId, targetDate)) {
  //     return this.putLimitedUnit.isPermitted(boxId, targetDate);
  //   }
  //   return false;
  // }

  isPaymentFree(boxId: string, targetDate: moment.Moment): boolean {
    // console.log('DEBUG partner 001');
    if (this.term.isPermitted(boxId, targetDate)) {
      // console.log('DEBUG partner 002');
      const result = this.paymentFree.isPermitted(boxId, targetDate);
      // console.log('DEBUG START result');
      // console.log(result);
      // console.log('DEBUG END result');

      return result;
    }
    // console.log('DEBUG partner 003');
    return false;
  }
}
