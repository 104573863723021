<div id="content">
  <h2>電話番号の登録</h2>
  <div class="regist-phone-num-conf-view">
    <form>
      <label for="confirmationCode"
        >{{
          phoneNumForView
        }}
        に送信された、6桁のコードを入力してください。</label
      >
      <div class="form-group">
        <input
          class="text-area"
          type="text"
          pattern="[0-9]*"
          (ngModelChange)="onConfirmationCodeChange($event)"
          id="confirmationCode"
          [(ngModel)]="parameters.confirmationCode"
          name="confirmationCode"
        />
      </div>
      <button
        (click)="sendConfirmationCode($event)"
        class="large-black"
        id="sendConfirmationCode"
      >
        ログイン
      </button>
      <div class="container-resend-code">
        <!--  重要: 下記の id resend-code-to-phone は、recaptcha の実装に必要です。 -->
        <button
          id="resend-code-to-phone"
          class="button-resend-code-to-phone"
          (click)="sendPhoneNumber($event)"
        >
          <img src="../../assets/images/ui-ver2.0/icon_arrow_right.svg" />
        </button>
        <label for="resend-code-to-phone">確認コードの再送</label>
      </div>
      <div id="recaptcha-container"></div>
    </form>
  </div>
</div>
<!-- <app-global-menu-component></app-global-menu-component> -->
