<div id="content">
  <h2>お知らせ</h2>
  <div *ngIf="is_loading_view; then loadingView; else contentView">
    ###
  </div>
  <ng-template #loadingView>
    <p>Loading...</p>
  </ng-template>
  <ng-template #contentView>
    <div *ngIf="dataList.length == 0">
      <div id="empty-content">
        <div class="inner">
          <p>現在、あなた宛の</p>
          <p>お知らせはありません</p>
        </div>
      </div>
    </div>
    <div id="notification">
      <div *ngIf="view_id; then eachContentView; else listView">###</div>
      <ng-template #eachContentView>
        <p class="title">{{ dataList[view_id]?.title }}</p>
        <p class="send-at">{{ dataList[view_id]?.sendAt }}</p>
        <p>{{ dataList[view_id]?.text }}</p>
        <div *ngIf="dataList[view_id]?.device">
          <p>{{ dataList[view_id]?.device }}({{ dataList[view_id]?.address }})</p>
          <p><a routerLink="../../mylockers" class="arrow" >マイロッカーへ</a></p>
          <p><a (click)="openAboutKeySharing()" class="question" >鍵の共有について</a></p>
        </div>
        <div *ngIf="dataList[view_id]?.linkURL">
          <p><a href="{{ dataList[view_id].linkURL }}" target="_blank" class="arrow" >{{ dataList[view_id]?.linkText }}</a></p>
        </div>
      </ng-template>
      <ng-template #listView>
        <ul *ngIf="dataList.length != 0" class="notification-list">
          <li *ngFor="let item of dataList; let i = index" ><img src="../../assets/images/ui-ver2.0/icon_locker.png" width="30px" /><a routerLink="{{ i }}"> {{ item.title }}</a></li>
        </ul>
      </ng-template>
    </div>
  </ng-template>
</div>
<app-global-menu-component></app-global-menu-component>