import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  safeSite: SafeResourceUrl;
  site = 'https://spacer.live/iframe/privacy/';
  h2 = '個人情報保護方針';

  constructor(private sanitizer: DomSanitizer) {
    this.safeSite = this.sanitizer.bypassSecurityTrustResourceUrl(this.site);
  }

  ngOnInit() {
  }

}
