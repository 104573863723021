import { Component, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-android-view',
  templateUrl: './android-view.component.html',
  styleUrls: ['./android-view.component.scss']
})
export class AndroidViewComponent implements OnInit {
  @Output() confirm: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onConfirm() {
    this.confirm.emit('true');
  }
  unsubscribe() {

  }
}
