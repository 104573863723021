// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
//  firebase: {
//    apiKey: "AIzaSyAMtD5paK8y-vf5_fW-cTpVaPx-o4btfe8",
//    authDomain: "spacer-test.firebaseapp.com",
//    databaseURL: "https://api.spacer-test.firebaseio.com",
//    projectId: "spacer-test",
//    storageBucket: "spacer-test.appspot.com",
//    messagingSenderId: "914343099320"
//  },
 
  firebase: {
    apiKey: "AIzaSyDTd3YvfPWaM3YHyJ2UXBV8syW2GNefxp0",
    authDomain: "leisurelocker.firebaseapp.com",
    databaseURL: "https://leisurelocker-default-rtdb.firebaseio.com",
    projectId: "leisurelocker",
    storageBucket: "leisurelocker.appspot.com",
    messagingSenderId: "247826096180",
  },
//  apiUrl: "http://spacer.live:4200/",
//  apiUrl: "http://localhost:8008/",
  apiUrl: "https://api-v2.spacer.live/",
// apiUrl: "https://api-vsv0ukl18tz6dm.spacer.live/",
  payment: {
    gmoTokenLibUrl: "https://stg.static.mul-pay.jp/ext/js/token.js",
    gmoTokenApiKey: "tshop00041944"
  },
  logger: {
    userOperation: "none" // 現状、"firebase" のみ設定可能
  },
  showDebugView: true // 画面上にデバッグ用項目を表示する場合は true
};
