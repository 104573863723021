import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  safeSite: SafeResourceUrl;
  site = 'https://spacer.live/iframe/faq/';
  h2 = 'よくあるご質問';

  constructor(private sanitizer: DomSanitizer) {
    this.safeSite = this.sanitizer.bypassSecurityTrustResourceUrl(this.site);
  }

  ngOnInit() {
  }

}
