import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-use-payment-completed',
  templateUrl: './use-payment-completed.component.html',
  styleUrls: ['./use-payment-completed.component.scss']
})
export class UsePaymentCompletedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
