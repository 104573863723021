import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { sanitizeIdentifier } from "@angular/compiler";

@Component({
  selector: "app-custom-iframe",
  templateUrl: "./custom-iframe.component.html"
  // styleUrls: ['']
})
export class CustomIframeComponent implements OnInit {
  // baseUrl の後ろに連結するURL。
  @Input() iframeSrcUrl = "";

  safeResourceUrl: SafeResourceUrl;
  baseUrl = "https://spacer.live";

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const completelyUrl = this.baseUrl + "/" + this.iframeSrcUrl;
    // baseUrl に記載されたドメインで始まるURLは、信頼できるとみなす。
    this.safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      completelyUrl
    );
  }
}
