<div
  class="global-menu-item"
  [routerLink]="this.targetUrl"
  routerLinkActive
  #rla="routerLinkActive"
  (click)="userOpeLog()"
>
  <img src="{{ rla.isActive ? this.imageCurrentUrl : this.imageDefaultUrl }}" />
  <p
  class="default"
  routerLinkActive="current"
 >
  {{ this.text }}</p>
</div>
