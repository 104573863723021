import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {UserOperationLoggerService} from '../user-operation-logger.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit, AfterViewInit {
  _action;
  _device;
  private viewName: string;
  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private userOpeLogger: UserOperationLoggerService,
              private el: ElementRef) {}

  async ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this._action = params['action'];
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this._device = params['device'];
    });
  }

  ngAfterViewInit() {
    this.viewName = this.el.nativeElement.querySelectorAll('h2')[0].innerText;
  }

  get action() {
    return this._action;
  }

  gotoMylocker(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    this.router.navigateByUrl('choice-share-type-last');
  }

  userOpeLog(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
  }

  clickSharingKey(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    this.router.navigateByUrl(`choice-share-type/${this._device}`)
  }
}
