export class ProductForm {
  public deviceNum: string
  public senderName: string
  public price: number
  public desc: string
  public errorForDialog = ''

  constructor(
  ) {}

  // 値が不正であれば false を返す。
  // this.errorForDialog を更新する。
  get validate(): boolean {
    if (this.price) {
      if (this.price >= 0) {
        return true
      } else {
        this.errorForDialog = '金額が不正です';
      }
    }
    return false
  }
}
