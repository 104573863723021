import { Injectable } from '@angular/core';
import { environment } from "../environments/environment";
import { HttpHeaders } from "@angular/common/http";
import * as firebase from "firebase";
import { HttpJsonService } from "./http-json.service";
import { SecureParamService } from "./secure-param.service";
import {ProductForm} from "./product-form";

@Injectable({
  providedIn: 'root'
})
export class CheckProductSetApiService {

  constructor(private httpJsonService: HttpJsonService,
              private secureParamService: SecureParamService) { }

  readonly url = `${environment.apiUrl}myLocker/checkProductSet`;
  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  /**
   * ロッカーに商品を設定可能かどうか判定するAPIを呼び出す
   */
  async checkProductSet(boxId: string) {
    const url = `${environment.apiUrl}myLocker/checkProductSet`;
    const uid = firebase.auth().currentUser.uid;
    const json = {
      spacerId: boxId,
      uid: uid,
      // random: random,
      // desc: product.desc,
    };
    return await this.httpJsonService.postJson(url, json);
  }
}
