import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../environments/environment";
import * as firebase from "firebase";
import {HttpJsonService} from "./http-json.service";
import {SecureParamService} from "./secure-param.service";

@Injectable({
  providedIn: 'root'
})
export class CheckPutLimitApiService {

  constructor(private httpJsonService: HttpJsonService,
              private secureParamService: SecureParamService) {}

  readonly url = `${environment.apiUrl}checkPutLimit`;
  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  /**
   * ロッカーが利用可能かどうか判定するAPIを呼び出す
   */
  async checkPutLimit(boxId: string) {
    const uid = firebase.auth().currentUser.uid;
    const postId = boxId.replace(/[^0-9^\.]/g, '');
    const random = this.secureParamService.getRandom(); // <- 改ざん検知パラメタ
    const md5_message = this.secureParamService.md5(random, uid, postId); // <- 改ざん検知パラメタ
    const json = {
      id: postId,
      uid: uid,
      random: random,
      md5_message: md5_message
    };
    return await this.httpJsonService.postJson(this.url, json);
  }

  get messageLimit(): string {
    return '該当ロッカーへの預け入れは制限されているため、ご利用できません。'
  }
}
