<mat-sidenav-container class="sidenav-container" hasBackdrop="true">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true">
    <div
      style="float: left;margin-left:20px; display:block; margin-top:30px; margin-bottom: 30px;height: 47px;"
    >
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="Side nav toggle icon" class="md-30"
          >clear</mat-icon
        >
      </button>
    </div>
    <div style="clear: both;"></div>
    <div id="user">
      <div id="userInner">
        <mat-icon aria-label="Side nav toggle icon" class="md-30"
          >person</mat-icon
        >
        <span style="display:block">{{ displayName }}</span>
      </div>
    </div>
    <div class="menuList">
      <div class="menuListInner">
        <a routerLink="/">ホーム</a>
      </div>
    </div>
    <div class="menuList">
      <div class="menuListInner">
        <span (click)="drawer.toggle(); openDialog()">チケット購入</span>
      </div>
    </div>
    <div class="menuList">
      <div class="menuListInner">
        <a routerLink="maps">地図</a>
      </div>
    </div>
    <div class="menuList">
      <div class="menuListInner">
        <a routerLink="id-link">連携済みID一覧</a>
      </div>
    </div>
    <div class="menuList">
      <div class="menuListInner">
        <a routerLink="tutorial">チュートリアル</a>
      </div>
    </div>
    <div class="menuList">
      <div class="menuListInner">
        <a routerLink="auth-providers">ログインアカウント連携</a>
      </div>
    </div>
    <div class="menuList">
      <div class="menuListInner">
        <a routerLink="phone-number-change">電話番号の変更(試作)</a>
      </div>
    </div>
    <div class="menuList">
      <div class="menuListInner">
        <a routerLink="unsubscribe">退会処理(試作)</a>
      </div>
    </div>
    <div class="menuList">
      <div class="menuListInner">
        <a
          href="https://spacer.live/kiyaku.html"
          target="_blank"
          (click)="drawer.toggle()"
          >利用規約・プライバシーポリシー</a
        >
      </div>
    </div>
    <button id="logoutBtn" (click)="logout()">ログアウト</button>
  </mat-sidenav>
  <mat-sidenav-content class="example-container">
    <div style="float: left;margin-left:20px; display:flex">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="Side nav toggle icon" class="md-30"
          >menu</mat-icon
        >
      </button>
    </div>
    <!--    <img src="assets/images/logo.svg" width="180px" style="margin-right:54px;" >-->
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
