import { Injectable } from '@angular/core';
import {PartnerMember} from "../entity/partner-member";
import {Observable, Subscriber} from "rxjs";
import {map} from "rxjs/operators";
import {ParseDepth, RoleParser} from "./role-parser";
import {AngularFireDatabase} from "@angular/fire/database";
import * as firebase from "firebase";
import {PaymentFree} from "../entity/payment-free";
import {PutLimitedUnit} from "../entity/put-limited-unit";
import {ObserveOnOperator} from "rxjs/internal/operators/observeOn";
import {Partner} from "../entity/partner";

@Injectable({
  providedIn: 'root'
})
export class PartnersRepositoryImplService {
  readonly partnerId = ''
  private _currentPartner: Partner;
  currentPartnerObservable: Observable<Partner>;
  private roleParser: RoleParser;

  constructor(
    private db: AngularFireDatabase,
  ) {
    this.roleParser = new RoleParser();
  }

  async refreshObserver(currentPartnerMember: PartnerMember) {
    // console.log('DEBUG PartnersRepositoryImplService#refreshObserver');
    this._currentPartner = null;
    // console.log('DEBUG START currentPartnerMember');
    // console.log(currentPartnerMember);
    // console.log('DEBUG END currentPartnerMember');

    try {
      this.currentPartnerObservable = this.db.object(`partners/${currentPartnerMember.partnerId}`)
        .snapshotChanges().pipe(
          map(c => { return c.payload.val() }),
          // map(val => { return val[currentPartnerMember.partnerId] }),
          map( item => { return this.parseItemPartner(item) }),
        )
      this.currentPartnerObservable.subscribe(obj => {
        this._currentPartner = obj;
        // console.log('DEBUG START this._currentPartner');
        // console.log(this._currentPartner);
        // console.log('DEBUG END this._currentPartner');
      });
    } catch(e) {
      // permission error など
      console.log(e);
    }

  }

  get currentPartner(): Partner {
    return this._currentPartner;
  }

  private parseItemPartner(item) {
    const partner = new Partner();
    if(item) {
      partner.name = item['name'];
      this.roleParser.parseTerm(item, partner.term);
      if(item['roles']) {
        const itemRole = item['roles'];
        if(itemRole['productSet']) {
          this.parseProductSet(itemRole['productSet'], partner.productSet)
        }
        if(itemRole['putLimitedUnit']) {
          this.parsePutLimitedUnit(itemRole['putLimitedUnit'], partner.putLimitedUnit)
        }
        if(itemRole['paymentFree']) {
          this.parsePaymentFree(itemRole['paymentFree'], partner.paymentFree)
        }
      }
    }
    return partner;
  }

  private parseProductSet(srcItem, dstObj: PaymentFree) {
    this.roleParser.parseTerm(srcItem, dstObj.term);
    this.roleParser.parseUnits(srcItem['spacerUnits'], dstObj, ParseDepth.Box);
    // console.log('DEBUG START srcItem');
    // console.log(srcItem);
    // console.log('DEBUG END srcItem');
    // console.log('DEBUG START dstObj');
    // console.log(dstObj);
    // console.log('DEBUG END dstObj');

  }

  private parsePaymentFree(srcItem, dstObj: PaymentFree) {
    this.roleParser.parseTerm(srcItem, dstObj.term);
    this.roleParser.parseUnits(srcItem['spacerUnits'], dstObj, ParseDepth.Unit);
  }

  private parsePutLimitedUnit(srcItem, dstObj: PutLimitedUnit) {
    this.roleParser.parseTerm(srcItem, dstObj.term);
    this.roleParser.parseUnits(srcItem['spacerUnits'], dstObj, ParseDepth.Box);
  }
}
