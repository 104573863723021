<!-- 自動キャンセル済のロッカー。 -->
<div class="auto-cancel-header">
  自動キャンセルしました
</div>
<div class="container-header">
  <div class="container-box-id">
    <div class="box-id">{{ box.boxId }}</div>
    <div class="box-address">ここに本文テキストがはいりまあああああああああああああああああああああああああっす</div>
  </div>
</div>

<div class="alert-message">このロッカーはご予約から48時間以上経過後もご利用が確認できなかったため、自動的にキャンセルとなりました。</div>

<hr>

<div class="container-propname-and-value">
  <div class="propname">利用開始から</div>
  <div class="value"><div id="timer-text">3時間59分48秒</div></div>
</div>

<hr>

<div class="container-propname-and-value">
  <div class="propname">利用料金</div>
  <div class="value">¥1,234</div>
</div>

<div class="container-propname-and-value">
  <div class="propname"><span class="large">合計料金</span></div>
  <div class="value"><span class="large">¥1,234</span></div>
</div>

<div class="container-buttons">
  <button class="large-white">キャンセル料のお支払へ</button>
</div>
