import * as firebase from 'firebase';
import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';

// この Component でのみ利用する class
export class HistoryEntity {
  constructor(private value) {}

  get device(): string {
    return this.value.device;
  }
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  dataList;
  entityList;
  private historyTypeMap = {
    'reserve-cancel': '予約キャンセル',
    'reserve' : 'ご予約',
    'use' : 'ご利用',
    'prepaid' : '事前決済',
    'reserve-cancel-by-others': '他者決済',
    'reserve-by-others' : '他者決済',
    'use-by-others' : '他者決済',
    'prepaid-by-others' : '他者決済',
  };

  constructor(private db: AngularFireDatabase) {
  }

  async ngOnInit() {
    const baseList = await this.getHistoriesFromDB();
    const newDataList = baseList.map(item => {
      const historyTypeSymbol = this.getHistoryTypeSymbol(item.value)
      const isPrepaid =  historyTypeSymbol === 'prepaid' && item.value.prepaid;
      const newData = {
        historyTypeSymbol: historyTypeSymbol,
        historyTypeStr: this.getHistoryTypeString(historyTypeSymbol),
        isReserveCancel: historyTypeSymbol === 'reserve-cancel',
        isReserve: historyTypeSymbol === 'reserve',
        isUse: historyTypeSymbol === 'use',
        isPrepaid: isPrepaid,
        startAt: isPrepaid ? item.value.prepaid.prepaidStartAt : item.value.startAt,
        endAt: isPrepaid ? item.value.prepaid.prepaidEndAt : item.value.paidAt,
        isCreatedByCurrentUser: this.isCreatedByCurrentUser(item.value),
      }
      return Object.assign(newData, item);
    })
    this.entityList = newDataList.sort((a,b) => a.key > b.key ? -1: 1);
  }

  private async getHistoriesFromDB() {
    const currentUser = firebase.auth().currentUser;
    const dbpath = `members/${currentUser.uid}/history`;
    const ref = this.db.list(dbpath);
    let list;
    await new Promise<void>((resolve) => {
      ref.snapshotChanges().pipe(
        map(changes => {
          return changes.map(c => ({
            key: c.payload.key,
            value: c.payload.val()
          }))
        })
      ).subscribe(res => {
        list = res;
        resolve();
      })
    })
    return list;
  }

  private isCreatedByCurrentUser(val: any): boolean {
    return !val.createdBy || val.createdBy === firebase.auth().currentUser.uid;
  }

  private getHistoryTypeSymbol(val: unknown): string {
   let symbol: string;

    if (val['expired']) {
      symbol = 'reserve-cancel';
    }
    if(val['isPrepaid'] === 'true'){
      symbol = 'prepaid';
    } else if(val['isReserved'] === 'true'){
      symbol = 'reserve';
    } else {
      symbol = 'use';
    }

    if(!this.isCreatedByCurrentUser(val)){
      symbol = `${symbol}-by-others`;
    }

    return symbol;
  }

  private getHistoryTypeString(symbol: string): string {
    return this.historyTypeMap[symbol];
  }
}
