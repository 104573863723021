import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import { Observable, Subscription } from "rxjs/Rx";

@Component({
  selector: "app-count-up-timer",
  templateUrl: "./count-up-timer-component.component.html",
  styleUrls: ["./count-up-timer-component.component.scss"]
})
export class CountUpTimerComponent implements OnInit, OnDestroy {
  @Output()
  tickEmmit = new EventEmitter<number>();

  @Output()
  onFetchPrice = new EventEmitter();

  @Input()
  get inputDate() {
    return this.pastTimeString;
  }

  set inputDate(val) {
    this.pastTimeString = val;
    this.pastTime
      = new Date(this.pastTimeString);
    // this.tickEmmit.emit(this._hours); // <- 不要であるかどうか確認が必要。確認後、削除もしくはコメントアウト解除
  }

  private pastTime: Date;
  private pastTimeString: string;
  private diff: number;
  private $counter: Observable<number>;
  private subscription: Subscription;
  public message: string;
  private _hours: number;
  private _seconds: number;
  private _minutes: number;

  get canShow(): boolean {
    // 下記判定に利用する値は、 this._hours や this._seconds でもよい
    return !(this._minutes === undefined);
  }

  get strHours(): string {
    return this._hours + '';
  }

  get strMinutes(): string {
    return this.lpad(this._minutes);
  }

  get strSeconds(): string {
    return this.lpad(this._seconds);
  }

  constructor(private elm: ElementRef) {}

  dhms(t) {
    this._seconds = Math.abs(t % 60);
    this._minutes = Math.abs(Math.ceil(t / 60) % 60);
    this._hours = Math.abs(Math.ceil(t / 3600));
    this.tickEmmit.emit(this._hours);
    if ( this._seconds === 0 && this._minutes === 0 ) {
      this.onFetchPrice.emit();
    }
    return [
      this._hours + ":",
      this.lpad(this._minutes) + ":",
      this.lpad(this._seconds)
    ].join("");
  }

  lpad(num): string {
    let s = num + "";
    if (s.length < 2) s = "0" + s;
    return s;
  }

  ngOnInit() {
    this.pastTime = new Date(this.pastTimeString);
    // this.$counter = Observable.interval(1000).map(x => {
    this.$counter = Observable.interval(1000).map(x => {
      this.diff = Math.floor(
        (this.pastTime.getTime() - new Date().getTime()) / 1000
      );
      return x;
    });

    this.subscription = this.$counter.subscribe(
      x => (this.message = this.dhms(this.diff))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
