import { Component } from "@angular/core";
import { GlobalMenuItem } from "./global-menu-item";

@Component({
  selector: "app-global-menu-component",
  templateUrl: "./global-menu.component.html",
  styleUrls: ["./global-menu.component.scss"]
})
export class GlobalMenuComponent {
  items: GlobalMenuItem[];

  constructor() {
    // this.items = [
    //   new GlobalMenuItem(
    //     "開ける",
    //     "../../assets/images/ui-ver2.0/icon_check.svg",
    //     function() {}
    //   ),
    //   new GlobalMenuItem(
    //     "探す",
    //     "../../assets/images/share.svg",
    //     function() {}
    //   ),
    //   new GlobalMenuItem(
    //     "マイロッカー",
    //     "../../assets/images/share.svg",
    //     function() {}
    //   ),
    //   new GlobalMenuItem(
    //     "お知らせ",
    //     "../../assets/images/share.svg",
    //     function() {}
    //   ),
    //   new GlobalMenuItem(
    //     "メニュー",
    //     "../../assets/images/share.svg",
    //     function() {}
    //   )
    // ];
  }
}
