<!-- 予約利用のロッカー。 -->
<div *ngIf="isAutoCanceled" class="auto-cancel-header">
  自動キャンセルしました
</div>
<div class="container-header">
  <div class="container-use-type-icon">
    <div class="use-type-icon">
      <img src="../../../assets/images/ui-ver2.0/icon_reserved.svg" />
    </div>
    <div class="use-type-text">予約中</div>
  </div>
  <div class="container-box-id">
    <div class="box-id">{{ boxId }}</div>
    <div class="box-address">ここに本文テキストがはいりまあああああああああああああああああああああああああっす</div>
  </div>
</div>

<hr>

<div class="container-propname-and-value">
  <div class="propname">利用開始から</div>
  <div class="value"><app-tick-timer [diff]="timeDiffForTick"></app-tick-timer>  </div>
</div>
<hr>

<div class="container-propname-and-value">
  <div class="propname">利用料金</div>
  <div class="value">¥{{ priceInfo.usePrice }}</div>
</div>

<div class="container-propname-and-value">
  <div class="propname">予約料金</div>
  <div class="value">¥{{ priceInfo.reservePrice }}</div>
</div>

<div class="container-propname-and-value">
  <div class="propname"><span class="large">合計料金</span></div>
  <div class="value"><span class="large">¥{{ priceInfo.totalPrice }}</span></div>
</div>

<div *ngIf="!isAutoCanceled" class="container-buttons">
  <button class="large-black">ロッカーを開ける</button>
  <button class="large-white">予約をキャンセルする</button>
</div>

<div *ngIf="isAutoCanceled" class="container-buttons">
  <button class="large-white">キャンセル料のお支払へ</button>
</div>
