import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reserve-payment-completed',
  templateUrl: './reserve-payment-completed.component.html',
  styleUrls: ['./reserve-payment-completed.component.scss']
})
export class ReservePaymentCompletedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
