<h2>利用時間をお選びください</h2>
<div class="time-box">
  <button class="minus" [disabled]="prevBtnDisabled" (click)="onPrevClick()">
    −
  </button>
  <div class="time">{{ prepaidAddHours }}</div>
  <button class="plus" (click)="onNextClick()">＋</button>
</div>
<div class="list-box">
  <div class="list-row">
    <div class="start-time-label">利用開始時間</div>
    <div class="start-time">{{ prepaidStartAt }}</div>
  </div>
  <hr />
  <div class="list-row">
    <div class="end-time-label">利用終了時間</div>
    <div class="end-time">{{ prepaidEndAt }}</div>
  </div>
  <hr />
  <div class="list-row">
    <div class="price-label">事前決済金額</div>
    <div class="price">￥ {{ prepaidAddCost }}</div>
  </div>
  <hr />
</div>
<div class="cation-box">
  <ng-container *ngIf="priceText != null">
    <p>・料金：{{ priceText }}</p>
  </ng-container>
  <p>・ご利用時間の変更やキャンセルは致し兼ねます。ご注意ください。</p>
  <p>
    ・鍵を共有した場合は、共有された方がロッカーを解錠すると利用終了となります。
  </p>
</div>
<div class="btn-box">
  <button class="half" (click)="ok()">お支払へ</button>
</div>
