import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { SpacerWebappAuthenticationService } from './spacer-webapp-authentication-service';
import { SpacerErrorHandleService } from '../../lib/spacer-error-handle/spacer-error-handle.service';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberChangeService {
  // 電話番号を変更するためのServiceです。
  // すでに電話番号による認証が済んでいるユーザー向けの機能となります。
  // 電話番号変更の大まかな流れは以下の通りです。
  //
  // - 予めサインイン中のユーザーの credential を取得しておく。これを oldCredential とする。
  // - 新しい電話番号を Firebase へ送信し、confirmationResult を生成する。
  // - confirmationResult から verificationId を取得し、
  //   verificationId と確認コードをもとに、 credential を作成する。これを newCredential とする。
  // - unlink メソッドを使って、一旦電話番号認証を外す。
  // - サインイン中のユーザーに、newCredential を関連付ける。
  newPhone = '';

  constructor(
    private spacerWebappAuthenticationService: SpacerWebappAuthenticationService,
    private errorHandler: SpacerErrorHandleService
  ) { }

  // 予めサインイン中のユーザーの credential を取得しておく。これを oldCredential とする。
  // 新しい電話番号を Firebase へ送信し、confirmationResult を生成する。
  // confirmationResult を spacerWebappAuthenticationService へ登録する。
  async sendPhoneNumber(phoneNumber, recaptchaVerifier): Promise<void> {
    this.newPhone = phoneNumber;
    const authService = this.spacerWebappAuthenticationService;
    authService.credentialsForPhoneNumberChange['old'] = authService.credential;
    authService.confirmationResult = await firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier);
  }

  // - confirmationResult から verificationId を取得し、
  //   verificationId と確認コードをもとに、 credential を作成する。これを newCredential とする。
  // - unlink メソッドを使って、一旦電話番号認証を外す。
  // - サインイン中のユーザーに、newCredential を関連付ける。
  async sendConfirmationCode(confirmationCode: string) {
    const authService = this.spacerWebappAuthenticationService;
    try {
      const verificationId = authService.confirmationResult.verificationId;
      authService.credentialsForPhoneNumberChange[
        'new'
      ] = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        confirmationCode
      );
      await firebase.auth().currentUser.unlink('phone');
      await firebase
        .auth()
        .currentUser.linkWithCredential(
          authService.credentialsForPhoneNumberChange['new']
        );
    } catch (error) {
      this.errorHandler.handle(error, alert);
    }
  }

  public credential() {
    return this.spacerWebappAuthenticationService.credential;
  }

  getNewPhoneNum() {
    return this.newPhone;
  }
}
