import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "payment-alert-dialog",
  templateUrl: "./payment-alert-dialog.html",
  styleUrls: ["./payment-alert-dialog.scss"]
})
export class PaymentAlertDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentAlertDialog>
  ) {}

  public close() {
    this.dialogRef.close();
  }
}
