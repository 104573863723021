import { IHasUnits } from "../interface/i-role";
import { Unit } from "../entity/unit";
import { Box } from "../entity/box";
import { Term } from "../value-object/term";
import * as moment from "moment";

export enum ParseDepth {
  Unit,
  Box,
}

export class RoleParser {

  parseTerm(srcItem, dstObj: Term) {
    if(srcItem['fromDate']) {
      dstObj.from = moment(srcItem['fromDate'])
    }
    if(srcItem['toDate']) {
      dstObj.to = moment(srcItem['toDate'])
    }
  }

  parseBox(srcItem, dstObj: Unit) {
    const newBoxes = [];
    Object.keys(srcItem).forEach(boxNo => {
      const box = new Box();
      box.id = boxNo;
      this.parseTerm(srcItem[boxNo], box.term);
      newBoxes.push(box);
    })
    dstObj.boxes = newBoxes;
  }

  parseUnits(srcItem, dstObj: IHasUnits, depth: ParseDepth) {
    if(!srcItem){
      return;
    }
    const newUnits = [];
    Object.keys(srcItem).forEach(unitNo => {
      const unit = new Unit();
      unit.id = unitNo;
      this.parseTerm(srcItem[unitNo], unit.term);
      if (depth == ParseDepth.Box) {
        if(srcItem[unitNo]['details']) {
          this.parseBox(srcItem[unitNo]['details'], unit)
        }
      }
      newUnits.push(unit);
    })
    dstObj.units = newUnits;
  }
}
