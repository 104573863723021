import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-view',
  templateUrl: './ios-view.component.html',
  styleUrls: ['./ios-view.component.scss']
})
export class IosViewComponent implements OnInit {
  @Input() customURL;

  constructor() { }

  ngOnInit() {
  }

  onConfirm() {
    window.location.href = this.customURL;
  }

  unsubscribe() {

  }
}
