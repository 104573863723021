<h2>お問合せについて</h2>
<div id="content">
  <div class="iframeBody">
      決済方法など問題が発生しましたら<br>
　　　お問合せ<br>
      <a href=tel:090-1269-0643>090-1269-0643</a><br>
　　　までおかけください<br>
      <iframe src="https://spacer.live/iframe/faq/10/"></iframe>
  </div>
</div>
