<h3>デバッグ専用画面</h3>
ログインユーザーのUUID<br>
{{uuid}}
<hr>
<h3>Firebase</h3>
ログインユーザー<br>
<ul>
<li><a target="_blank" href="{{firebasePathForMembers}}">members</a></li>
<li><a target="_blank" href="{{firebasePathForMembersMylockers}}">myLockers</a></li>
</ul>
