import { Injectable } from "@angular/core";

enum ReserveServiceStatus {
  BeforeRequest,
  SendRequest,
  ReceivedResponse,
  Reserved,
  NotReserved,
  ReceivedError
}

@Injectable({
  providedIn: "root"
})
export class ReserveService {
  private endpoint: string;
  private status: ReserveServiceStatus;
  constructor() {
    this.endpoint = "http://dummy.com";
    this.status = ReserveServiceStatus.BeforeRequest;
  }

  sendRequest() {
    const url = this.endpoint;
    const params = this.requestBody();
    this.status = ReserveServiceStatus.SendRequest;
    this.callbackHttp("dummy");

    // TODO : 以下のように、HTTP リクエストを送信する
    // this.httpClient.post(url, params).subscribe(this.callbackHttp, this.callbackHttpError);
  }

  callbackHttp(response) {
    this.status = ReserveServiceStatus.ReceivedResponse;
    const result = "dummy"; // 実際には、result には、HTTP Response をもとに、予約の成否を示すフラグを格納する。
    if (result === "dummy") {
      // 予約できた場合
      this.status = ReserveServiceStatus.Reserved;
    } else {
      // 予約できなかった場合（他のユーザに予約されてしまった場合や、画面のリロードで二重に予約を行った場合）
      this.status = ReserveServiceStatus.NotReserved;
    }
  }

  callbackHttpError(error) {}

  private requestBody() {
    return "dummyRequestBody";
  }

  get isBeforeRequest() {
    return this.status === ReserveServiceStatus.BeforeRequest;
  }

  get isSendRequest() {
    return this.status === ReserveServiceStatus.SendRequest;
  }

  get isReceivedResponse() {
    return this.status === ReserveServiceStatus.ReceivedResponse;
  }

  get isReserved() {
    return this.status === ReserveServiceStatus.Reserved;
  }

  get isNotReserved() {
    return this.status === ReserveServiceStatus.NotReserved;
  }

  get isReceivedError() {
    return this.status === ReserveServiceStatus.ReceivedError;
  }
}
