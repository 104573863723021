import { Term } from '../value-object/term'

export class Box {
  /**
   * SPACER の BoxID
   */
  id: string;

  term: Term;
  constructor() {
    this.term = new Term();
  }

}
