import {Component, Input, OnInit} from '@angular/core';
import {IBoxForMyBoxes, PriceInfo} from "../myboxes/myboxes.component";
import * as moment from 'moment';
import {Duration} from "moment";

@Component({
  selector: 'app-myboxes-box-reserved',
  templateUrl: './myboxes-box-reserved.component.html',
  styleUrls: [
    '../myboxes-box/myboxes-box.component.scss',
    './myboxes-box-reserved.component.scss',
  ]
})
export class MyboxesBoxReservedComponent implements OnInit {
  @Input() box: IBoxForMyBoxes;
  constructor() { }

  ngOnInit(): void {
  }

  get boxId() {
    return this.box.boxId;
  }

  get priceInfo() {
    return this.box.priceInfo;
  }

  get isAutoCanceled(): boolean {
    return this.box.isAutoCanceled;
  }

  get timeDiffForTick(): Duration {
    return this.box.timeDiffForTick;
  }

}
