import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancel-payment-completed',
  templateUrl: './cancel-payment-completed.component.html',
  styleUrls: ['./cancel-payment-completed.component.scss']
})
export class CancelPaymentCompletedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
