import { Component, OnInit } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { take } from "rxjs/operators";
import { Observable } from "rxjs/Observable";

@Component({
  selector: "app-idlink",
  templateUrl: "./idlink.component.html",
  styleUrls: ["./idlink.component.scss"]
})
export class IDLinkComponent implements OnInit {
  uid: string;
  idlist: Observable<any[]>;

  constructor(private auth: AngularFireAuth, private db: AngularFireDatabase) {
    this.auth.onAuthStateChanged(user => {
      if (!user) {
        return;
      }
      this.uid = user.uid;
    });
  }

  ngOnInit() {
    this.idlist = this.db
      .list("members/" + this.uid + "/idLink")
      .valueChanges();
    //     ref.valueChanges().pipe(take(1)).subscribe(report => {
    //       this.idlist = report;
    //       const length = report.length / 2;
    //       // console.log(report);
    //       if (length === 0) {
    //         return;
    //       } else {
    //       }
    //       for (let i = 0; i < length; i++) {
    // //        this.apartValues[String(report[i])] = report[i + length];
    //       }
    //     });
  }
}
