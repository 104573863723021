import { Component, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { SpacerErrorHandleService } from '../lib/spacer-error-handle/spacer-error-handle.service';
import { PhoneNumberAuthParameter } from '../authentication/telephone-number-auth/phone-number-auth-parameter';
import { PhoneNumberAuthenticationService } from '../authentication/services/phone-number-authentication-service';
import { SpacerWebappAuthenticationService } from '../authentication/services/spacer-webapp-authentication-service';
import * as firebase from 'firebase';
import { MembersRepositoryService } from '../members-repository-service';
import { UserOperationLoggerService } from '../user-operation-logger.service';
import { Overlay } from '@angular/cdk/overlay';
import { MatSpinner } from '@angular/material/progress-spinner';
import { ComponentPortal } from '@angular/cdk/portal';
import { Router , ActivatedRoute } from '@angular/router';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-confirmation-code-form',
  templateUrl: './regist-phone-num-conf.component.html',
  styleUrls: ['./regist-phone-num-conf.component.scss'],
})
export class RegistPhoneNumConfComponent implements OnInit, AfterViewInit {
  unitnumhairetu: string[] = new Array(100);
  unitnumtemp: string;
 
 

  unitnum:string;
  tempnum:number;
  returnUrl: string;
  public parameters = new PhoneNumberAuthParameter();
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  phoneNumForView = null;
  // ローディング中であることを表すオブジェクト
  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically(),
  });
  private viewName: string;
  constructor(
    private commonService: CommonService,
    private setcommonService: CommonService,
    private angularFireAuth: AngularFireAuth,
    private spacerWebappAuthenticationService: SpacerWebappAuthenticationService,
    private phoneNumberAuthService: PhoneNumberAuthenticationService,
    private route: Router,
    private router: ActivatedRoute,
    private overlay: Overlay,
    private _members: MembersRepositoryService,
    private userOpeLogger: UserOperationLoggerService,
    private el: ElementRef,
    private errorHandleService: SpacerErrorHandleService
  ) {

    this.returnUrl = this.router.snapshot.queryParams['returnUrl'];
}
 // private i18nClientService: I18nClientService,
  // 現時点では i18nClientService は利用していないが、
  // 将来的には、国を選択するドロップダウンリストの中身を、i18nClientService を用いて取得するようにしたい
 async ngOnInit() {
    this.phoneNumForView = this.phoneNumberAuthService.getPhoneNum();
    this.unitnumtemp = this.commonService.getMessege();
    this.unitnumhairetu = this.unitnumtemp.split('!');
    this.unitnum = this.unitnumhairetu[0];
    this.setcommonService.setMessege(this.unitnum);
  }

  sendConfirmationCode(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    let credential;
    let successConfirm = false;
    const errorHandleService = this.errorHandleService;
    try {
      credential = this.phoneNumberAuthService.sendConfirmationCode(
        this.parameters.confirmationCode
      );
      this.phoneNumberAuthService.setCredential(credential);
      successConfirm = true;
      this.angularFireAuth
        .signInWithCredential(credential)
        .then(() => {
    this.setcommonService.setMessege(this.unitnum);
          this.route.navigateByUrl('/put?' + this.unitnumtemp);
        })
        .then(() => {
          this._members.updatePhoneNum(
            this.phoneNumberAuthService.getPhoneNum()
          );
          // console.log('then in signInWithCredential after update phone num...');
        })
        .then(() => {
          this.overlayRef.detach();
        })
        .catch((error) => {
          this.overlayRef.detach();
          // console.log('98tty.'); // TODO : DELETE
          errorHandleService.handle(error, alert);
        });
    } catch (error) {
      this.overlayRef.detach();
      // console.log('4j7656r'); // TODO : DELETE
      errorHandleService.handle(error, alert);
    }
  }
  onConfirmationCodeChange(value: string) {
    const numberValue = parseInt(value, 10);
    this.tempnum = isNaN(numberValue) ? null : numberValue;
    this.parameters.confirmationCode = String(this.tempnum);
  }
  async sendPhoneNumber(event) {
    this.userOpeLogger.logViewAndButtonName(this.viewName, '確認コードの再送');
    // if ( !this.parameters.isAgreeTermOfUseAndPrivacyPolicy ) {
    //   return;
    // }
    try {
      this.spacerWebappAuthenticationService.confirmationResult = await this.phoneNumberAuthService.sendPhoneNumber(
        this.recaptchaVerifier
      );
      // this.route.navigateByUrl('login/confirmation-code');
    } catch (error) {
      // console.log(error);
      this.errorHandleService.handle(error, alert);
    }
  }

  ngAfterViewInit() {
    if (this.phoneNumberAuthService._completelyPhoneNumber) {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'resend-code-to-phone',
        {
          size: 'invisible',
          callback: function () {},
        }
      );
    } else {
      this.route.navigateByUrl('/put');
    }
  }
}
