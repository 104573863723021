<meta name="viewport" content="width=device-width, initial-scale=1">
<div id="content">
  <h2>決済してください</h2>
  <div *ngIf="dataList?.length > 0" class="list">
    <app-banner ></app-banner>
    <div class="card">
      <ng-container *ngFor="let item of dataList">
        <app-mylocker-item [item]="item"></app-mylocker-item>
      </ng-container>
    </div>
    <div class="detect">
    </div>
  </div>

  <div *ngIf="dataList?.length === 0" id="empty-content">
    <app-banner ></app-banner>
    <div class="inner">
      <p>ロッカーがありません</p>
      <p>リロードするか最初か</p>
      <p>らやり直してください</p>
<p><a href = "https://select.spacer.live">初めの画面に戻る</a></p>
    </div>
    <div class="detect">
    </div>
  </div>
  <div class="flex">
    <p class="about-payment" (click)="clickAboutPayment($event)">
      お問合せについて
    </p>
    <img class="question-icon" src="../../../assets/images/ui-ver2.0/icon_question.svg" />
  </div>
</div>
<app-global-menu-component></app-global-menu-component>
