<div id="content">
  <h2>通知設定の変更</h2>
  <div class="notification-view">
    現在の通知設定
    <div class="text-area">
      <div>オフになっています</div>
    </div>
    <button class="large-black">通知の設定を開く</button>
  </div>
</div>
<app-global-menu-component></app-global-menu-component>
