import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { BannerMasterRepositoryService } from '../banner-master-repository-service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  enabled = false;
  imageUrl: string;
  openUrl: string;

  constructor(
    private storage: AngularFireStorage,
    private bannerMasterService: BannerMasterRepositoryService) {
  }

  async ngOnInit() {
    this.bannerMasterService.getCurrent().then((banner) => {
      if (banner) {
        this.enabled = true;
        this.openUrl = banner.openUrl || '';
        this.storage.ref(banner.imagePath).getDownloadURL().subscribe(url => {
          this.imageUrl = url;
        });
      }
    });
  }
}
