import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpJsonService } from "../http-json.service";

@Component({
  selector: "apart-dialog",
  templateUrl: "./apart-dialog.html",
  styleUrls: ["./apart-dialog.scss"]
})
export class ApartDialog {
  room: string;
  values = {};
  spacerid: string;
  myuid: string;

  constructor(
    private _httpjson: HttpJsonService,
    public dialogRef: MatDialogRef<ApartDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.values = data.apartValues;
    this.spacerid = data.spacerid;
    this.myuid = data.myuid;
    /*    const ref = db.list('keyreceivers/' + data.spacerid );
    ref.valueChanges().pipe(take(1)).subscribe(report => {
    const length = report.length / 2;
    if (length === 0) {
        alert('対応していないSPACERです。');
        this.dialogRef.close('');
    }
    for (let i = 0; i < length; i++) {
        this.values[String(report[i])] = report[i + length ];
    }
    });*/
  }

  async request() {
    if (this.room === undefined) {
      alert("お住まいの方を選択して下さい");
      return;
    }
    alert(this.room + "にカギを送ります。");
    const url =
      "https://spacer.live/collaborateApart/" +
      this.data.spacerid +
      "/" +
      this.values[this.room] +
      "/" +
      this.myuid;
    const res = await this._httpjson.getJson(url);
    if (res) {
      const result = res["result"];
      if (result === "accept") {
        alert(this.room + "にカギを送信しました。");
      } else if (result === "deny") {
        alert("ユーザーが登録されていません。");
      }
    }
  }
}
