import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: "app-restrict",
  templateUrl: "./restrict.component.html",
  styleUrls: ["./restrict.component.scss"]
})
// 既存のQRコードを読み取った時に、指定のSPACERが開くようにする機能。
// UIの変更前の機能のため、リダイレクトし、新しいUIに適応。
export class RestrictComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const selectedSpacer = this.route.snapshot.paramMap.get('id');
    this.router.navigateByUrl('mylockers/detect/' + selectedSpacer);
  }

}
