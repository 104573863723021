import { Component, OnInit } from '@angular/core';
import {UserOperationLoggerService} from '../user-operation-logger.service';
import {SpacerErrorAnnouncer, SpacerErrorHandleService} from '../lib/spacer-error-handle/spacer-error-handle.service';
import {MembersRepositoryService} from '../members-repository-service';
import {ControlValueAccessor} from '@angular/forms';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit, ControlValueAccessor {
  userName = '';
  mailAddress = '';
  inquiryText = '';

  inquiry = {
    userName: '',
    mailAddress: '',
    inquiryText: '',
  }

  constructor(
    private userOpeLogger: UserOperationLoggerService,
    private errorHandleService: SpacerErrorHandleService,
    private memberRepo: MembersRepositoryService
  ) { }

  ngOnInit() {
  }

  canNotSend(): boolean {
    [
      this.userName,
      this.mailAddress,
      this.inquiryText
    ].forEach(elm => {
      if (this.errorHandleService.isEmptyString(elm)) {
        return true;
      }
    });
    return false;
  }

  canSend(): boolean {
    return !this.canNotSend();
  }

  htmlEscape(s) {
    s = s.replace(/&/g, '＆')
      .replace(/</g, '＜')
      .replace(/>/g, '＞')
      .replace(/"/g, '”')
      .replace(/'/g, '’');
    return s;
  }

  async send() {
    const isEmpty = this.errorHandleService.isEmptyString;
    let alertMsg = ''
    if (isEmpty(this.inquiry.userName)) {
      alertMsg = 'お名前を入力してください。';
    } else if (isEmpty(this.inquiry.mailAddress)) {
      // alertMsg = 'メールアドレスを入力してください。';
    } else if (isEmpty(this.inquiry.inquiryText)) {
      alertMsg = 'お問合せ内容を入力してください。';
    };
    if (alertMsg !== '') {
      new SpacerErrorAnnouncer(alertMsg).announce();
      return;
    }
    // const options = {
    //   userName: this.userName,
    //   mailAddress: this.mailAddress,
    //   inquiryText: this.inquiryText
    // }
    const options = [];
    const phoneNum = await this.memberRepo.getPhoneNum();
    options.push(`${this.htmlEscape(this.inquiry.userName)} 様 (TEL:${phoneNum} MAIL:${this.htmlEscape(this.inquiry.mailAddress)})`);
    options.push(this.htmlEscape(this.inquiry.inquiryText));
    this.userOpeLogger.save(options);
  }

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
  }

}
