import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DialogService } from '../../dialogs/dialog.service';
import { Router } from '@angular/router';
import { ReserveCancelApiService } from '../../reserve-cancel-api.service';
import {UserOperationLoggerService} from '../../user-operation-logger.service';
import {PartnerShopMembersRepositoryService} from "../../partner-shop-members-repository.service";
import {CheckProductSetApiService} from "../../check-product-set-api.service";
import {CalcPriceApiService} from "../../calc-price-api.service";
import {PrepaidService} from '../../prepaid.service';
import {AngularFireDatabase} from "@angular/fire/database";
import { take } from "rxjs/operators";


@Component({
  selector: 'app-mylocker-item',
  templateUrl: './mylocker-item.component.html',
  styleUrls: ['./mylocker-item.component.scss']
})
/**
 * マイロッカーに表示されるBoxをあらわす Component です。
 */
export class MylockerItemComponent implements OnInit {
  @Input() item;
  private _isReserved: boolean;
  // private _expiredTime: Date;
  private _expiredTimeNum: number;
  private hourleft: number;
  message: string;
  private _isAutoCanceled: boolean;
  paidExpired;
  priceValues;
  is_paid_expired = false;
  fetched = false;
  prepaidEndTime;
  isPrepaidExpired = false;
  isShared = false;
  redirectURL = "";
  unitnum:string;
  returnUrl: string;
  checkbox: string;
  other: string;
 
  /**
   * 取り出し制限時間（分）
   */
  private readonly openLimitMinits = 10;

  /**
   * 利用料金が支払われると true となる。
   * 支払い後、取り出し制限時間をすぎると false となる。
   * 注意 : このフラグは「商品代金が全額支払い済かどうか」の判定には利用できない。
   */
  is_paid = false;

  readonly viewName = 'マイロッカー';
  private _canProductSet: boolean; // MEMO 既知の書き込み元は ngOnInit です。

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dialogService: DialogService,
    private userOpeLogger: UserOperationLoggerService,
    private cancelApiService: ReserveCancelApiService,
    private partnerShopMembersRepository: PartnerShopMembersRepositoryService,
    private checkProductSetApi: CheckProductSetApiService,
    private calcPriceApi: CalcPriceApiService,
    private prepaidService: PrepaidService,
    private db: AngularFireDatabase,
  ) {}

  // HACK 商品設定やロッカーの無料設定などの組み合わせにより、可読性が低くなっています。
  get status(): string {
    if (this.canSetPrice()) {
      return 'sellProduct';
    } else {
      if (this.hasProduct) {
        return 'buyProduct';
      }
    }

    if (this._isReserved) {
      if (this._isAutoCanceled) {
        return 'autoCanceled';
      } else {
        return 'reserved';
      }
    }
    if (this.totalPayPrice === 0 || this.item.status === 'free') {
      return 'free';
    } else {
      return 'use';
    }
  }

  get deviceNum(): string {
    return this.item['device'].replace(/[^0-9^\.]/g, '');
  }

  // private get priceObject() {
  //   // console.log(this.priceValues['list']);
  //   // console.log(this.priceObject);
  //   console.log('test');
  //   return this.priceValues['list']['0'];
  // }

  get usePrice(): number {
    return this.priceValues.usePrice;
  }

  get reservedPrice(): number {
    return this.priceValues.reservePrice;
  }

  get productPrice(): number {
    return this.priceValues.productPrice;
  }

  get payedPrice(): number | null {
    return this.priceValues.paidPrice;
  }

  get totalPrice(): number | null {
    return this.priceValues.totalPrice;
  }

  get totalPayPrice(): number | null {
    return this.priceValues.totalPayPrice;
  }

  get hasProduct(): boolean {
    if (this.item['product']) {
      return true;
    }
    return false;
  }

  get isAutoCanceled(): boolean {
    return this._isAutoCanceled;
  }

  /**
   * 商品を設定した人の名前。
   */
  get senderName(): string {
    if (this.item['product']) {
      return this.item['product']['senderName']
    }
    return null;
  }

  /**
   * 商品の説明文。
   */
  get productDesc(): string {
    if (this.item['product']) {
      return this.item['product']['desc']
    }
    return null;
  }

  async ngOnInit() {
    this._expiredTimeNum = new Date(this.item.expired).getTime();
    this._isReserved = this.item.isReserved === "true";
    await this.partnerShopMembersRepository.fetchCurrentUser();
    // this._canProductSet に、商品設定権限判定結果を保持する。
    const responseCheckProductSetApi = await this.checkProductSetApi.checkProductSet(`SPACER` + this.deviceNum);
    if(responseCheckProductSetApi) {
      this._canProductSet = responseCheckProductSetApi['result'] === 'true';
    } else {
      // 万が一、商品設定権限判定のためにAPIと通信できない場合には、一旦、商品設定できないようにしておく。
      this._canProductSet = false;
    }
//年・月・日・曜日を取得する
    const hiduke=new Date(); 
    const year = hiduke.getFullYear();
    const month = hiduke.getMonth()+1;
    const day = hiduke.getDate();
    const hour = hiduke.getHours();
    const minute = hiduke.getMinutes();
 
    const leisuretime = this.item.leisuretime;
    const leisuretimenumber = parseInt(leisuretime);
    if(hour == 1){
      this.hourleft =  (23 - hour);
    }else{
      this.hourleft =  (23 - hour) + 24*(leisuretimenumber-1);
    }
    const minuteleft =  (60 - minute);
    this.redirectURL = "<meta http-equiv='refresh' content='0;URL=choice-share-type-last/" + this.deviceNum + "'>";
    this.message = this.hourleft + "時間" + minuteleft + "分";
    await this.fetchPrice();
    if (this.item.paidAt) {
      const paidAt = new Date(this.item.paidAt);
      paidAt.setMinutes( paidAt.getMinutes() + this.openLimitMinits);
      this.paidExpired = paidAt;
      this.is_paid = true;
    };

    if(this.item.prepaid){
      this.prepaidEndTime = new Date(this.item.prepaid.prepaidEndAt);
    }
    this.setSharedCardHeader();
  }

  timeOver() {
    this.is_paid_expired = true;
    this.is_paid = false;
  }

  prepaidTimeOver() {
    this.isPrepaidExpired = true;
  }

  clickSharingKey(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.router.navigateByUrl(`choice-share-type/${this.item['device']}`)
  }

  clickPayAndOpen(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    // CautionBeforeOpenComponentの追加
    this.dialogService.cautionBeforeOpen(`confirm-payment/${this.deviceNum}/use`);
  }

  clickOpen(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.router.navigateByUrl(`mylockers/detect/SPACER${this.deviceNum}`);
  }

  clickOpenFree(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.router.navigateByUrl(`mylockers/detect/SPACER${this.deviceNum}`);
  }

  clickCancel(event) {
    if(this.hasProduct) {
      alert('このロッカーは商品設定済のため、キャンセルできません。');
      return;
    }
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.cancelApiService.id = `SPACER${this.deviceNum}`;
    this.dialogService.manualCancelCautionComponent();
  }

  clickReserveCalcel(event) {
    if(this.hasProduct) {
      alert('このロッカーは商品設定済のため、キャンセルできません。');
      return;
    }
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.dialogService.manualCancelCautionComponent();
  }

  clickPayAutoCanceled(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.router.navigateByUrl(`confirm-payment/${this.deviceNum}/autoCanceled`);
  }

  canSetPrice() {
    return this._canProductSet
  }

  clickSetPrice(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.router.navigateByUrl(`share-with-price/${this.deviceNum}`)
  }

  clickPrepaid(event) {
    if (!this.priceValues.canSetPrepaid.result){
      alert(this.priceValues.canSetPrepaid.error.message);
      return;
    }

    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());

    this.dialogService.prepaidDialogComponent(
      this.item['device'],
      `confirm-payment/${this.deviceNum}/prepaid`);
  }

  clickAboutPrepaid(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, this.deviceForUserOpeLog());
    this.dialogService.aboutPrepaidDialog();
  }

  /**
   * 自動キャンセル日時に達しているかどうか判定する目的で、
   * マイロッカー上のタイマーで1秒経過するたびに呼び出されます
   * @param eventData
   */
  onReceiveEventFromChild(eventData: number) {
    const currentTimeNum = new Date().getTime();
    if (!this.hasProduct) {
      // 商品設定済の場合は自動キャンセルさせない
      this._isAutoCanceled =
        this._isReserved && currentTimeNum > this._expiredTimeNum;
    }
    // this.fetchPrice();
  }

  async fetchPrice() {
    if(!this.item.device){
      return;
    }
    const response = await this.calcPriceApi.calcSingleFromMylocker(this.item.device);
    this.priceValues = response.priceValues;
    // console.log(this.priceValues);
    this.fetched = true;
  }

  private deviceForUserOpeLog(): string {
    return `対象ロッカー:SPACER${this.deviceNum}`;
  }

  private setSharedCardHeader() {
    const sendKeyUrl = this.item.sendKeyURL;
    const itemRef = this.db.object<any>(`sendKeyURL/${sendKeyUrl}`);
    itemRef.valueChanges().pipe(take(1)).subscribe(data => {
      if (data.sharedMembers) {
        this.isShared = true;
      }
    });
  }
}

