<div id="fullview">
  <div class="logo">
    <img src="assets/images/logo.svg" height="60px" />
  </div>

  <div class="h2-a">SPACER(スペースアール)会員の方</div>
  <button class="k" (click)="onConfirm()" class="large-black">
    確認する
  </button>
  <div class="white-area">
    <p class="textarea">
      共有された鍵を利用するには、サービスサイトへログインして内容をご確認ください。
    </p>
    <!-- 通常　ボタン非活性ここにチェック入ったら、ボタン活性 -->
  </div>
  <div class="gray-area">
    <div class="h2-a">会員登録がお済みでない方</div>
    <p class="textarea">
      SPACER(スペースアール)のアプリをダウンロードし、会員登録後、上記の「確認する」ボタンをタップしてください。
    </p>
    <div class="logo-area">
      <a href="https://apps.apple.com/jp/app/spacer-%E3%82%B9%E3%83%9A%E3%83%BC%E3%82%B9%E3%82%A2%E3%83%BC%E3%83%AB/id1200289673" target="_blank"><img
        class="logo-apple"
        src="../../assets/images/ui-ver2.0/top_btn01.png"
      /></a>
    </div>
  </div>
</div>
