<p id="title">洗濯たのめーる<br />(crowd-wash)</p>
<br />
<form>
  <select class="select-btn" (change)="changeItem($event)">
    <option value="default">マッチング情報をご選択下さい</option>
    <option *ngFor="let item of matches | async" [value]="item.key"
      >{{item.val.target_name}}さん ( {{item.key}} )</option
    > </select
  ><br /><br />
</form>

<div *ngIf="is_selected; then selected">###</div>
<ng-template #selected>
  <div *ngFor="let match of matches | async">
    <div *ngIf="match.key==match_selected;">
      <p class="normal-size">マッチング相手：{{match.val.target_name}} さん</p>
      <p class="normal-size">対象店舗ID：{{match.val.spacer_id}}</p>
      <p class="normal-size">
        預け入れ予定日時：{{match.val.deposit_time_char}}
      </p>
      <p class="normal-size">
        洗濯完了予定日時：{{match.val.collect_time_char}}
      </p>
      <button class="btn" (click)="request()">{{dsp_btn}}</button>
    </div>
  </div>
</ng-template>
