import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpJsonService } from '../http-json.service';

@Component({
  selector: 'app-key',
  templateUrl: './key.component.html',
  styleUrls: ['./key.component.scss']
})
export class KeyComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpjson: HttpJsonService,
  ) { }

  async ngOnInit() {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      alert('iOSの場合、マイロッカーへの追加はできますが、鍵の開け締めは出来ません。アプリをダウンロード頂き、ご利用ください。');
    }
    const sendKeyUrl = this.route.snapshot.paramMap.get('id');
    await this.setSharedLocker(sendKeyUrl);
    this.router.navigateByUrl('notifications');
  }

  //鍵共有後のDB処理のAPI
  private async setSharedLocker(sendKeyUrl: string) {
    const url = `${environment.apiUrl}myLocker/shared`
    const params = {
      urlKey: sendKeyUrl,
    }
    const data = await this.httpjson.postJson(url, params);
    if (data.isSuccess === false) {
      alert(data.error['message']);
    }
  }
}
