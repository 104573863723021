import { Injectable } from '@angular/core';
import {MemberApiService} from "./member-api.service";
import {MemberInfo} from "./entity/member-info";

@Injectable({
  providedIn: 'root'
})
export class FirstPaymentFreeService {
  private _memberInfo: MemberInfo;

  /**
   * チュートリアルを終えたときに true となる
   * ブラウザリロードで false にリセットされる
   */
  isTutorialFinishedAfterLoad = false;

  constructor(
    private memberApiService: MemberApiService
  ) { }

  /**
   * 初回利用無料メッセージを表示するかどうか判断する
   */
  async shouldAlertPaymentFree() {
    if (!this._memberInfo) {
      this._memberInfo = await this.memberApiService.getInfo();
      return this._memberInfo.isFirstPaymentFree;
    } else {
      // 既に表示済であれば、表示しない（ブラウザをリロードすると再表示される）
      return false;
    }

  }

  get messagePaymentFree(): string {
    return "初回利用時は無料です";
  }
}
