<nav id="global-menu-container">
  <!-- <app-global-menu-item
    text="開ける"
    targetUrl="/put"
    imageCurrentUrl="../../assets/images/ui-ver2.0/icon_check.svg"
    imageDefaultUrl="../../assets/images/ui-ver2.0/icon_check_opacity0-5.svg"
  ></app-global-menu-item> -->
  <app-global-menu-item
    text="マイロッカー"
    targetUrl="/mylockers"
    imageCurrentUrl="../../assets/images/ui-ver2.0/icon_locker.svg"
    imageDefaultUrl="../../assets/images/ui-ver2.0/icon_locker_opacity0-5.svg"
  ></app-global-menu-item>
 <app-global-menu-item
    text="お知らせ"
    targetUrl="/notifications"
    imageCurrentUrl="../../assets/images/ui-ver2.0/icon_information.svg"
    imageDefaultUrl="../../assets/images/ui-ver2.0/icon_information_opacity0-5.svg"
  ></app-global-menu-item>
  <app-global-menu-item
    text="メニュー"
    targetUrl="/menu"
    imageCurrentUrl="../../assets/images/ui-ver2.0/icon_menu.svg"
    imageDefaultUrl="../../assets/images/ui-ver2.0/icon_menu_opacity0-5.svg"
  ></app-global-menu-item>
</nav>
