<!-- 通常利用のロッカー。 -->
<div class="container-header">
  <div class="container-use-type-icon">
    <div class="use-type-icon">
      <img src="../../../assets/images/ui-ver2.0/icon_in_use.svg" />
    </div>
    <div class="use-type-text">利用中</div>
  </div>
  <div class="container-box-id">
    <div class="box-id">{{ box.boxId }}</div>
    <div class="box-address">ここに本文テキストがはいりまあああああああああああああああああああああああああっす</div>
  </div>
</div>

<hr>

<div class="container-propname-and-value">
  <div class="propname">利用開始から</div>
  <div class="value">
    <div id="timer-text">

    </div>
  </div>
</div>

<hr>

<div class="container-propname-and-value">
  <div class="propname">利用料金</div>
  <div class="value">¥1,234</div>
</div>

<div class="container-propname-and-value">
  <div class="propname"><span class="large">合計料金</span></div>
  <div class="value"><span class="large">¥1,234</span></div>
</div>

<div class="container-buttons">
  <button class="large-black">ロッカーを開ける</button>
  <button class="large-white">鍵を共有する</button>
</div>
