/**
 * 電話番号を登録するためのパラメタ(ログイン時に利用)。
 */
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
export class PhoneNumberAuthParameter {
  public isAgreeTermOfUseAndPrivacyPolicy: boolean;
  public phoneNumber: string;
  public phoneNumberPrefix: string;
  public confirmationCode: string;


  constructor() {
    this.isAgreeTermOfUseAndPrivacyPolicy = false;
  }

  /**
   * 国ごとの電話番号の接頭辞と、フォームに入力された電話番号を組み合わせた結果を返す。
   */
  completelyPhoneNumber() {
    const telNo = this.phoneNumber || "";
     console.log("DEBUG");
    // MEMO 日本であれば、+81 と 09012341235 を組み合わせた結果として 819012341234 を返す
    return `+81${telNo.slice(1)}`;
  }

  // TODO DELTE (呼び出し元なし)
  codeCharAt(idx): string {
    const code = this.confirmationCode || "";
    return code.charAt(idx);
  }
}
