import { Component, Inject, ElementRef, OnInit } from '@angular/core';
import { SpacerErrorHandleService } from '../lib/spacer-error-handle/spacer-error-handle.service';
import { PhoneNumberAuthParameter } from '../authentication/telephone-number-change/phone-number-auth-parameter';
import { PhoneNumberChangeService } from '../authentication/services/phone-number-change-service';
import { MembersRepositoryService } from '../members-repository-service';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-phone-number-change-confirmation-code-form',
  templateUrl: './change-phone-num-conf.component.html',
  styleUrls: ['./change-phone-num-conf.component.scss'],
})
export class ChangePhoneNumConfComponent implements OnInit {
  public parameters = new PhoneNumberAuthParameter();
  phone = '';
  private viewName: string;

  constructor(
    private phoneNumberChangeService: PhoneNumberChangeService,
    private errorHandleService: SpacerErrorHandleService,
    private _member: MembersRepositoryService,
    private el: ElementRef,
    private router: Router
  ) {
    // 現時点では i18nClientService は利用していないが、
    // 将来的には、国を選択するドロップダウンリストの中身を、i18nClientService を用いて取得するようにしたい
    // private i18nClientService: I18nClientService,
    this.phone = this.phoneNumberChangeService.getNewPhoneNum();
  }

  sendConfirmationCode() {
    const errorHandleService = this.errorHandleService;
    this.phoneNumberChangeService
      .sendConfirmationCode(this.parameters.confirmationCode)
      .then(function () {
        this._member.updatePhoneNum(this.phone);
        alert('電話番号を変更しました。');
        this.router.navigateByUrl('/put');
      })
      .catch(function (error) {
        errorHandleService.handle(error, alert);
      });
  }

  ngOnInit(): void {
    this.viewName = this.el.nativeElement.querySelectorAll('h2')[0].innerText;
  }
}
