import { AppRoutingModule } from './app-routing.module';
import { QRCodeModule } from 'angular2-qrcode';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { KeyComponent } from './key/key.component';
import { RestrictComponent } from './restrict/restrict.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { HomeComponent } from './home/home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApartDialog } from './home/apart-dialog';
import { CrowdWashDialog } from './home/crowd-wash-dialog';
import { LaundryPackDialog } from './home/laundry-pack-dialog';
import { AuthGuard } from './auth.service';
import { LoginComponent } from './login/login.component';
import { CountDownTimerComponent } from './count-down-timer-component/count-down-timer-component.component';
import { CountUpTimerComponent } from './count-up-timer-component/count-up-timer-component.component';
import { TextCopyDirective } from './copy-text.directive';
import { HttpJsonService } from './http-json.service';
import { KeyControllerService } from './key-controller.service';
import { MapsComponent } from './maps/maps.component';
import { CommonService } from './service/common.service';
import { MainNavComponent, TicketDialog } from './main-nav/main-nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './custom-material/app.material.module';
import { IDLinkComponent } from './idlink/idlink.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentCreditcardComponent } from './payment-creditcard/payment-creditcard.component';
import { PaymentService } from './payment.service';
import { PaymentAlertDialog } from './payment/payment-alert-dialog';
import { PaymentConfrimDialog } from './payment/payment-confrim-dialog';
import { TutorialComponent } from './tutorial/tutorial.component';
import { AuthProvidersComponent } from './auth-providers/auth-providers.component';
import { GlobalMenuComponent } from './global-menu/global-menu.component';
import { CustomIframeComponent } from './lib/custom-iframe/custom-iframe.component';
import { GlobalMenuItemComponent } from './global-menu/global-menu-item.component';
import { HeaderMediumComponent } from './lib/header-medium/header-medium.component';
import { SpacerErrorHandleService } from './lib/spacer-error-handle/spacer-error-handle.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { ReserveConfirmComponent } from './reserve/reserve-confirm/reserve-confirm.component';
import { MylockerListComponent } from './mylocker/mylocker-list/mylocker-list.component';
import { MylockerItemComponent } from './mylocker/mylocker-item/mylocker-item.component';
import { PutComponent } from './put/put.component';
import { HistoryComponent } from './history/history.component';
import { RegistPaymentMethodComponent } from './regist-payment-method/regist-payment-method.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { ChangePaymentMethodComponent } from './change-payment-method/change-payment-method.component';
import { ChangePhoneNumComponent } from './change-phone-num/change-phone-num.component';
import { ChangePhoneNumConfComponent } from './change-phone-num-conf/change-phone-num-conf.component';
import { ReservePaymentCompletedComponent } from './reserve-payment-completed/reserve-payment-completed.component';
import { UsePaymentCompletedComponent } from './use-payment-completed/use-payment-completed.component';
import { CancelPaymentCompletedComponent } from './cancel-payment-completed/cancel-payment-completed.component';
import { OtherMenuComponent } from './other-menu/other-menu.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { FaqComponent } from './faq/faq.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TransactionsLawComponent } from './transactions-law/transactions-law.component';
import { SvgComponent } from './svg/svg.component';
import { DetectComponent } from './put/detect/detect.component';
import {
  AboutKeyShareingComponent,
  AboutPrepaidComponent,
  CopiedKeyURLComponent,
  OpenedLockerComponent,
  ReserveCautionComponent,
  SelectPaymentMethodComponent,
  CautionBeforeOpenComponent,
  ManualCancelCautionComponent,
  RequireCardComponent,
  AboutCancelPriceComponent,
  BeforeRemoveCardComponent,
  CleaningDialog,
  PrepaidDialogComponent,
} from './dialogs/dialog-components.component';
import { KeptComponent } from './put/kept/kept.component';
import { ChoiceShareTypeComponent } from './choice-share-type/choice-share-type.component'
import { ChoiceShareTypeLastComponent } from './choice-share-type-last/choice-share-type.component'
import { RootRedirectorComponent } from './root-redirector/root-redirector.component';
import { IosViewComponent } from './key-share/ios-view/ios-view.component';
import { AndroidViewComponent } from './key-share/android-view/android-view.component';
import { KotlinViewComponent } from './key-share/kotlin-view/kotlin-view.component';
import {UnsubscribeComponent} from './unsubscribe/unsubscribe.component';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import {HttpJsonRetryService} from './http-json-retry.service';
import { InquiryComponent } from './inquiry/inquiry.component';
import { ShareWithPriceComponent } from './share-with-price/share-with-price.component';
import { MyboxesBoxUseComponent } from './myboxes-box-use/myboxes-box-use.component';
import { MyboxesBoxReservedComponent } from './myboxes-box-reserved/myboxes-box-reserved.component';
import { MyboxesBoxAutoCanceledComponent } from './myboxes-box-auto-canceled/myboxes-box-auto-canceled.component';
import { MyboxesBoxWithProductComponent } from './myboxes-box-with-product/myboxes-box-with-product.component';
import { MyboxesComponent } from './myboxes/myboxes.component';
import { MyboxesBoxEditableProductComponent } from './myboxes-box-editable-product/myboxes-box-editable-product.component';
import { DebugViewComponent } from './debug-view/debug-view.component';
import { TickTimerComponent } from './tick-timer/tick-timer.component';
import { BannerComponent } from './banner/banner.component';

@NgModule({
  entryComponents: [
    MainNavComponent,
    TicketDialog,
    CleaningDialog,
    ApartDialog,
    LaundryPackDialog,
    CrowdWashDialog,
    PaymentAlertDialog,
    PaymentConfrimDialog,
    AboutKeyShareingComponent,
    AboutPrepaidComponent,
    OpenedLockerComponent,
    ReserveCautionComponent,
    CopiedKeyURLComponent,
    SelectPaymentMethodComponent,
    CautionBeforeOpenComponent,
    ManualCancelCautionComponent,
    RequireCardComponent,
    AboutCancelPriceComponent,
    BeforeRemoveCardComponent,
    MatSpinner,
    CleaningDialog,
    PrepaidDialogComponent,
  ],
  declarations: [
    AppComponent,
    KeyComponent,
    RestrictComponent,
    ConfirmComponent,
    HomeComponent,
    GlobalMenuComponent,
    GlobalMenuItemComponent,
    LoginComponent,
    UnsubscribeComponent,
    TutorialComponent,
    AuthProvidersComponent,
    CountDownTimerComponent,
    CountUpTimerComponent,
    MainNavComponent,
    TicketDialog,
    CleaningDialog,
    CustomIframeComponent,
    ApartDialog,
    LaundryPackDialog,
    CrowdWashDialog,
    TextCopyDirective,
    IDLinkComponent,
    MapsComponent,
    PaymentComponent,
    PaymentCreditcardComponent,
    PaymentAlertDialog,
    PaymentConfrimDialog,
    CautionBeforeOpenComponent,
    ManualCancelCautionComponent,
    RequireCardComponent,
    AboutCancelPriceComponent,
    BeforeRemoveCardComponent,
    PrepaidDialogComponent,

    // app/lib 配下の component
    HeaderMediumComponent,
    ReserveConfirmComponent,
    MylockerListComponent,
    MylockerItemComponent,
    HistoryComponent,
    RegistPaymentMethodComponent,
    ThankYouComponent,
    PrepaidDialogComponent,
    NotificationListComponent,
    ChangePaymentMethodComponent,
    ChangePhoneNumComponent,
    ChangePhoneNumConfComponent,
    // SelectPaymentMethodComponent,
    ReservePaymentCompletedComponent,
    UsePaymentCompletedComponent,
    CancelPaymentCompletedComponent,
    PutComponent,
    OtherMenuComponent,
    TermsComponent,
    TransactionsLawComponent,
    FaqComponent,
    PrivacyComponent,
    NotificationSettingsComponent,
    SvgComponent,
    DetectComponent,
    AboutKeyShareingComponent,
    AboutPrepaidComponent,
    CopiedKeyURLComponent,
    OpenedLockerComponent,
    KeptComponent,
    ReserveCautionComponent,
    SelectPaymentMethodComponent,
    ChoiceShareTypeComponent,
    ChoiceShareTypeLastComponent,
    RootRedirectorComponent,
    IosViewComponent,
    AndroidViewComponent,
    KotlinViewComponent,
    ConfirmPaymentComponent,
    InquiryComponent,
    ShareWithPriceComponent,
    MyboxesBoxUseComponent,
    MyboxesBoxReservedComponent,
    MyboxesBoxAutoCanceledComponent,
    MyboxesBoxWithProductComponent,
    MyboxesComponent,
    MyboxesBoxEditableProductComponent,
    DebugViewComponent,
    TickTimerComponent,
    BannerComponent
  ],
  imports: [
    BrowserModule,
    QRCodeModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    OverlayModule,
    MaterialModule,
    AuthenticationModule,
    AppRoutingModule,
    ServiceWorkerModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ServiceWorkerModule.register('ngsw-worker.js')
  ],
  providers: [
    HttpJsonService,
    HttpJsonRetryService,
    CommonService,
    AuthGuard,
    KeyControllerService,
    PaymentService,
    SpacerErrorHandleService,
  ],
  exports: [HeaderMediumComponent, CountDownTimerComponent, CountUpTimerComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
