import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as firebase from 'firebase';
import {MembersRepositoryService} from "../members-repository-service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-debug-view',
  templateUrl: './debug-view.component.html',
  styleUrls: ['./debug-view.component.scss']
})
export class DebugViewComponent implements OnInit, AfterViewInit {
  _mylokcers;
  _mylockersDump: string = '';
  constructor(
    private membersRepo: MembersRepositoryService
  ) { }

  async ngOnInit() {
  }

  get uuid() {
    return firebase.auth().currentUser.uid;
  }

  get firebasePathForMembers() {
    return `${environment.firebase.databaseURL}/members/${this.uuid}`;
  }

  get firebasePathForMembersMylockers() {
    return `${this.firebasePathForMembers}/myLockers`;
  }

  get mylockers() {
    return this._mylokcers;
  }

  async ngAfterViewInit() {
    console.log('DEBUG before getMylockers');
    this._mylokcers = await this.membersRepo.getMylockers();
    console.log('DEBUG after getMylockers');
    console.log(this._mylokcers);
    document['DEBUG'] = {}
    document['DEBUG']['mylockers'] = this._mylokcers;
    this.eachKeys(this._mylokcers, (boxId) => {
      console.log('DEBUG box ...');
      console.log(this._mylokcers[boxId]);
      this._mylockersDump = this._mylockersDump + JSON.stringify(this._mylokcers[boxId]);
    })
  }

  private eachKeys(map, callback) {
    const keys = Object.keys(map);
    keys.forEach(callback);
  }



}
