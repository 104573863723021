import { Injectable } from '@angular/core';
import { AngularFireDatabase } from "@angular/fire/database";
import { map } from "rxjs/operators";
import * as firebase from 'firebase';
import { PartnerMember } from "../entity/partner-member";
import { Observable } from "rxjs";
import { PaymentFree } from "../entity/payment-free";
import { PutLimitedUnit } from "../entity/put-limited-unit";
import { ParseDepth, RoleParser } from "./role-parser";
import {PartnersRepositoryImplService} from "./partners-repository-impl.service";
import {ProductSet} from "../entity/product-set";

@Injectable({
  providedIn: 'root'
})
export class PartnerMembersRepositoryImplService {

  private _currentPartnerMember: PartnerMember;
  currentPartnerMemberObservable: Observable<PartnerMember>;
  private roleParser: RoleParser;

  constructor(
    private db: AngularFireDatabase,
    public partnersRepository: PartnersRepositoryImplService,
  ) {
    this.roleParser = new RoleParser();
  }

  get currentPartnerMember(): PartnerMember {
    return this._currentPartnerMember;
  }

  async asyncCurrentPartnerMember() {
    if (!this._currentPartnerMember) {
      await this.refreshObserver();
    }
    return this._currentPartnerMember;
  }

  async refreshObserver() {
    this.currentPartnerMemberObservable = this.db.object(`partnerMembers/${firebase.auth().currentUser.uid}`)
      .snapshotChanges().pipe(
        map(c => { return c.payload.val() }),
        // map(val => { return val[firebase.auth().currentUser.uid] }),
        map( item => { return this.parseItemPartnerMember(item) })
      )
    await new Promise<void>((resolve) => {
      this.currentPartnerMemberObservable.subscribe(obj => {
        this._currentPartnerMember = obj;
        this.partnersRepository.refreshObserver(obj);
      });
      resolve();
    })
  }

  private parseItemPartnerMember(item) {
    const partnerMember = new PartnerMember();
    if(item) {
      // 名前とパートナーIDだけ取得する
      partnerMember.name = item['name'];
      partnerMember.partnerId = item['partnerId'];

      // 以下はAPIで取得する
      // this.roleParser.parseTerm(item, partnerMember.term);
      // if(item['roles']) {
      //   const itemRole = item['roles'];
      //   if(itemRole['putLimitedUnit']) {
      //     this.parsePutLimitedUnit(itemRole['putLimitedUnit'], partnerMember.putLimitedUnit)
      //   }
      //   if(itemRole['paymentFree']) {
      //     this.parsePaymentFree(itemRole['paymentFree'], partnerMember.paymentFree)
      //   }
      //   if(itemRole['productSet']) {
      //     this.parseProductSet(itemRole['productSet'], partnerMember.productSet)
      //   }
      // }
    }
    return partnerMember;
  }

  private parsePaymentFree(srcItem, dstObj: PaymentFree) {
    this.roleParser.parseTerm(srcItem, dstObj.term);
    this.roleParser.parseUnits(srcItem['spacerUnits'], dstObj, ParseDepth.Unit);
  }

  private parsePutLimitedUnit(srcItem, dstObj: PutLimitedUnit) {
    this.roleParser.parseTerm(srcItem, dstObj.term);
    this.roleParser.parseUnits(srcItem['spacerUnits'], dstObj, ParseDepth.Box);
  }

  private parseProductSet(srcItem, dstObj: ProductSet) {
    this.roleParser.parseTerm(srcItem, dstObj.term);
    this.roleParser.parseUnits(srcItem['spacerUnits'], dstObj, ParseDepth.Box);
  }
}
