import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../dialogs/dialog.service';
import { MembersRepositoryService } from '../members-repository-service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  dataList;
  is_loading_view = true;
  view_id = '';

  constructor(
    private route: ActivatedRoute,
    private _dialog: DialogService,
    private _members: MembersRepositoryService
  ) {
    this.view_id = this.route.snapshot.paramMap.get('id');
  }

  openAboutKeySharing() {
    this._dialog.aboutKeyShareingDialog();
  }

  async ngOnInit() {
    this.dataList = await this._members.getNotifications();
    this.is_loading_view = false;
  }

}