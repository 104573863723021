import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MembersRepositoryService} from './members-repository-service';
import {PriceValues} from './price-values';
import {Mylocker} from "./entity/mylocker";
import {IBoxForMyBoxes} from "./myboxes/myboxes.component";
import {ICalcPriceParameter} from "./interface/i-calc-price-parameter";
import {CalcPriceParameter} from "./value-object/calc-price-parameter";
import {HttpJsonService} from "./http-json.service";

export class CalcSingleResult {
  private _locker: any;
  private _priceValues: PriceValues;
  constructor(locker: any, priceValues: PriceValues) {
    this._locker = locker;
    this._priceValues = priceValues;
  }

  get locker(): any {
    return this._locker;
  }

  get priceValues(): PriceValues {
    return this._priceValues;
  }

  /**
   * 支払が必要な場合は ture を返す。
   * 支払不要の場合は false を返す。
   * status が free、もしくは 料金合計が0 であれば、支払不要。
   */
  get shouldPay(): boolean {
    if(this.locker) {
      if(this.locker['status'] === 'free') {
        return false;
      }
    }
    if (this.priceValues.totalPayPrice == 0) {
      return false;
    }
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CalcPriceApiService {

  readonly url = `${environment.apiUrl}payment/calcPrice`;
  uuid: string;
  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  constructor(private httpClient: HttpClient,
              private httpJsonService: HttpJsonService,
              private membersRepository: MembersRepositoryService) { }

  // マイロッカーから、指定した BoxID を検索し、その料金を計算する。
  async calcSingleFromMylocker(spacerid: string): Promise<CalcSingleResult> {
    // バックアップ ここから
    // let gotLocker = null;
    // let gotPrice = null;
    // this.membersRepository.getMylockerBySpacerid(spacerid)
    //   .then((locker) => {
    //     gotLocker = locker;
    //   return this.calcSingleWithLocker(locker['device'], locker['startAt'], locker['reservedTime']);
    // }).then((price) => {
    //   gotPrice = price;
    //   // console.log('debug gotLocker ....');
    //   // console.log(gotLocker);
    //   // console.log('debug gotPrice ....');
    //   // console.log(gotPrice);
    // }).catch((error) => {
    //   // console.log('ERROR in calcSingle');
    //   // console.log(error);
    // });
    // バックアップ ここまで

    // 上記コードの then を await に変えたバージョン
    const locker = await this.membersRepository.getMylockerBySpacerid(spacerid);
    const singlePriceList = await this.calcSingleWithLocker(locker['device'], locker['startAt'], locker['reservedTime']);
    const priceElement = singlePriceList['list'][0];
    const priceObject = new PriceValues(
      priceElement['price'], 
      priceElement['reserved_price'], 
      priceElement['productPrice'], 
      priceElement['paidPrice'],
      priceElement['canSetProduct'],
      priceElement['canSetPrepaid']);

    // return {locker: locker, price: priceObject};
    return new CalcSingleResult(locker, priceObject);
  }

  private async calcSingleWithLocker(spacerid: string, timestamp: string, reserved_time: string) {
    const httpOptions = {
      headers: this.headers
    };

    const requestBodyJSON = [
      {
        uid: this.uuid,
        spacerid: spacerid,
        timestamp: timestamp,
        reserved_time: reserved_time
      }
    ];
    const requestBody = 'list=' + JSON.stringify(requestBodyJSON);
    return await this.httpJsonService.postFormUrlEncoded(this.url, requestBody);
  }

  async calcMulti(boxes: CalcPriceParameter[]) {
    const httpOptions = {
      headers: this.headers
    };

    const requestBodyJSON = boxes.map(box => {
      return { ...box };
    });

    const requestBody = 'list=' + JSON.stringify(requestBodyJSON);
    return await this.httpClient
      .post(this.url, requestBody, httpOptions)
      .toPromise()
  }
}
