import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SpacerErrorHandleService {
  private listAlertMessage = {
    "auth/invalid-phone-number": "電話番号の形式が不正です",
    "auth/invalid-verification-code": "確認コードが不正です",
    "auth/too-many-requests":
      "短時間に複数回処理が行われたため処理を中断しました。時間をおいて再度おためしください。",
    "auth/code-expired":
      "確認コードが時間切れとなりました。確認コードを取得しなおしてください。"
  };
  private defaultAlertMessage = "エラーが発生しました";

  constructor() {}

  handle(error, callback) {
    const message =
      this.listAlertMessage[error.code] || this.defaultAlertMessage;
    // console.log("ERROR.......");
    // console.log(error);
    callback(message);
  }

  isEmptyString(value: string): boolean {
    if (value) {
      if (value.trim() === '') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}

export class SpacerErrorAnnouncer {
  private locker: string = null;
  constructor(public baseMessage: string) {
  }

  announce(callback = null): void {
    alert(this.finallyMessage());
    if (callback) {
      callback();
    }
  }

  setSpacerId(id): this {
    this.locker = id;
    return this;
  }

  private strLocker(): string {
    if (this.locker) {
      return ` ロッカー番号: ${this.locker} `;
    }
    return '';
  }

  private finallyMessage(): string {
    if (this.baseMessage) {
    } else {
      this.baseMessage = 'エラーが発生しました';
    }
    return this.baseMessage + this.strLocker();
  }
}
