export class Country {
  constructor(public name: String, public telephoneNumberPrefix: string, public selected: string = '') {}

  public nameWithTelephoneNumber() {
    return `${this.name} ${this.telephoneNumberPrefix}`;
  }

  public nameWithPhoneNumber() {
    return `${this.name} ${this.telephoneNumberPrefix}`;
  }

  public phoneNumberPrefix(): string {
    return this.telephoneNumberPrefix;
  }

  public optionSelected(): string {
    return this.selected;
  }
}
