<div id="fullview">
  <ng-container [ngSwitch]="action">
    <ng-container *ngSwitchCase="'afterUse'">
      <!--      ロッカーの通常利用料金を支払った後の表示-->
      <h2>お支払いが完了しました</h2>
      <img
        src="../../assets/images/ui-ver2.0/pictgram_payment_completion.svg"
      />
      ロッカーに近づき、ロッカーの鍵を開けお荷物を取り出してください。
      <button class="large-black" (click)="userOpeLog($event)">鍵を開ける</button>
    </ng-container>

    <ng-container *ngSwitchCase="'afterReserve'">
      <!--      ロッカーの予約料金を支払った後の表示-->
      <h2>ご予約ありがとうございました</h2>
      <img
        src="../../assets/images/ui-ver2.0/pictgram_thanks_for_reserved.svg"
      />
      予約したロッカーを開けるには、ロッカーに近づきマイロッカーの「ロッカーを開ける」をタップしてください。
      <button class="large-black" (click)="gotoMylocker($event)">
        マイロッカーへ
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'afterManualCanceled'">
      <!--      手動キャンセルした後の表示 (TODO : 要検討 : 自動キャンセルと手動キャンセルを分けて考えるかどうか) -->
      <h2>ご利用ありがとうございました</h2>
      <img
        src="../../assets/images/ui-ver2.0/pictgram_payment_completion.svg"
      />
      <button class="large-black" routerLink="/menu/history" (click)="userOpeLog($event)">
        ご利用履歴を見る
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'used'">
      <h2>ご利用ありがとうございます</h2>
      <img
        src="../../assets/images/ui-ver2.0/pictgram_locker_closed.svg"
      />
      <h5>注意：次のお客様のために扉がきちんと閉まるまでご確認下さい。</h5>

      <button class="large-black" routerLink="/menu/history" (click)="userOpeLog($event)">
        ご利用履歴を見る
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="'afterPayAutoCanceled'">
      <!--      自動キャンセルされたロッカーの代金を支払った後の表示 (TODO : 要検討 : 自動キャンセルと手動キャンセルを分けて考えるかどうか) -->
      <h2>お支払いが完了しました</h2>
      <img
        src="../../assets/images/ui-ver2.0/pictgram_payment_completion.svg"
      />

      <button class="large-black" routerLink="/menu/history" (click)="userOpeLog($event)">
        ご利用履歴を見る
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'afterPrepaid'">
      <h2>お支払いが完了しました</h2>
      <img
        src="../../assets/images/ui-ver2.0/pictgram_payment_completion.svg"
      />
      <button class="large-black" (click)="clickSharingKey($event)">
        続けて鍵を共有する
      </button>
      <button class="large-white" routerLink="/menu/history" (click)="userOpeLog($event)">
        ご利用履歴を見る
      </button>
      <button class="large-white" (click)="gotoMylocker($event)">
        マイロッカーへ
      </button>
    </ng-container>
  </ng-container>
  <!--  以下、この画面のもととなるソースコード。既に不要だがいちおうコメントアウトしておく-->
  <!--  <h2>ご利用ありがとうございます</h2>-->
  <!--  <img src="../../assets/images/ui-ver2.0/pictgram_locker_closed.svg" />-->
  <!--  <button class="large-black" routerLink="../menu/history" >ご利用履歴を見る</button>-->
</div>
<app-global-menu-component></app-global-menu-component>
