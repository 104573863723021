<div id="content">
  <h2>ご利用履歴</h2>

  <div id="history">
    <!-- 表示されるタイトル -->
    <div class="eachbox" *ngFor="let item of entityList ">
      <!-- <div [class.reserve-cancel]="{{item.historyTypeStr === 'reserve-cancel'}}"> -->
      <!-- <div [class.reserve-cancel]="{{item.historyTypeStr}}"> -->

      <!-- ご利用タブ -->
      <div class="box-gray">
        <!-- <div [class.reserve-cancel]="{{item.historyTypeStr}}"></div> -->

        <div [ngClass]="item.historyTypeSymbol">{{ item.historyTypeStr }}</div>
        <div class="date">{{ item.startAt }} 〜</div>
      </div>

      <div class="locker-item">
        <h4 class="locker-no-title">ロッカー番号</h4>
        <div class="locker-number">{{ item.value.device }}</div>
      </div>
      <div class="locker-address">{{ item.value.address }}</div>
      <hr />
      <div class="usetime-item">
        <h4 class="usetime-title">利用時間</h4>
        <div class="usetime-number">{{ item.value.device }}</div>
      </div>
      <div class="using-time">
        <div>開始 {{ item.startAt }} 〜</div>
        <div>終了 {{ item.endAt }}</div>
      </div>
      <hr />

      <ng-container *ngIf="item.isCreatedByCurrentUser">
        <div class="price-item">
          <h4 class="price-title">料金</h4>
          <div class="price">¥ {{ item.value.price }}（税込）</div>
        </div>
        <div class="paid-time">{{ item.value.paidAt }}</div>
        <hr />
        <div class="payment-item">
          <h4 class="payment-title">決済方法</h4>
          <div class="payment-method">{{ item.value.paymentMethod }}</div>
        </div>
        <hr />
      </ng-container>
      <!-- <p>value.expired: {{ item.value.expired }}</p> -->
    </div>
  </div>

</div>
<app-global-menu-component></app-global-menu-component>
