import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { SpacerWebappAuthenticationService } from "./spacer-webapp-authentication-service";

@Injectable({
  providedIn: "root"
})
export class PhoneNumberAuthenticationService {
  // 電話番号認証に関するサービスです。
  // 電話番号認証の大まかな流れは以下の通りです。
  //
  // - 電話番号を Firebase へ送信し、confirmationResult を生成する。
  // - confirmationResult から verificationId を取得し、
  //   verificationId と確認コードをもとに、 credential を作成する。
  // - credential を用いてサインアップ/サインインを行う。

  _completelyPhoneNumber: string;

  constructor(
    private spacerWebappAuthenticationService: SpacerWebappAuthenticationService
  ) {}

  // 電話番号を Firebase へ送信し、confirmationResult を生成する。
  async sendPhoneNumber(
    recaptchaVerifier
  ): Promise<firebase.auth.ConfirmationResult> {
    const confirmationResult = await firebase
      .auth()
      .signInWithPhoneNumber(this._completelyPhoneNumber, recaptchaVerifier);
    return confirmationResult;
  }

  set completelyPhoneNumber(phoneNumber: string) {
    this._completelyPhoneNumber = phoneNumber;
  }

  getPhoneNum() {
    return this._completelyPhoneNumber;
  }
  // confirmationResult から verificationId を取得し、
  // verificationId と確認コードをもとに、 credential を作成する。
  sendConfirmationCode(confirmationCode: string): firebase.auth.AuthCredential {
    const verificationId = this.spacerWebappAuthenticationService
      .confirmationResult.verificationId;
    return firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      confirmationCode
    );
  }

  public credential(): firebase.auth.AuthCredential {
    return this.spacerWebappAuthenticationService.credential;
  }

  public setCredential(credential): void {
    this.spacerWebappAuthenticationService.credential = credential;
  }
}
