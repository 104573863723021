import { Injectable } from '@angular/core';
import { HttpJsonService } from './http-json.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrepaidService {
  constructor(
    private httpJsonService: HttpJsonService
  ) { }

  /**
   * 事前決済金額をリセットして、計算します
   * @param spacerId SPACERXXX
   */
  async initPrice(spacerId: string): Promise<any> {
    const url = `${environment.apiUrl}prepaid/initPrice`;
    const json = { spacerId };
    return await this.httpJsonService.postJson(url, json, false);
  }

  /**
   * 事前決済金額を計算します
   * @param spacerId SPACERXXX
   */
  async prevPrice(spacerId: string): Promise<any> {
    const url = `${environment.apiUrl}prepaid/setPrevPrice`;
    const json = { spacerId };
    return await this.httpJsonService.postJson(url, json, false);
  }

  /**
   * 事前決済金額を計算します
   * @param spacerId SPACERXXX
   */
  async nextPrice(spacerId: string): Promise<any> {
    const url = `${environment.apiUrl}prepaid/setNextPrice`;
    const json = { spacerId };
    return await this.httpJsonService.postJson(url, json, false);
  }

  /**
   * 計算中の事前決済金額を取得します
   * @param spacerId SPACERXXX
   */
  async getPrice(spacerId: string): Promise<any> {
    const url = `${environment.apiUrl}prepaid/getPrice/${spacerId}`;
    return await this.httpJsonService.getJson(url);
  }
}
