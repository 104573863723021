import {Component, OnInit, AfterViewInit, ElementRef} from '@angular/core';
import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';
import { PhoneNumberAuthParameter } from '../authentication/telephone-number-change/phone-number-auth-parameter';
import { Country } from '../country';
import { SpacerErrorHandleService } from '../lib/spacer-error-handle/spacer-error-handle.service';
import { Router } from '@angular/router';
import { SpacerWebappAuthenticationService } from '../authentication/services/spacer-webapp-authentication-service';
import { PhoneNumberChangeService } from '../authentication/services/phone-number-change-service';
import { MembersRepositoryService } from '../members-repository-service';
import {UserOperationLoggerService} from '../user-operation-logger.service';

@Component({
  selector: 'app-phone-number-change-phone-number-form',
  templateUrl: './change-phone-num.component.html',
  styleUrls: ['./change-phone-num.component.scss']
})
export class ChangePhoneNumComponent
  implements AfterViewInit, OnInit {
  public parameters = new PhoneNumberAuthParameter();
  private _listCountries: Array<Country>;
  private viewName: string;
  public get listCountries(): Iterable<Country> {
    return this._listCountries;
  }
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  phone = '';

  constructor(
    private phoneNumberChangeService: PhoneNumberChangeService,
    private spacerWebappAuthenticationService: SpacerWebappAuthenticationService,
    private http: HttpClient,
    private route: Router,
    private errorHandleService: SpacerErrorHandleService,
    private userOpeLogger: UserOperationLoggerService,
    private el: ElementRef,
    private _members: MembersRepositoryService
  ) // 現時点では i18nClientService は利用していないが、
  // 将来的には、国を選択するドロップダウンリストの中身を、i18nClientService を用いて取得するようにしたい
  // private i18nClientService: I18nClientService,
  {}

  async sendPhoneNumber(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    const authService = this.spacerWebappAuthenticationService;
    if (!this.parameters.isAgreeTermOfUseAndPrivacyPolicy) {
      return;
    }
    try {
      await this.phoneNumberChangeService.sendPhoneNumber(
        this.parameters.completelyPhoneNumber(),
        this.recaptchaVerifier
      );
      await this.route.navigateByUrl('phone-number-change/confirmation-code');
    } catch (error) {
      // console.log(error);
      this.errorHandleService.handle(error, alert);
    }
  }

  async ngOnInit() {
    this.viewName = this.el.nativeElement.querySelectorAll('h2')[0].innerText;
    this.phone = await this._members.getPhoneNum();
  }


  // "recaptcha-container"
  ngAfterViewInit() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': function () { }
    });

    // this.fetchListCountries();
    this.fetchListCountriesMock();
  }

  private fetchListCountries(): void {
    const i18nURL = 'http://192.168.55.10';
    const getObservable = this.http.get(i18nURL + '/countries.csv', {
      responseType: 'text'
    });
    const successProc = response => {
      this._listCountries = [];
      const listLine = response.split('\n');
      let lineIdx = 0;
      listLine.forEach(line => {
        if (line) {
          if (lineIdx <= 0) {
            // ドロップダウンリストの先頭になにか表示する場合は、以下のようにすればよさそう
            // this._listCountries.push(new Country('言語を選択してください', ''));
          } else {
            const cells = line.split(',');
            this._listCountries.push(new Country(cells[0], cells[1]));
          }
        }
        lineIdx += 1;
      });
    };
    const errorProc = error => {
      this.errorHandleService.handle(error, alert);
    };
    getObservable.subscribe(successProc, errorProc);
  }

  private fetchListCountriesMock(): void {
    this._listCountries = [];
    this._listCountries.push(new Country('en', '+1'));
    this._listCountries.push(new Country('ja', '+81', 'selected'));
  }
}
