import { AngularFireDatabase } from '@angular/fire/database';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SpacersUnit } from './entity/spacers-unit';
import { SpacersUnitDetail } from './entity/spacers-unit-detail';

@Injectable({
  providedIn: 'root'
})
export class SpacersUnitsRepositoryService {
  readonly tableName = 'spacersUnits';

  constructor(private db: AngularFireDatabase) { }

  async getUnit(unitId: string): Promise<SpacersUnit> {
    const observable: Observable<SpacersUnit> =
      this.db.object(`${this.tableName}/${unitId}`)
        .valueChanges()
        .pipe(take(1), map(v => this.parse(v)));

    return new Promise<SpacersUnit>((resolve) => {
      observable.subscribe(data => {
        resolve(data);
      });
    });
  }

  async getUnitBySpacerId(spacerId: string): Promise<SpacersUnit> {
    const deviceNum = spacerId.replace(/[^0-9^\.]/g, '');
    const unitId = deviceNum.substr(0, deviceNum.length - 1);
    return this.getUnit(unitId);
  }

  private parse(unitData: any): SpacersUnit {
    const unit = new SpacersUnit();
    const object = Object.entries(unitData.details) as [string, any][];
    unit.collaboration = this.getCollaboration(unitData);

    object.forEach(([key, value]) => {
      const detail = new SpacersUnitDetail();
      detail.id = key;
      detail.price = value.price;
      unit.details.push(detail);
    });
    return unit;
  }

  private getCollaboration(unitData: any) {
    return {
      isEnabled: unitData.collaboration ? unitData.collaboration.isEnabled : unitData.isCleaning,
      mailList: unitData.collaboration ? unitData.collaboration.mailList : unitData.cleaningMailList,
      name: unitData.collaboration ? unitData.collaboration.name : unitData.cleaningShopName,
      phoneNumber: unitData.collaboration ? unitData.collaboration.phoneNumber : unitData.cleaningShopNumber,
      type: unitData.collaboration ? unitData.collaboration.type : 'cleaning',
    };
  }
}
