import { Injectable } from '@angular/core';
import { md5 } from './md5';

@Injectable({
  providedIn: 'root'
})
export class SecureParamService {
  constructor() {}

  public getRandom() {
    let random = '';
    const c = 'aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ0123456789';
    const cl = c.length;
    for (let i = 0; i < 18; i++) {
      random += c[Math.floor(Math.random() * cl)];
    }
    return random;
  }

  public md5(...values: string[]) {
    // values.forEach(value => {console.log(`DEBUG md5 arg sample : ${value}`)});
    // console.log(`DEBUG value join result`);
    // console.log(values.join(''));
    // console.log(``);
    return md5(values.join(''));
  }
}
