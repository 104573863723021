<h2>お支払い内容のご確認</h2>
<div id="payment">
  <h3>ご利用内容:送付サービス.</h3>
  <h3>回収・送付料金</h3>
  <div id="price">
    <h4>ご利用料金</h4>
    <p>¥ {{ usePrice }}</p>
    <div *ngIf="reservedPrice > 0">
      <h4>ご予約料金</h4>
      <p>¥ {{ reservedPrice }}</p>
    </div>
    <div *ngIf="payedPrice > 0" >
      <h4>お支払い済料金</h4>
      <p>¥ {{ payedPrice }}</p>
    </div>
    <div *ngIf="productPrice > 0">
      <div id="msg-product-price">下記へのお支払い金額</div>
      <p>¥ {{ productPrice | number }}</p>
      <div id="product-fee-desc">
        お支払い内容<br>
        お支払先: {{senderName}}<br>
        {{ productDesc }}
      </div>
    </div>
  </div>
  <div id="amout">
    <h3>合計</h3>
    <p>¥ {{ totalPrice }}(税込)</p>
    <h3>決済方法</h3>
    <div id="carrear">
      <select id="select-carrear" name="select-carrear" [(ngModel)]="selectCarrear" >
        <option value="">選択してください</option>
        <option *ngIf="hasCreditCard()" value="creditcard">クレジットカード</option>
        <option value="docomo">Docomo</option>
        <option value="au">auかんたん決済</option>
        <option value="softbank">Softbank</option>
        <option value="paypay">PayPay</option>
        <option value="linepay">LINE Pay</option>
      </select>
    </div>
    <button class="middle2-white" (click)="clickBack($event)">戻る</button>
    <button class="middle2-black" (click)="clickPay($event)">決済する</button>
  </div>
</div>
