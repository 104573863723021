import { Injectable } from '@angular/core';
import { md5 } from './md5';
import { HttpJsonService } from './http-json.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import {BluetoothRefService} from './bluetooth-ref.service';
import {CheckPutLimitApiService} from "./check-put-limit-api.service";

@Injectable({
  providedIn: 'root'
})
export class LockerService {
  serviceUuid = 65296;
  characteristicUuid = 65297;
  uid: string;
  is_iOS = false;
  is_restricted = false;

  constructor(
      private _httpjson: HttpJsonService,
      private db: AngularFireDatabase,
      private auth: AngularFireAuth,
      private bluetoothRef: BluetoothRefService,
      private checkPutLimitApiService: CheckPutLimitApiService
  ) {
    this.auth.onAuthStateChanged(user => {
      if (!user) {
        return;
      }
      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        this.is_iOS = true;
      }
      this.uid = user.uid;
    });
  }

  private async checkUseLimit(spacerId: string) {
    const checkResult = await this.checkPutLimitApiService.checkPutLimit(spacerId);
    if (checkResult) {
      if (checkResult['result'] === 'true') {
        return true
      } else {
        // 預け入れ制限されている場合
        alert(this.checkPutLimitApiService.messageLimit)
        return false;
      }
    } else {
      //　預け入れ可否を判定できない場合、預け入れ可能として取り扱う
      return true;
    }
  }

  async detectSpacer(namePrefix: string = 'SPACER') {
    // console.log('DEBUG detectSpacer called');
    // Todo iOSかどうかのチェックは別のサービスで行う。
    // ボタンの２度押しを防ぐため、必要
    // var url = `${environment.apiUrl}key/generate/`;
    // var data = await this._httpjson.postJson(url, { test: "testjson", test2: "test2josn" });
    // return;
//    if (this.is_iOS) {
 //     alert('iOSではWebBluetoothの機能はご利用頂けません。SPACERアプリをダウンロードし、お使い下さい。');
  //      return false;
 //   }
    // const updateMessage = "通信中...";
    try {
      var device = await this.bluetoothRef.bluetooth.requestDevice({
        filters: [
          { services: [this.serviceUuid], namePrefix: namePrefix }
        ]
      });
      // this.selectText = updateMessage;

      var server = await device.gatt.connect();
      var service = await server.getPrimaryService(this.serviceUuid);
      var characteristic = await service.getCharacteristic(
        this.characteristicUuid
      );
      var spacerId = device.name;
    } catch (error) {
      if (!device) {
        return false;
      }
      device.gatt.disconnect();
      alert('Bluetoothの接続で問題が発生しました 再度お試しください');
      window.location.reload();
    }
    // 預け入れ可否判定
    let isUsable = true;
    try {
      isUsable = await this.checkUseLimit(spacerId);
    } catch (e) {
      // 預け入れ制限判定に失敗した場合、預け入れ可能として取り扱う
    }
    if (!isUsable) {
      return false;
    }

    let readdata = this._getReadData(await characteristic.readValue());
    if (
      readdata == 'rwsuccess' ||
      readdata == 'wsuccess' ||
      readdata == 'using'
    ) {
      alert('使用中のSPACERです');
    //   this.selectText = "使いたいSPACERを選択";
      device.gatt.disconnect();
      return false;
    } else if (readdata == 'false') {
      alert('読み込みに失敗しました 再度お試し下さい');
      device.gatt.disconnect();
      window.location.reload();
      return false;
    }
    const random = this._getRandom();
    var md5_message = md5(random + this.uid + readdata + spacerId);
    // console.log(md5_message);
    await this.db
      .object('checkString/' + random)
      .set({ md5: md5_message, timestamp: this._getSpacerTime() });
    try {
      var url = `${environment.apiUrl}key/generate/`;
      var data = await this._httpjson.postJson(url, {
        random: random,
        uid: this.uid,
        key: readdata,
        spacerid: spacerId,
        md5_message: md5_message
      });
      if (!data) {
        device.gatt.disconnect();
        return false;
      }
    } catch (error) {
      alert('サーバーからの処理に失敗しました');
    //   this.selectText = "使いたいSPACERを選択";
      device.gatt.disconnect();
      return false;
    }

    await characteristic.writeValue( new Uint8Array( this._stringToCharCode( '543214723567xxxrw, ' + data['key'] ) ) );
    readdata = this._getReadData(await characteristic.readValue());

    md5_message = md5(random + spacerId + readdata);
    // console.log(md5_message);
    try {
      url = `${environment.apiUrl}key/generateResult/`;
      await this._httpjson.postJson(url, {
        random: random,
        spacerid: spacerId,
        result: readdata,
        md5_message: md5_message
      });
    } catch (error) {
      alert('サーバーからの処理に失敗しました!');
    //   this.selectText = "使いたいSPACERを選択";
      device.gatt.disconnect();
      return false;
    }
    device.gatt.disconnect();
    if (readdata != 'rwsuccess' && readdata != 'doorOpen') {
      alert('読み込みに失敗しました 再度お試し下さい!');
      window.location.reload();
      return false;
    }
    if (readdata == 'doorOpen') {
      alert('SPACERのドアを閉めて下さい。（自動で施錠されます。）');
      window.location.reload();
      return false;
    }
    return true;
  }

  async  openSpacer(restrictSpacer, urlId, paidAt) {

    if (this.is_iOS) {
      alert('iOSではWebBluetoothの機能はご利用頂けません。SPACERアプリをダウンロードし、お使い下さい。');
      return false;
    }

    try {
      if( this.is_restricted ){
        restrictSpacer = 'SPACER';
      }
      var device = await this.bluetoothRef.bluetooth.requestDevice({
        filters: [{ services: [this.serviceUuid], namePrefix: restrictSpacer }]
      });

    //   this.openText = updateMessage;
      var server = await device.gatt.connect();
      var service = await server.getPrimaryService(this.serviceUuid);
      var characteristic = await service.getCharacteristic(
        this.characteristicUuid
      );
      var spacerId = device.name;
    } catch (error) {
      if( this.is_restricted ){
        this.is_restricted = false;
      }else{
        this.is_restricted = true;
        return false;
      }

      if (!device) {
        return false;
      }
      device.gatt.disconnect();
      alert('Bluetoothの接続で問題が発生しました 再度お試しください');
      window.location.reload();
      return false;
    }

    // 預け入れ可否判定は不要。（荷物を取り出すことは許可するべき。そうしないと、ショップ専用ロッカーから商品をとりだせない。）
    // let isUsable = true;
    // try {
    //   isUsable = await this.checkUseLimit(spacerId);
    // } catch (e) {
    //   // 預け入れ制限判定に失敗した場合、預け入れ可能として取り扱う
    // }
    // if (!isUsable) {
    //   return false;
    // }

    // if (spacerId != this.selectedSpacer) {
    //   alert('正しいSpacerIDを選択して下さい');
    // //   this.openText = "カギを開ける";
    //   device.gatt.disconnect();
    //   return;
    // }
    const random = this._getRandom();
    let md5_message = md5(random + this.uid + urlId + spacerId + paidAt);
    await this.db
      .object('checkString/' + random)
      .set({ md5: md5_message, timestamp: this._getSpacerTime() });

    // const parcentage = 100 / 7;
    // let ctr = 1;
    // this.openText =
    //   updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
    try {
      var url = `${environment.apiUrl}key/get`;
      const data = await this._httpjson.postJson(url, {
        random: random,
        uid: this.uid,
        urlkey: urlId,
        spacerid: spacerId,
        paid_at: paidAt,
        md5_message: md5_message
      });
      if (data['message'] === 'expired') {
        // this.is_expired = true;
        // alert('有効期限が過ぎています チケットを購入して下さい');
        // this.openText = "カギを開ける";
        device.gatt.disconnect();
        return false;
      }
    //   ctr++;
    //   this.openText =
    //     updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      await characteristic.writeValue( new Uint8Array( this._stringToCharCode('543214723567xxxw, ' + data['key']) ) );
    //   ctr++;
    //   this.openText =
    //     updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      let readdata = this._getReadData(await characteristic.readValue());
      if (readdata == 'using') {
        await characteristic.writeValue( new Uint8Array( this._stringToCharCode('543214723567xxxw,' + data['key']) ) );
        readdata = this._getReadData(await characteristic.readValue());
      }
    //   ctr++;
    //   this.openText =
    //     updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      md5_message = md5(random + this.uid + urlId + spacerId + readdata);
      url = `${environment.apiUrl}key/getResult/`;
    //   ctr++;
    //   this.openText =
    //     updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      await this._httpjson.postJson(url, {
        random: random,
        uid: this.uid,
        urlkey: urlId,
        spacerid: spacerId,
        result: readdata,
        md5_message: md5_message
      });
    //   ctr++;
    // //   this.openText =
    //     updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      device.gatt.disconnect();
      // ここも治す必要ある？
      if (readdata != 'wsuccess' && readdata != 'rwsuccess') {
        alert('解錠に失敗しました 再度お試し下さい');
        window.location.reload();
        return false;
      }
    // alert('ご利用ありがとうございました');
    //   this.openText = "カギを開ける";
    //   this.is_default = true;
      return true;
    //   window.location.reload();
    } catch (error) {
      alert('サーバーからの処理に失敗しました');
      device.gatt.disconnect();
      return false;
    }
  }

  async openTestSpacer(namePrefix: string = 'SPACER') {
    // console.log('test');
    try {
    // console.log('test2');
      var device = await this.bluetoothRef.bluetooth.requestDevice({
        filters: [{ services: [this.serviceUuid], namePrefix: namePrefix }]
      });

    // console.log('test3');
    //   this.openText = updateMessage;
      var server = await device.gatt.connect();
      var service = await server.getPrimaryService(this.serviceUuid);
      var characteristic = await service.getCharacteristic(
        this.characteristicUuid
      );
      var spacerId = device.name;
    } catch (error) {
      if (!device) {
        return false;
      }
      device.gatt.disconnect();
      alert('Bluetoothの接続で問題が発生しました 再度お試しください');
      window.location.reload();
      return false;
    }

    try {
      await characteristic.writeValue( new Uint8Array( this._stringToCharCode('543214723567xxxw, 191094143189555' ) ) );
    //   ctr++;
    //   this.openText =
    //     updateMessage + String(Math.floor(parcentage * ctr)) + "％完了";
      let readdata = this._getReadData(await characteristic.readValue());
      device.gatt.disconnect();
      // ここも治す必要ある？
      if (readdata != 'wsuccess' && readdata != 'rwsuccess') {
        alert('解錠に失敗しました 再度お試し下さい');
        window.location.reload();
        return false;
      }
    // alert('ご利用ありがとうございました');
    //   this.openText = "カギを開ける";
    //   this.is_default = true;
      return true;
    //   window.location.reload();
    } catch (error) {
      alert('サーバーからの処理に失敗しました');
      device.gatt.disconnect();
      return false;
    }
  }

  private _stringToCharCode(base: string) {
    const arraystring = [];
    for (let i = 0; i < base.length; i++) {
      arraystring.push(base.charCodeAt(i));
    }
    return arraystring;
  }

  private _getRandom() {
    let random = '';
    const c = 'aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ0123456789';
    const cl = c.length;
    for (let i = 0; i < 18; i++) {
      random += c[Math.floor(Math.random() * cl)];
    }
    return random;
  }

  private _getReadData(value: any) {
    const ints = new Int8Array(value.byteLength);
    let readdata = '';
    for (let i = 0; i < ints.length; i++) {
      readdata += String.fromCharCode(value.getInt8(i));
    }
    return readdata;
  }

  private _getSpacerTime() {
    const date = new Date();
    return (
      date.getFullYear() +
      '-' +
      ('0' + Number(date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2) +
      ':' +
      ('0' + date.getSeconds()).slice(-2)
    );
  }

}
