import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MembersRepositoryService } from './members-repository-service';
import { PaymentService } from './payment.service';
import { PhoneNumberAuthenticationService } from './authentication/services/phone-number-authentication-service';
import { PartnerMembersRepositoryImplService } from "./repository-impl/partner-members-repository-impl.service";
import { PartnersRepositoryImplService } from "./repository-impl/partners-repository-impl.service";
import {CalcPriceApiService} from "./calc-price-api.service";
import { SpacersUnitsRepositoryService } from './spacers-units-repository-service';

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  uid: string;
  readonly current_version: string = '2.0';
  readonly fbUsers: string = 'members/';

  constructor(
    private auth: AngularFireAuth,
    private db: AngularFireDatabase,
    private router: Router,
    private phoneNumberAuthService: PhoneNumberAuthenticationService,
    private _members: MembersRepositoryService,
    private _paymentService: PaymentService,
    private _partnerMembersRepositoryImplService: PartnerMembersRepositoryImplService,
    private _partnersRepositoryImplService: PartnersRepositoryImplService,
    private _calcPriceApiService: CalcPriceApiService
  ) { }

  async ngOnInit() {
    // console.log('DEBUG ngOnInit app.component....');
    this.auth.onAuthStateChanged(async user => {
      if (!user) {
        // console.log('DEBUG no user!!!');
        return;
      }
      if (!await this._members.getPhoneNum() && this.phoneNumberAuthService.getPhoneNum()) {
        // console.log('DEBUG onAuthStateChanged before phone num proc ...');
        await this._members.updatePhoneNum( this.phoneNumberAuthService.getPhoneNum() );
        // console.log('DEBUG onAuthStateChanged after phone num proc ...');
      }
      this.uid = user.uid;
      this._calcPriceApiService.uuid = this.uid
      this._paymentService.uid = user.uid;
      await this._paymentService.fetchCreditCard();
      await this._members.checkRepository();
      await this._members.checkOldUrlKey();
      await this._partnerMembersRepositoryImplService.refreshObserver();
      // returnURLのサービス処理
      // リファクタリングのフェーズで下記の処理をmembers-repository-serviceに移行したい。
      const item = this.db.object<any>(this.fbUsers + this.uid).valueChanges();
      item.subscribe( async data => {
        if (!data.version || data.message === undefined) {
          this.db
            .object(this.fbUsers + this.uid)
            .update({ version: this.current_version });
        }
        if (data.version != this.current_version) {
          await this.db
            .object(this.fbUsers + this.uid)
            .update({ version: this.current_version });
        }
        if (data.version === '1.5') {
          await this.auth.signOut().then(success => {
            this.router.navigate(['/menu/auth-providers']);
            window.location.reload();
          });
        }
      });
    });
  }
}
