import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import "rxjs/add/operator/take";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AngularFireAuth, private router: Router) {}

  canActivate(): Observable<boolean> {
    //      return Observable.from(this.auth)
    return this.auth.authState
      .take(1)
      .map(state => !!state)
      .do(authenticated => {
        if (!authenticated) {
          const href = window.location.href;
          const href_array = href.split("/");
          href_array.shift();
          href_array.shift();
          href_array.shift();
          const returnURL = "/" + href_array.join("/");
          var SPACERcheck = returnURL.slice( 2 ) ;
          if (returnURL === "/") {
              this.router.navigateByUrl("/login?returnUrl=" + returnURL);
          } else {
            if ( returnURL.match('tutorial') ) {
              this.router.navigateByUrl("/login?returnUrl=/");
            } else if( SPACERcheck.match('SPACER')) {
              this.router.navigateByUrl("/login?returnUrl=" + SPACERcheck);
            } else {
              this.router.navigateByUrl("/login?returnUrl=" + returnURL);
            }
          }
        }
      });
  }
}
