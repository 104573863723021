import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ReserveCancelApiService } from './reserve-cancel-api.service';
import { SpacerErrorAnnouncer } from './lib/spacer-error-handle/spacer-error-handle.service';
import { Mylocker } from "./entity/mylocker";
import {SendKeyUrl} from "./entity/send-key-url";

@Injectable({
  providedIn: 'root'
})
export class MembersRepositoryService {
  readonly tableName = 'members/';
  uid = '';
  dbpath = '';
  repository;
  repositoryObservable: Observable<any>;
  sendKeysObservable: Observable<any[]>[] = [];

  constructor(private db: AngularFireDatabase,
              private cancelApi: ReserveCancelApiService) { }

  getUID() {
    return this.uid;
  }

  async getNotifications() {
    await this.checkRepository();
    if (!this.repository['notifications']) {
      return [];
    }
    const dataList = [];
    Object.keys(this.repository['notifications']).forEach((key) => {
      Object.keys(this.repository['notifications'][key]).forEach((each) => {
        dataList.push(this.repository['notifications'][key][each]);
      });
    })
    return dataList.reverse();
  }

  async getMylockers() {
    await this.checkRepository();
    this._checkMylocker();
    return this.repository['myLockers'];
  }

  async getMylockerBySpacerid(spacerid: string) {
    await this.checkRepository();
    return (this.repository['myLockers'][spacerid]);
  }

  private async _checkMylockerByUrl(url) {
    let ret = false;
    if (!this.repository['myLockers']) {
      return false;
    }
    Object.keys(this.repository['myLockers']).forEach(async (key) => {
      if (ret) { return; }
      if (this.repository['myLockers'][key]['sendKeyURL'] === url) {
        ret = true;
      }
    });
    return ret;
  }

  async checkOldUrlKey() {
    if (!this.repository['spacers']) {
      return;
    }
    Object.keys(this.repository['spacers']).forEach(async (key) => {
      await this.addMylockerByUrl(this.repository['spacers'][key]);
    });
  }

  async addMylockerByUrl(url) {
    await this.checkRepository();
    if (await this._checkMylockerByUrl(url)) {
      return;
    }
    await new Promise<void>((resolve) => {
      this.db.object('sendKeyURL/' + url).snapshotChanges().pipe(
        map(changes => {
          return changes.payload.val();
        })
      ).subscribe( async (res) => {
        const sendKeyUrl = new SendKeyUrl();
        sendKeyUrl.applyItem(res, url);
        const mylocker = new Mylocker();
        // console.log('DEBUG START res');
        // console.log(res);
        // console.log('DEBUG END res');

        mylocker.applySendKeyURL(sendKeyUrl, url);

        await this.updateMylocker(this.tableName + this.uid + '/myLockers/' + res['device'], mylocker);

        // console.log(mylocker);

        const date = new Date();
        const temp_datetime = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2);
        const datetime =
          temp_datetime +
          ('0' + date.getDate()).slice(-2) +
          ('0' + date.getHours()).slice(-2) +
          ('0' + date.getMinutes()).slice(-2) +
          ('0' + date.getSeconds()).slice(-2) +
          ('00' + date.getMilliseconds()).slice(-3) + '-' + this._getRandom(5);
        const notifications = this.tableName + this.uid + '/notifications/' + temp_datetime + '/' + datetime;
        // console.log(notifications);
        await this.db.object( notifications ).update({
          address: mylocker.address,
          device: mylocker.device,
          sendAt: date.getFullYear() + '/' +
            ('0' + (date.getMonth() + 1)).slice(-2) + '/' +
            ('0' + date.getDate()).slice(-2) + ' ' +
            ('0' + date.getHours()).slice(-2) + ':' +
            ('0' + date.getMinutes()).slice(-2),
          text: '下記のロッカーの鍵の共有を完了しました。 ロッカーのご利用状況はMy Lockerからご確認いただけます。',
          title: mylocker.device + 'の鍵の共有のお知らせ',
          type: 'send-key'
        });
        resolve();
      });
    });

  }
  private _getRandom(num: number) {
    let random = '';
    const c = 'aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ0123456789';
    const cl = c.length;
    for (let i = 0; i < num; i++) {
      random += c[Math.floor(Math.random() * cl)];
    }
    return random;
  }

  private async updateMylocker(pathToMylocker, sourceObject: Mylocker) {
    let startAtForUpdate = null;
    if (sourceObject['startAt']) {
      startAtForUpdate = sourceObject['startAt'];
    }

    const dataForUpdate: any = {
      address: sourceObject.address,
      device: sourceObject.device,
      sendKeyURL: sourceObject.sendKeyURL,
      product: sourceObject.product,
      status: sourceObject.status,
      isCleaning: sourceObject.isCleaning,
      cleaningShopName: sourceObject.cleaningShopName,
      cleaningShopNumber: sourceObject.cleaningShopNumber,
      cleaningURL: sourceObject.cleaningURL,
      startAt: startAtForUpdate,
      prepaid: sourceObject.prepaid,
    };

    // isReservedは、sendKeyURLのhasReservedから設定される。
    // hasReservedは、現在、予約中かどうかの判断であるが、運用の途中から追加したカラムの為、
    // 存在する場合のみ上書きを行う。存在しない場合は、myLockerのisReservedを参照する。
    if (sourceObject.isReserved){
      dataForUpdate.isReserved = sourceObject.isReserved;
    }

    this.db.object(pathToMylocker).update(dataForUpdate);
  }

  async getSpacer(spacerid: string) {
    // console.log('DEBUG BEFORE checkRepository');
    // console.log('DEBUG repo myLocker spacerid 1 ...');
    // console.log(this.repository['myLockers'][spacerid]);
    await this.checkRepository();
    // console.log('DEBUG BEFORE checkRepository');
    // console.log('DEBUG repo myLocker spacerid 2 ...');
    // console.log(this.repository['myLockers'][spacerid]);
    return (this.repository['myLockers'][spacerid]);
  }

  private async _checkMylocker() {
    if (!this.repository['myLockers']) {
      return;
    }
    Object.keys(this.repository['myLockers']).forEach( async (key) => {
      await new Promise<void>((resolve) => {
        // マイロッカーから↓sendKeysObservableを直接読めばよさそう
        this.sendKeysObservable[key] = this.db.object('sendKeyURL/' + this.repository['myLockers'][key]['sendKeyURL'])
          .snapshotChanges().pipe(
          map(changes => {
            return changes.payload.val();
          })
        ).subscribe( async res => {
          // res は sendKeyURL 1件分のデータである
          if (!res) {
            this.db.object(this.tableName + this.uid + '/myLockers/' + key).remove();
          } else {
            // console.log('DEBUG B022');
            // console.log('DEBUG START res');
            // console.log(res);
            // console.log('DEBUG END res');

            const url = this.repository['myLockers'][key]['sendKeyURL'];
            const sendKeyUrl = new SendKeyUrl();
            sendKeyUrl.applyItem(res, url);
            if (sendKeyUrl.isReserved === 'true') {
              if (sendKeyUrl.product) {
                // 予約利用であり、商品代金支払い済の場合
              } else {
                // 予約利用であり、かつ支払い済であれば、予約キャンセルAPIを実行する必要がある。
                // これにより、マイロッカーから表示されなくなる。
                const history = await this.getCurrentHistoryBySpacerid(key);
                if (history) {
                  // history には、history の paidAt が含まれている
                  if (history === sendKeyUrl['paidAt']) {
                    this.cancelApi.id = key;
                    this.cancelApi.reserveCancel().then(result => {
                    }).catch(error => {
                      new SpacerErrorAnnouncer('予約キャンセル処理で問題が発生しました。').setSpacerId(key).announce();
                    });
                  }
                }
              }
            }
            const mylocker = new Mylocker();
            // console.log('DEBUG mylocker....');
            // console.log(mylocker);
            mylocker.applySendKeyURL(sendKeyUrl, url);
            // console.log('DEBUG mylocker after applySendKeyURL....');
            // console.log(mylocker);

            mylocker.applySendKeyURL(sendKeyUrl, url);
            // console.log('DEBUG BX001');
            // console.log('DEBUG START mylocker');
            // console.log(mylocker);
            // console.log('DEBUG END mylocker');
            await this.updateMylocker(this.tableName + this.uid + '/myLockers/' + res['device'] , mylocker);

            // console.log('DEBUG BX002');
            // console.log('DEBUG START mylocker');
            // console.log(mylocker);
            // console.log('DEBUG END mylocker');

          }
          resolve();
        });
      });
    });
  }

  async getCurrentHistoryBySpacerid(spacerid: string) {
    await this.checkRepository();
    let ret = ''
    if (!this.repository['history']) {
      return ret;
    }
    Object.keys( this.repository['history'] ).forEach((key) => {
      if (this.repository['history'][key]['device'] === spacerid) {
        const paidAt = Date.parse( this.repository['history'][key]['paidAt'] );
        const current_time = new Date().getTime();
        if (paidAt > (current_time - (1000 * 60 * 10))) {
          ret = this.repository['history'][key]['paidAt'];
        }
      }
    })
    return ret;
  }

  async getPhoneNum() {
    await this.checkRepository();
    if (!this.repository['phone']) {
      return '';
    }
    return this.repository['phone'];
  }

  async getH12() {
    await this.checkRepository();
    if (!this.repository['H12']) {
      return '';
    }
    return this.repository['H12'];
  }

  async getEmail() {
    await this.checkRepository();
    if (!this.repository['email']) {
      return '';
    }
    return this.repository['email'];
  }

  async getName() {
    await this.checkRepository();
    if (!this.repository['name']) {
      return '';
    }
    return this.repository['name'];
  }

  async updatePhoneNum(phone: string) {
    this.uid = firebase.auth().currentUser.uid;
    await this.db.object(this.tableName + this.uid ).update({ phone: phone });
  }

  deleteDataOfUser() {
    this.db.object<any>(this.tableName + this.uid).remove();
  }

  isRepository() {
    if (!this.repository) {
      return false;
    } else {
      return true;
    }
  }


  async checkRepository() {
    // console.log('DEBUG checkRepository 001');
    if (!this.repository) {
      if (!this.uid) {
        this.uid = firebase.auth().currentUser.uid;
      }
      await this.initialize(this.uid);
    } else { // else for debug
      // console.log('DEBUG START this.repository');
      // console.log(this.repository);
      // console.log('DEBUG END this.repository');
    }
  }

  async initialize(uid): Promise<void> {
    this.uid = uid;
    this.dbpath = `${this.tableName}${this.uid}`;
    this.repositoryObservable = this.db.object(this.dbpath).snapshotChanges();

    await new Promise<void>((resolve) => {
      this.repositoryObservable.pipe(
        map(changes => {
          return changes.payload.val();
        })
      ).subscribe(res => {
        this.repository = res;
        // console.log(res);
        resolve();
      })
    });
    await this._checkMylocker();
  }
}
