import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as firebase from 'firebase';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpJsonService {
  constructor(private http: HttpClient) {}

  private async createHeaderWithAuthorizationToken() {
    const token = await firebase.auth().currentUser.getIdToken()
    let header = new HttpHeaders()
    header = header.set('authorization', `Bearer ${token}`);
    return header
  }

  async getJson(url: string) {
    let header = await this.createHeaderWithAuthorizationToken();
    const response = await this.http.get(url, { headers: header }).toPromise();
    if (response) {
      return response;
    } else {
      return;
    }
  }

  /*  getJson(url: string) {
    return this._http.get(url).map(res => res.json());
  }*/

  async getJsonByPost(url: string, data) {
    const headers = await this.createHeaderWithAuthorizationToken();
    return await this.http
      .post(url, data, { headers: headers })
      .toPromise()
      .catch(err => {
        // console.error('An error occurred:', err.error);
        throw err;
      });
  }

  async postJson(url: string, json: Object, skipError: boolean = true) {
    const _json = JSON.stringify(json);
    let headers = await this.createHeaderWithAuthorizationToken();
    headers = headers.set('Content-Type', 'application/json');
    return await this.http
      .post( url, _json, { headers: headers })
      .toPromise()
      .catch(err => {
        console.error('An error occurred:', err.error);
        if(!skipError){
          return err.error;
        }
      });
  }

  async postFormUrlEncoded(url: string, parameter: Object) {
    const _json = parameter;
    let headers = await this.createHeaderWithAuthorizationToken();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return await this.http
      .post( url, _json, { headers: headers })
      .toPromise()
      .catch(err => {
        console.error('An error occurred:', err.error);
      });
  }
}
