import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AboutCancelPriceComponent,
  AboutKeyShareingComponent, AboutPrepaidComponent,
  BeforeRemoveCardComponent, CautionBeforeOpenComponent,
  CopiedKeyURLComponent, ManualCancelCautionComponent,
  OpenedLockerComponent, RequireCardComponent,
  ReserveCautionComponent, SelectPaymentMethodComponent,
  CleaningDialog, PickupDialog, ViewQRCodeComponent, PrepaidDialogComponent,
  AboutPaymentComponent,
} from './dialog-components.component';
@Injectable({
  providedIn: 'root'
})
export class DialogService {
  common_setting = {
    panelClass: 'dialog',
    width: '80% fit-content',
    height: '65%',
    data: {
      url: '',
      cardSeq: '',
      device: '',
      cleaningShopName: '',
      cleaningShopNumber: '',
      collaborationName: '',
      collaborationPhoneNumber: '',
    }
  };

  constructor( public dialog: MatDialog, private router: Router ) {}

  aboutKeyShareingDialog(): void {
    const dialogRef = this.dialog.open( AboutKeyShareingComponent, this.common_setting );

    dialogRef.afterClosed().subscribe(result => {
      // // console.log('The dialog was closed');
      // this.fullname = result;
    });
  }

  aboutPrepaidDialog(): void {
    const setting = Object.assign({}, this.common_setting);
    setting.height = '55%';
    const dialogRef = this.dialog.open( AboutPrepaidComponent, setting );
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  aboutPaymentDialog(): void {
    const setting = Object.assign({}, this.common_setting);
    const dialogRef = this.dialog.open( AboutPaymentComponent, setting );
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  cleaning(url, _device, cleaningShopName, cleaningShopNumber): void {
    //const dialogRef = this.dialog.open( CleaningDialog, this.common_setting );
    const setting = this.common_setting;
    setting.data.url = url;
    setting.data.device = _device;
    setting.data.cleaningShopName = cleaningShopName;
    setting.data.cleaningShopNumber = cleaningShopNumber;
    const dialogRef = this.dialog.open(CleaningDialog, setting);

    dialogRef.afterClosed().subscribe(result => {
      // // console.log('The dialog was closed');
      // this.fullname = result;
    });
  }

  pickup(url, device, collaborationName, collaborationPhoneNumber): void {
    const setting = this.common_setting;
    setting.height = 'fit-content'
    setting.data.url = url;
    setting.data.device = device;
    setting.data.collaborationName = collaborationName;
    setting.data.collaborationPhoneNumber = collaborationPhoneNumber;
    const dialogRef = this.dialog.open(PickupDialog, setting);

    dialogRef.afterClosed().subscribe(result => {
      // // console.log('The dialog was closed');
      // this.fullname = result;
    });
  }

  viewQRCodeDialog(targetUrl): void {
    const setting = this.common_setting;
    setting.data.url = targetUrl;
    const dialogRef = this.dialog.open( ViewQRCodeComponent, this.common_setting );
    dialogRef.afterClosed().subscribe(result => {
      // // console.log('The dialog was closed');
      // this.fullname = result;
    });
  }

  copiedKeyURLDialog(): void {
    const dialogRef = this.dialog.open( CopiedKeyURLComponent, this.common_setting );
    dialogRef.afterClosed().subscribe(result => {
      // // console.log('The dialog was closed');
      // this.fullname = result;
    });
  }

  openedLockerDialog(): void {
    const dialogRef = this.dialog.open( OpenedLockerComponent, this.common_setting );

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigateByUrl('/mylockers/kept');
    });
  }
  reserveCautionDialog(): void {
    const dialogRef = this.dialog.open( ReserveCautionComponent, this.common_setting );

    dialogRef.afterClosed().subscribe(result => {
      // this.router.navigateByUrl('/put/kept');
    });
  }

  selectPaymentMethodDialog(): void {
    const dialogRef = this.dialog.open( SelectPaymentMethodComponent, this.common_setting );

    dialogRef.afterClosed().subscribe(result => {
      // this.router.navigateByUrl('/put/kept');
    });
  }

  cautionBeforeOpen(url): void {
    const setting = this.common_setting;
    setting.data.url = url;
    const dialogRef = this.dialog.open( CautionBeforeOpenComponent, setting );

    dialogRef.afterClosed().subscribe(result => {
      // this.router.navigateByUrl('/put/kept');
    });
  }

  manualCancelCautionComponent(): void {
    const dialogRef = this.dialog.open( ManualCancelCautionComponent, this.common_setting );

    dialogRef.afterClosed().subscribe(result => {
      // this.router.navigateByUrl('/put/kept');
    });
  }

  requireCardComponent(): void {
    const dialogRef = this.dialog.open( RequireCardComponent, this.common_setting );

    dialogRef.afterClosed().subscribe(result => {
      // this.router.navigateByUrl('/put/kept');
    });
  }

  aboutCancelPriceComponent(): void {
    const dialogRef = this.dialog.open( AboutCancelPriceComponent, this.common_setting );

    dialogRef.afterClosed().subscribe(result => {
      // this.router.navigateByUrl('/put/kept');
    });
  }

  beforeRemoveCardComponent(cardSeq): void {
    const setting = this.common_setting;
    setting.data.cardSeq = cardSeq;
    const dialogRef = this.dialog.open( BeforeRemoveCardComponent, setting );

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  prepaidDialogComponent(device: string, url: string): void {
    const setting = Object.assign({}, this.common_setting);
    setting.data.device = device;
    setting.data.url = url;
    const dialogRef = this.dialog.open( PrepaidDialogComponent, this.common_setting );

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
