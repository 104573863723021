<div id="home_main">
  <div [ngClass]="unlockableSpacerNum ? '' : 'displayNone'">
    <p class="select-spacer-message">あなたが開けられるSPACER</p>
    <select class="theme-select-btn" (change)="changeSpacer($event)">
      <option value="default">SPACERを選択</option>
      <option *ngFor="let spacer of spacers | async" [value]="spacer.url">
        {{ spacer.key }}
      </option>
    </select>
    <div *ngIf="!is_default; then spacerContent">###</div>
    <ng-template #spacerContent>
      <div id="content">
        <p id="main-spacer-name">{{ selectedSpacer }}</p>
        <p id="main-spacer-name-below">※2時間無料</p>

        <div class="icons">
          <a
            href="http://maps.google.com/maps?q={{ data.lat }},{{
              data.lng
            }}&zoom=14&mode=w"
            target="blank"
          >
            <img src="assets/images/map.svg" height="60px" />
            <!--            <mat-icon aria-label="Side nav toggle icon" class="md-50" >place</mat-icon>-->
          </a>
          <p class="icons-bottom-text">マップ</p>
        </div>

        <div class="icons">
          <button id="url-key" [text-copy]="urlKey">
            <img src="assets/images/share.svg" height="60px" />
          </button>
          <p class="icons-bottom-text">カギを共有</p>
        </div>

        <div class="icons">
          <span (click)="openDialog()">
            <img src="assets/images/ticket.svg" height="60px" />
          </span>
          <p class="icons-bottom-text">チケット購入</p>
        </div>

        <div *ngIf="is_cleaning; then cleaning">###</div>
        <ng-template #cleaning>
          <div class="icons">
            <span (click)="cleaningDialog()">
              <img src="assets/images/cleaning.svg" height="60px" />
            </span>
            <p class="icons-bottom-text">クリーニングに出す</p>
          </div>
        </ng-template>

        <div *ngIf="is_apart; then apart">###</div>
        <ng-template #apart>
          <div class="icons">
            <span (click)="apartDialog()">
              <img src="assets/images/apart.svg" height="60px" />
            </span>
            <p class="icons-bottom-text">集合住宅</p>
          </div>
        </ng-template>

        <div *ngIf="is_laundry_pack; then laundryPack">###</div>
        <ng-template #laundryPack>
          <div class="icons">
            <span (click)="laundryPackDialog()">
              <img src="assets/images/laundry_pack.svg" height="60px" />
              <p class="icons-bottom-text">ランドリーパック</p>
            </span>
          </div>
        </ng-template>

        <div *ngIf="is_crowdwash; then crowdwash">###</div>
        <ng-template #crowdwash>
          <div class="icons">
            <span (click)="crowdWashDialog()">
              <img src="assets/images/crowd_wash.svg" height="60px" />
              <p class="icons-bottom-text">洗濯たのメール</p>
            </span>
          </div>
        </ng-template>

        <div *ngIf="data.status == 'free'; then cntStop; else cntTimer">
          ###
        </div>
        <ng-template #cntStop>
          <div class="theme-btn">
            <p>カウントストップ</p>
            <p>（無料で開けられます）</p>
          </div>
        </ng-template>
        <ng-template #cntTimer>
          <div class="theme-btn">
            <app-count-down-timer
              (onTimeOver)="timeOver()"
              [(inputDate)]="expired"
            ></app-count-down-timer>
            <p id="timer-text-below">{{ ticketExplainText }}</p>
          </div>
          <div *ngIf="is_dailyTicket; then dailyTicket; else normalTicket">
            ###
          </div>
          <ng-template #dailyTicket>
            <button class="theme-extend-btn" (click)="buyDailyTicket()">
              {{ ticketText }}
            </button>
          </ng-template>
          <ng-template #normalTicket>
            <button class="theme-extend-btn" (click)="extendExpiredTime()">
              {{ ticketText }}
            </button>
          </ng-template>
        </ng-template>
        <div *ngIf="is_expired; then openBtn; else expiredBtn">###</div>
        <ng-template #openBtn>
          <button class="open-btn open-btn-expired" (click)="openSpacer()">
            {{ openText }}
          </button>
        </ng-template>
        <ng-template #expiredBtn>
          <button
            class="open-btn"
            [ngClass]="is_clicked ? 'open-close-btn-clicked' : ''"
            (click)="openSpacer()"
          >
            {{ openText }}
          </button>
        </ng-template>
      </div>
    </ng-template>
  </div>

  <br />
  <mat-tab-group dynamicHeight>
    <mat-tab label="新型SPACER">
      <div id="new-spacer">
        <br /><br />
        <button
          id="close-btn"
          (click)="selectSpacer()"
          [ngClass]="is_clicked ? 'open-close-btn-clicked' : ''"
        >
          使いたいSPACERを選択
        </button>
        <p class="SPACERBluetooth">
          選択したSPACERがBluetooth接続（ペア設定）した後にカギが開き利用可能になります。<br />また、SPACERは一度扉を閉めると自動で施錠されます。
        </p>
      </div>
    </mat-tab>
    <mat-tab label="旧型SPACER">
      <div id="new-spacer">
        <p class="new-spacer-message">荷物を入れて扉を閉めたら</p>
        <div class="triangle"></div>
        <br />
        <button
          id="close-btn"
          (click)="findSpacer()"
          [ngClass]="is_clicked ? 'open-close-btn-clicked' : ''"
        >
          {{ findText }}
        </button>
        <p class="SPACERBluetooth">
          選択したSPACERがBluetooth接続（ペア設定）した後にカギが閉まります。
        </p>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
