import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kotlin-view',
  templateUrl: './kotlin-view.component.html',
  styleUrls: ['./kotlin-view.component.scss']
})
export class KotlinViewComponent implements OnInit {
  @Input() customURL;

  constructor() { }

  ngOnInit() {
  }

  onConfirm() {
    window.location.href = this.customURL;
  }
}
