import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpParams } from "@angular/common/http";
import { HttpJsonService } from "../http-json.service";
import { Observable } from "rxjs/Observable";

@Component({
  selector: "crowd-wash-dialog",
  templateUrl: "./crowd-wash-dialog.html",
  styleUrls: ["./crowd-wash-dialog.scss"]
})
export class CrowdWashDialog {
  spacerid: string;
  uid: string;
  matches: Observable<any[]>;
  is_selected = false;
  match_selected: string;
  dsp_btn: string;
  target_name: string;

  constructor(
    public dialogRef: MatDialogRef<CrowdWashDialog>,
    private _httpjson: HttpJsonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.spacerid = data.spacerid;
    this.uid = data.uid;
    this.matches = data.matches;
  }

  async request() {
    if (this.match_selected === undefined) {
      alert("マッチング情報をご選択下さい。");
      return;
    }
    if (this.dsp_btn === "依頼する") {
      alert("依頼します。\n マッチングID：" + this.match_selected);
    } else {
      alert("洗濯完了を報告します。\n マッチングID：" + this.match_selected);
    }

    const url = "https://api.spacer.co.jp/crowdwash/update";
    let params = new HttpParams();
    params = params.append("uid", this.uid);
    params = params.append("spacerid", this.spacerid);
    params = params.append("match_id", this.match_selected);

    const res = await this._httpjson.getJsonByPost(url, params);

    if (res) {
      const result = res["result"];
      if (result === "accepted") {
        alert("承りました。");
      } else if (result === "invalid_param") {
        alert("パラメーターが不正です。");
      } else if (result === "none_spacer") {
        alert("SPACERのIDが不正です。");
      } else if (result === "none_member") {
        alert("IDの連携が出来ていません。");
      } else if (result === "none_target_member") {
        alert("マッチング相手のID連携が出来ていません。");
      } else if (result === "none_target_member") {
        alert("マッチング相手のID連携が出来ていません。");
      } else if (result === "deny") {
        alert("サーバーエラーです。");
      } else {
        alert("レスポンスエラーです。");
      }
      this.dialogRef.close();
    }
  }

  changeItem($event) {
    if ($event.target.value === "") {
      return;
    }
    if ($event.target.value === "default") {
      this.is_selected = false;
      return;
    }
    if ($event.target.value === undefined) {
      return;
    }
    this.is_selected = true;
    this.match_selected = $event.target.value;
    this.matches.forEach(test => {
      test.forEach(test2 => {
        if (this.match_selected === test2.key) {
          if (test2.val.is_client === "true") {
            this.dsp_btn = "依頼する";
          } else {
            this.dsp_btn = "洗濯完了を報告する";
          }
        }
      });
    });
  }
}
