import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { PhoneNumberAuthenticationService } from '../authentication/services/phone-number-authentication-service';
import {Router} from '@angular/router';
import { HttpJsonService } from '../http-json.service';
import { environment } from '../../environments/environment';
import { MembersRepositoryService } from '../members-repository-service';

@Component({
  selector: 'app-login',
  templateUrl: './auth-providers.component.html',
  styleUrls: ['./auth-providers.component.scss']
})
/**
 * かつて Google や Facebook で認証していたユーザーを
 * 電話番号認証へ移行する。
 */
export class AuthProvidersComponent implements OnInit {
  telephoneNumber = '';
  authenticatedProviders = [];

  /**
   * ユーザーの名前
   */
  name;

  /**
   * ユーザーのメールアドレス
   */
  email;

  /**
   * 用途不明。おそらく2019年頃の仕様です。
   */
  h12;

  /**
   * 旧チケット返金額。
   * 2019年頃の仕様と思われます。ロッカーの利用にチケットを利用していたらしいです。
   */
  amount;

  /**
   * 認証プロバイダ名と認証プロバイダオブジェクトの関連付け
   */
  authProviderIdMap = {
    'google.com': new firebase.auth.GoogleAuthProvider(),
    'facebook.com': new firebase.auth.FacebookAuthProvider()
  };
  authProviderNameMap = {
    Google: 'google.com',
    Facebook: 'facebook.com'
  };
  authProviderIdNameMap = {
    'google.com': 'Google',
    'facebook.com': 'Facebook',
    phone: '電話番号'
  };

  constructor(
    private auth: AngularFireAuth,
    private phoneNumberAuthService: PhoneNumberAuthenticationService,
    private _httpjson: HttpJsonService,
    private _members: MembersRepositoryService,
    private router: Router
  ) {
    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.authenticatedProviders = user.providerData.map(
          providerDatum => providerDatum.providerId
        );
      }
    });
  }

  async ngOnInit() {
    this.name = await this._members.getName();
    this.email = await this._members.getEmail();
    this.h12 = await this._members.getH12();
    if (this.h12) {
      this.amount = Number(this.h12) * 240;
    }

  }

  // TODO DELETE (呼び出し元なし)
  convertProviderIdToName(providerId: string) {
    return this.authProviderIdNameMap[providerId];
  }

  // 認証プロバイダを追加するためのボタンをクリックしたときに呼び出される。
  addAuthProvider(providerId: string) {
    const user = this.auth.currentUser;
    if (user && providerId) {
      const providerObj = this.authProviderIdMap[providerId];
      if (providerObj) {
        this.linkWithPopup(user, providerObj)
          .then(() => {})
          .catch(error => {
            // console.log(error);
          });
      }
    }
  }

  /**
   * 認証プロバイダとの関連付けを行います。ポップアップ表示を伴います。
   * @param user
   * @param providerObj 認証プロバイダオブジェクト。authProviderIdMap の値を利用可能。
   */
  async linkWithPopup(user, providerObj) {
    let linkError;
    let linkResult;
    const phoneUser = firebase.auth().currentUser;
    try {
      // 認証プロバイダの認証画面をポップアップ表示する
      linkResult = await firebase.auth().currentUser.linkWithPopup(providerObj);
    } catch (error) {
      // console.log('ERROR in linkWithPopup 01');
      linkError = error;
    }

    if (linkResult) {
      // 他の認証プロバイダ(GoogleやFacebook)との関連付けに成功した場合
      return;
    }

    if (linkError) {
      // // console.log(linkError);
      if (linkError.code === 'auth/credential-already-in-use') {
        // すでに認証プロバイダによる認証が行われていた場合(過去にGoogleやFacebookでログイン済の場合)は、
        // 認証プロバイダによる認証をメインのユーザーとする。
        // メインのユーザーに対して、電話番号認証を関連付ける。
        await firebase.auth().signInWithCredential(linkError.credential);
        // console.log('Google:' + firebase.auth().currentUser.uid);
        const url = `${environment.apiUrl}auth/move/`;
        const data = await this._httpjson.postJson(url, {
          phone: phoneUser.uid,
          social: firebase.auth().currentUser.uid
        });
        if (data['result'] === 'success') {
          await this.auth.signOut().then(success => {
            this.router.navigate(['/']);
            window.location.reload();
          });
        }
      } else {
        // console.log(linkError.message);
        // console.log(linkError.code);
      }
    }
  }
}
