import {Component, Input, OnInit} from '@angular/core';
import {IBoxForMyBoxes} from "../myboxes/myboxes.component";

@Component({
  selector: 'app-myboxes-box-with-product',
  templateUrl: './myboxes-box-with-product.component.html',
  styleUrls: [
    '../myboxes-box/myboxes-box.component.scss',
    './myboxes-box-with-product.component.scss',
  ]
})
export class MyboxesBoxWithProductComponent implements OnInit {
  @Input() box: IBoxForMyBoxes;
  constructor() { }

  ngOnInit(): void {
  }

}
