import { Injectable } from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/database";
import {map} from "rxjs/operators";
import * as firebase from "firebase";
import {PartnerShopMember} from "./entity/partner-shop-member";

@Injectable({
  providedIn: 'root'
})
export class PartnerShopMembersRepositoryService {
  readonly tableName = `partnerShopMembers/`
  currentPartnerShopMemberItem: any;
  currentPartnerShopMemberEntity: PartnerShopMember;
  currentUserIsShopMember = false;
  currentUserCanSetPrice = false;
  constructor(private db: AngularFireDatabase) {
  }

  async fetchCurrentUser() {
    const uid = firebase.auth().currentUser.uid;
    const dbPath = `${this.tableName}${uid}`;

    await new Promise<void>((resolve) => {
      this.db.object(dbPath).snapshotChanges().pipe(
        map(changes => {
          return changes.payload.val();
        })
      ).subscribe(res => {
        this.currentPartnerShopMemberItem = res;
        if (res) {
          this.currentPartnerShopMemberEntity = new PartnerShopMember();
          this.currentPartnerShopMemberEntity.applyItem(this.currentPartnerShopMemberItem);
          this.currentUserIsShopMember = true;
          this.currentUserCanSetPrice = this.currentPartnerShopMemberEntity.canSetPrice();
        } else {
          this.currentUserIsShopMember = false;
        }


        resolve();
      })
    });
  }
}
