import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { SpacerWebappAuthenticationService } from './spacer-webapp-authentication-service';
import { SpacerErrorHandleService } from '../../lib/spacer-error-handle/spacer-error-handle.service';
import { MembersRepositoryService } from '../../members-repository-service';

@Injectable({
  providedIn: 'root'
})
/**
 * 退会処理のための Service です。
 */
export class UnsubscribeService {
  credential: firebase.auth.AuthCredential;
  constructor(
    private spacerWebappAuthenticationService: SpacerWebappAuthenticationService,
    private errorHandler: SpacerErrorHandleService,
    private repository: MembersRepositoryService
  ) {}

  // FIXME 優先度低 退会処理に失敗しています。原因は特定できていません。
  async unsubscribe() {
    const currentUser = firebase.auth().currentUser;
    await currentUser.delete();
    await this.repository.deleteDataOfUser();
  }
}
