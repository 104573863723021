<div id="content">
  <h2>クレジットカード情報の変更</h2>
  <div [hidden]="viewMode.isNotFetched()">
    <div class="select-card credit-card-view">
      <div [hidden]="viewMode.hasCard()">
        現在、登録されている情報はありません。ご利用にはクレジットカードの登録が必要です。
      </div>
      <div *ngIf="viewMode.hasCard()">
        現在登録している決済方法
        <div class="text-area">
          <div>クレジットカード</div>
          <div>{{ currentCard?.cardNo }}</div>
          <div>{{ cardExpire(currentCard?.expire) }}</div>
        </div>

        <div [hidden]="viewMode.shouldNotShowFormOpener()">
          <button (click)="openForm($event)" class="large-black">
            変更する
          </button>
          <button (click)="confirmRemoveCard($event)" class="large-white" [hidden]="viewMode.hasNoCard()" >削除する</button>
        </div>
    </div>
    <div class="payment-form" [hidden]="viewMode.shouldNotShowForm()">
      <div class="form-error-message" *ngIf="errorMessages.length">
        <mat-error *ngFor="let msg of errorMessages">{{ msg }}</mat-error>
      </div>
      <div class="new-card">
        <label>新しいカードを登録する</label>
        <mat-form-field class="cardno input-item">
          <mat-label>カード番号</mat-label>
          <input
            matInput
            pattern="^[0-9]+$"
            maxlength="16"
            placeholder="カード番号"
            [(ngModel)]="formData.cardno"
          />
          <mat-hint
            >ハイフンで区切らないで、番号のみを入力してください。</mat-hint
          >
        </mat-form-field>
        <mat-form-field class="expire input-item">
          <mat-label>有効期限(月)</mat-label>
          <mat-select [(ngModel)]="formData.expireM">
            <mat-option *ngFor="let month of expireMonth" [value]="month.value">
              {{ month.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="expire input-item">
          <mat-label>有効期限(年)</mat-label>
          <mat-select [(ngModel)]="formData.expireY">
            <mat-option *ngFor="let year of expireYear" [value]="year.value">
              {{ year.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="input-item">
          <mat-label>カード名義</mat-label>
          <input
            matInput
            placeholder="カード名義"
            [(ngModel)]="formData.name"
          />
        </mat-form-field>
        <mat-form-field class="input-item">
          <mat-label>セキュリティコード</mat-label>
          <input
            matInput
            pattern="^[0-9]+$"
            maxlength="4"
            placeholder="セキュリティコード"
            [(ngModel)]="formData.code"
          />
        </mat-form-field>
      </div>
      <button class="large-black" (click)="registerCard($event)">
        変更する
      </button>
      <button class="large-white" (click)="cancel($event)">戻る</button>
    </div>
    </div>
  </div>
</div>
<app-global-menu-component></app-global-menu-component>
