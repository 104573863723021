import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DialogService } from '../dialogs/dialog.service';
import { ReserveCancelApiService } from '../reserve-cancel-api.service';
import { PaymentService } from '../payment.service';
import { PrepaidService } from '../prepaid.service';
import { HttpClient } from '@angular/common/http';
import { MembersRepositoryService } from '../members-repository-service';
import { environment } from '../../environments/environment';
import { CalcPriceApiService, CalcSingleResult } from '../calc-price-api.service';
import { Overlay } from '@angular/cdk/overlay';
import { MatSpinner } from '@angular/material/progress-spinner';
import { ComponentPortal } from '@angular/cdk/portal';
import { PaymentAlertDialog } from '../payment/payment-alert-dialog';
import { MatDialog } from '@angular/material/dialog';
import { UserOperationLoggerService } from '../user-operation-logger.service';

@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss']
})
export class ConfirmPaymentComponent implements OnInit {
  private _routeId;
  private _routeAction;
  private _backUrlMap;
  private _spacer;
  private _calcSingleResult: CalcSingleResult;
  private _isPrepaid = false;
  private viewName;
  errorInfo: string = null;
  status: string = null;

  // 一時的なエラーメッセージ。適切な文言に修正する必要あり。
  private _commonErrMessage = 'エラーが発生しました。';
  private _errorMessageForUser: string;

  address = '';
  startTime = ''
  endTime = ''
  amountTime = '';
  productDesc = null;
  senderName = null;
  usePrice = null;
  reservedPrice = null;
  productPrice = null;
  totalPrice = null;
  payedPrice = 0;
  selectCarrear = '';

  // ローディング中であることを表すオブジェクト
  overlayRef = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });

  constructor(
    private route: ActivatedRoute, private router: Router,
    private cancelApi: ReserveCancelApiService,
    private dialogService: DialogService,
    private _member: MembersRepositoryService,
    private paymentService: PaymentService,
    private calcPriceApiService: CalcPriceApiService,
    private prepaidService: PrepaidService,
    private overlay: Overlay,
    public dialog: MatDialog,
    private userOpeLogger: UserOperationLoggerService,
    private el: ElementRef,
    private httpClient: HttpClient) {

    this._backUrlMap = {
      'use': 'mylockers',
      'reserved': 'mylockers', // 予約した時点では、決済は発生しない。
      'manualCanceled': 'mylockers',
      'autoCanceled': 'mylockers',
      'prepaid': 'mylockers'
    }
    // キャリア決済完了時 Spacer-Server-APIからのリダイレクト時の処理
    // statusとerrorInfoをURLパラメータから取得
    const cb = this.route.snapshot.queryParams.cb;
    const status = this.route.snapshot.queryParams.status;
    const errorInfo = this.route.snapshot.queryParams.errorinfo;
    if (typeof cb !== "undefined" && typeof status !== "undefined") {
      this.status = status;
      if (typeof errorInfo !== "undefined") {
        this.errorInfo = errorInfo;
      }
    }
  }

  // async をつければ、ngOnInit が終わってから画面を表示できるかもしれないので、実験中
  async ngOnInit() {
    this.viewName = this.el.nativeElement.querySelectorAll('h2')[0].innerText;
    // キャリア決済完了時 Spacer-Server-APIからのリダイレクト時の処理
    // console.log(`DEBUG ngOnInit this.status : ${this.status}`);
    if (this.status) {
      this.onFinichedPaymentForCarrier();
    }

    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    this.route.paramMap.subscribe((params: ParamMap) => {
      this._routeId = params.get('id');
      this._routeAction = params.get('action');
    });

    // fetchCreditCard の呼び出し後、paymentService にはログインユーザの UID がセットされる。;
    await this.paymentService.fetchCreditCard();
    this._spacer = await this._member.getMylockerBySpacerid(`SPACER${this.deviceNum}`);
    this._calcSingleResult = await this.calcPriceApiService.calcSingleFromMylocker(`SPACER${this.deviceNum}`);
    this._isPrepaid = this.lockerUsage === 'prepaid';

    this.setLockerData();

    if (this._isPrepaid) {
      await this.setPrepaidData();
    } else {
      await this.setTakeoutData();
    }

    this.paymentService.spacerid = `SPACER${this.deviceNum}`;
    this.overlayRef.detach();

    // 決済不要にも関わらず、決済画面へ遷移してしまった場合の対応。
    // 決済完了後の画面へリダイレクトする。
    if (!this._calcSingleResult.shouldPay) {
      const msgCancelError = `キャンセル処理でエラーが発生致しました。お問い合わせ窓口までロッカー番号をお伝え下さい。\nロッカー番号: SPACER${this.deviceNum}`;
      const msgCancelErrorForDeveloper = `cancel error in ${this.lockerUsage}`;
      let nextUrl;
      switch (this._routeAction) {
        case 'manualCanceled': // 手動キャンセルの場合
          this.cancelApi.id = `SPACER${this.deviceNum}`;
          try {
            const cancelResult = await this.cancelApi.reserveCancel();
            this.handleNull(cancelResult, msgCancelError, msgCancelErrorForDeveloper);
          } catch (error) {
            // 決済は完了したが、予約キャンセル処理に失敗した場合。
            // マイロッカー上にデータが残っているはずなので、運用でのカバーが必要。
            this.throwErrorAndSetMessageForUser(msgCancelError, msgCancelErrorForDeveloper);
          } finally {
            // 決済完了画面へ遷移させる
            nextUrl = 'thankyou/afterManualCanceled';
          }
          this.router.navigateByUrl(nextUrl);
          break;
        case 'autoCanceled': // 自動キャンセルの代金を支払う場合
          // 以下のロジックは 手動キャンセルの場合 と同等だが、今後の仕様変更に備えて、分けて記載してる
          this.cancelApi.id = `SPACER${this.deviceNum}`;
          try {
            const cancelResult = await this.cancelApi.reserveCancel();
            this.handleNull(cancelResult, msgCancelError, msgCancelErrorForDeveloper);
          } catch (error) {
            // 決済は完了したが、予約キャンセル処理に失敗した場合。
            // マイロッカー上にデータが残っているはずなので、運用でのカバーが必要。
            this.throwErrorAndSetMessageForUser(msgCancelError, msgCancelErrorForDeveloper);
          } finally {
            // 決済完了画面へ遷移させる
            nextUrl = 'thankyou/afterManualCanceled'
          }
          this.router.navigateByUrl(nextUrl);
          break;
      }
    }
  }

  canNotPay(): boolean {
    return this.selectCarrear === null || this.selectCarrear === '';
  }

  hasCreditCard(): boolean {
    if (this.paymentService.currentCard) {
      return true;
    } else {
      return false;
    }
  }

  get lockerUsage(): string {
    return this._routeAction;
  }

  get deviceNum(): number {
    return this._routeId.replace(/[^0-9^\.]/g, '');
  }

  /**
   * ロッカー基本情報の設定
   *
   * @private
   * @memberof ConfirmPaymentComponent
   */
  private setLockerData() {
    // 住所の設定
    if (this._spacer['address']) {
      this.address = this._spacer['address'];
    }
  }

  /**
   * 解錠するための情報の設定
   *
   * @private
   * @memberof ConfirmPaymentComponent
   */
  private async setTakeoutData() {
    // 開始時間の設定
    if (this._spacer['startAt']) {
      this.startTime = this._spacer['startAt'].slice(0, -3);
    }
    if (this._spacer['reservedTime']) {
      this.startTime = this._spacer['reservedTime'].slice(0, -3);
    }

    // 終了時間の設定
    if (this.lockerUsage === 'autoCanceled') {
      this.endTime = this._spacer['expired'].slice(0, -3);
    } else {
      const date = new Date();
      this.endTime = (
        date.getFullYear() +
        '-' +
        ('0' + Number(date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2) +
        ' ' +
        ('0' + date.getHours()).slice(-2) +
        ':' +
        ('0' + date.getMinutes()).slice(-2));
    }

    // 利用時間の設定
    const startDate = new Date(this.startTime).getTime();
    const endDate = new Date(this.endTime).getTime();
    this.amountTime = String(Math.ceil((endDate - startDate) / 1000 / 60 / 60));

    // 商品設定
    if (this._spacer['product']) {
      if (this._spacer['product']['desc']) {
        this.senderName = this._spacer['product']['name'];
        this.productDesc = this._spacer['product']['desc'];
      }
    }

    if (this._calcSingleResult) {
      this.usePrice = this._calcSingleResult.priceValues.usePrice;
      this.reservedPrice = this._calcSingleResult.priceValues.reservePrice;
      this.productPrice = this._calcSingleResult.priceValues.productPrice;
      this.payedPrice = this._calcSingleResult.priceValues.paidPrice;
      this.totalPrice = this._calcSingleResult.priceValues.totalPayPrice;
    }
  }

  /**
   * 事前決済するための情報の設定
   *
   * @private
   * @returns
   * @memberof ConfirmPaymentComponent
   */
  private async setPrepaidData() {
    const prepaid = await this.prepaidService.getPrice(`SPACER${this.deviceNum}`);
    if (prepaid.error) {
      this.overlayRef.detach();
      return alert(prepaid.error.message);
    }

    // 開始、終了時間の設定
    this.startTime = prepaid.prepaidStartAt.slice(0, -3);
    this.endTime = prepaid.prepaidEndAt.slice(0, -3);

    // 利用時間の設定
    const startDate = new Date(this.startTime).getTime();
    const endDate = new Date(this.endTime).getTime();
    this.amountTime = String(Math.ceil((endDate - startDate) / 1000 / 60 / 60));

    // 事前決済料金の設定
    this.usePrice = prepaid.addPrepaidCost;
    this.totalPrice = this.usePrice;
  }

  clickSelectPayment() {
    this.dialogService.selectPaymentMethodDialog();
  }

  clickBack(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event);
    const backUrl = this._backUrlMap[this.lockerUsage];
    this.router.navigateByUrl(backUrl);
  }

  // target が null または undefined の場合、
  // this._errorMessageForUser に messageForUser を設定して、例外をスローする。
  // 例外には messageForDeveloper が含まれる。
  private handleNull(target: any, messageForUser: string, messageForDeveloper: string): void {
    if (target) {
      // 何もしない
    } else {
      // target が null または undefined の場合
      this.throwErrorAndSetMessageForUser(messageForUser, messageForDeveloper);
    }
  }

  private throwErrorAndSetMessageForUser(messageForUser: string, messageForDeveloper: string): void {
    this._errorMessageForUser = messageForUser;
    throw new Error(messageForDeveloper);
  }

  async clickPay(event) {
    this.userOpeLogger.logViewAndEventText(this.viewName, event, `選択: ${this.selectCarrear}`);
    if (this.canNotPay()) {
      alert('決済方法を指定してください');
      return;
    }
    let nextUrl = null;
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
    let calcSingleResult: CalcSingleResult;
    try {

      if (this._isPrepaid) {
        this.paymentService.amount = this.totalPrice;
      } else {
        // 料金計算API呼び出し ////////////////////////////////////////////////////////////////
        calcSingleResult = await this.calcPriceApiService.calcSingleFromMylocker(`SPACER${this.deviceNum}`);
        this.handleNull(calcSingleResult, this._commonErrMessage, 'calcSingleResult is null');
        // 料金計算(支払い済金額の減算) ////////////////////////////////////////////////////////////////
        this.paymentService.amount = calcSingleResult.priceValues.totalPayPrice;
      }

      // 決済種別判定 ////////////////////////////////////////////////////////////////
      const isCreditCardPayment = this.selectCarrear === 'creditcard';

      // 決済実行 ////////////////////////////////////////////////////////////////
      let execPaymentResult = null;
      if (isCreditCardPayment) {
        const fetchedCard = await this.paymentService.fetchCreditCard();
        this.handleNull(fetchedCard, this._commonErrMessage, 'fetchedCard is null');

        execPaymentResult = await this.paymentService.execPaymentCreditCard(fetchedCard.cardSeq, this._isPrepaid);
        this.handleNull(execPaymentResult, this._commonErrMessage, 'execPaymentResult is null');
      } else {
        // console.log('DEBUG before execPaymentResult = ...');
        execPaymentResult = await this.paymentService.execPaymentCarrier(this.selectCarrear, this._isPrepaid);
        // console.log('DEBUG after execPaymentResult = ...');
        this.handleNull(execPaymentResult, this._commonErrMessage, 'execPaymentResult is null');
        // console.log('DEBUG after handleNull');
        const redirectParams = execPaymentResult.redirectParams;
        // console.log('DEBUG redirectParams ... ');
        // console.log(redirectParams);
        this.handleNull(redirectParams, this._commonErrMessage, 'redirectParams is null');

        // ここでいうマイロッカー操作とは、鍵を開ける、予約をキャンセルする、といった操作のことを示す。
        // キャリア決済画面へリダイレクトする仕様であるため、その時点で、WebApp が終了する。
        // そのため、Service にロッカー情報を保持することはできない。
        // また、URLにロッカー情報を保持することも不可能。
        // そのため、キャリア決済から 戻ってきて、最初に Webapp が起動したタイミングで、
        // ご利用履歴をもとに、どのロッカーに対して決済が行われたのかを判定する。
        // ロッカーがわかれば、Firebase 上の mylockerを参照することで、そのロッカーが
        // 通常利用中なのか、予約中なのか、自動キャンセル済なのかを判定することができる。
        // 通常利用中であればロッカーを開ける画面へ遷移させ、
        // 予約中であれば予約キャンセルAPIを呼び出し、
        // 自動キャンセル済であれば予約キャンセルAPIを呼び出す。
        // console.log('DEBUG before  redirectForCarrier... ');
        this.redirectForCarrier(redirectParams.startUrl, redirectParams.asccessId, redirectParams.token);
        // console.log('DEBUG after  redirectForCarrier... ');
      }

      // console.log('DEBUG execPaymentResult');
      // console.log(execPaymentResult);
      // document['execPaymentResult'] = execPaymentResult;

      // 決済実行結果判定 ////////////////////////////////////////////////////////////////
      if (execPaymentResult['result']) {
        if (isCreditCardPayment) {
          nextUrl = await this.lockerOperation();
        } else {
          // キャリア決済の場合、キャリア側の決済画面からリダイレクトされたあとでロッカーを操作する。
        }
      } else {
        // 決済失敗
        this.throwErrorAndSetMessageForUser(execPaymentResult['message'], 'execPaymentResult is false');
      }
    } catch (e) {
      if (this._errorMessageForUser === '') {
        // 想定していないエラーの場合
        alert(`err ... ${e}`);
        throw e;
      } else {
        // 想定しているエラーの場合
        alert(this._errorMessageForUser);
      }
    } finally {
      this.overlayRef.detach();
      if (nextUrl) {
        // alert(this.lockerUsage + ' __ ' + nextUrl);
        this.router.navigateByUrl(nextUrl);
      }
    }
  }

  /**
   * キャリア決済画面へリダイレクト
   * @param url
   * @param accessId
   * @param token
   */
  redirectForCarrier(url, accessId, token) {
    const form = document.createElement("form");
    document.body.appendChild(form);

    const accessIdInput = document.createElement("input");
    accessIdInput.setAttribute("type", "hidden");
    accessIdInput.setAttribute("name", "AccessID");
    accessIdInput.setAttribute("value", accessId);
    form.appendChild(accessIdInput);

    const tokenInput = document.createElement("input");
    tokenInput.setAttribute("type", "hidden");
    tokenInput.setAttribute("name", "Token");
    tokenInput.setAttribute("value", token);
    form.appendChild(tokenInput);
    form.setAttribute("method", "post");

    form.setAttribute("action", url);
    form.submit();
  }

  /**
   * キャリア決済完了時
   */
  onFinichedPaymentForCarrier() {
    // console.log('DEBUG onFinichedPaymentForCarrier start');
    alert('DEBUG onFinichedPaymentForCarrier start');
    let message = "決済処理が成功しました。";
    if (this.status === "cancel") {
      message = "決済がキャンセルされました。";
    } else if (this.status === "error") {
      let errorCode = "";
      if (this.errorInfo) {
        errorCode = "\n\n エラーコード:" + this.errorInfo;
      }
      message =
        "決済処理に失敗しました。\n\n時間をおいて再度お試し下さい。" + errorCode;
    }
    const diaglogRef = this.dialog.open(PaymentAlertDialog, {
      data: { message: message }
    });
    diaglogRef.afterClosed().subscribe((result: any) => {
      // // console.log('result payment carrier', this.status);
      // if (this.status === 'success') {
      //   // 決済処理成功時の処理
      //   // console.log('DEBUG onFinichedPaymentForCarrier success');
      //   this.lockerOperation();
      // } else {
      //   // 決済処理失敗時の処理
      //   // console.log('DEBUG onFinichedPaymentForCarrier failure');
      // }
    });
  }

  async lockerOperation() {
    const msgCancelError = `キャンセル処理でエラーが発生致しました。お問い合わせ窓口までロッカー番号をお伝え下さい。\nロッカー番号: SPACER${this.deviceNum}`;
    const msgCancelErrorForDeveloper = `cancel error in ${this.lockerUsage}`;
alert("aaa");
    let nextUrl;
    // 決済成功
    switch (this.lockerUsage) {
      case 'use': // 通常利用の場合
alert("bbb");
        // +++++++++++++++++++++++++++++++++ 
        // +++++++++++++++++++++++++++++++++ 
        // +++++++++++++++++++++++++++++++++ 
        // +++++++++++++++++++++++++++++++++ 
        // ここでfirebaseを追加してmail送信も行う 
        // +++++++++++++++++++++++++++++++++ 
        // +++++++++++++++++++++++++++++++++ 
        // +++++++++++++++++++++++++++++++++ 
        // +++++++++++++++++++++++++++++++++ 
        // +++++++++++++++++++++++++++++++++ 
        // ロッカーを開ける画面へ遷移させる
        nextUrl = `choice-share-type-last/SPACER${this.deviceNum}`;
        //nextUrl = `mylockers/detect/SPACER${this.deviceNum}`;
        break;
      case 'manualCanceled': // 手動キャンセルの場合
        this.cancelApi.id = `SPACER${this.deviceNum}`;
        try {
          const cancelResult = await this.cancelApi.reserveCancel();
          this.handleNull(cancelResult, msgCancelError, msgCancelErrorForDeveloper);
        } catch (error) {
          // 決済は完了したが、予約キャンセル処理に失敗した場合。
          // マイロッカー上にデータが残っているはずなので、運用でのカバーが必要。
          this.throwErrorAndSetMessageForUser(msgCancelError, msgCancelErrorForDeveloper);
        } finally {
          // 決済完了画面へ遷移させる
          nextUrl = 'thankyou/afterPayAutoCanceled';
        }
        break;
      case 'autoCanceled': // 自動キャンセルの代金を支払う場合
        // 以下のロジックは 手動キャンセルの場合 と同等だが、今後の仕様変更に備えて、分けて記載してる
        this.cancelApi.id = `SPACER${this.deviceNum}`;
        try {
          const cancelResult = await this.cancelApi.reserveCancel();
          this.handleNull(cancelResult, msgCancelError, msgCancelErrorForDeveloper);
        } catch (error) {
          // 決済は完了したが、予約キャンセル処理に失敗した場合。
          // マイロッカー上にデータが残っているはずなので、運用でのカバーが必要。
          this.throwErrorAndSetMessageForUser(msgCancelError, msgCancelErrorForDeveloper);
        } finally {
          // 決済完了画面へ遷移させる
          nextUrl = 'thankyou/afterPayAutoCanceled'
        }
        break;
      case 'prepaid':
        nextUrl = `thankyou/afterPrepaid?device=SPACER${this.deviceNum}`
        break;
      default:
        this.throwErrorAndSetMessageForUser(msgCancelError, `invalid state: ${this.lockerUsage}`);
    } // <- end of switch (this.status)
    return nextUrl;
  }
}
