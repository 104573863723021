import * as moment from 'moment';

export class BannerMaster {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  imagePath: string | null;
  openUrl: string | null;

  get enabled() {
    return moment().isBetween(this.startDate, this.endDate);
  }
}
