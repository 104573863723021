<div id="fullview">
  <div class="logo">
    <img src="assets/images/logo.svg" height="60px" />
  </div>

  <div class="h2-a">SPACER(スペースアール)会員の方</div>
  <button class="k" (click)="onConfirm()" class="large-black">
    確認する
  </button>
  <div class="white-area">
    <p class="textarea">
      共有された鍵を利用するには、サービスサイトへログインして内容をご確認ください。
    </p>
    <!-- 通常　ボタン非活性ここにチェック入ったら、ボタン活性 -->
  </div>
  <div class="gray-area">
    <div class="h2-a">会員登録がお済みでない方</div>
    <p class="textarea">
      以下のサービスサイトへアクセスし、会員登録後、上記の「確認する」ボタンをタップしてください。
      ご利用には、最新版のChromeが必要です。
    </p>
    <button (click)="unsubscribe()" class="large-white">
      サービスサイトへ
    </button>
  </div>
</div>
