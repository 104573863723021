import * as moment from 'moment';

export class PartnerShopMember {
  moment = require("moment");
  fromDateStr: string;
  toDateStr: string;
  name: string;
  phoneStr: string;
  shopName: string;

  constructor() {}

  // getCurrentPartnerShopMember の結果から生成する
  applyItem(item: any) {
    this.fromDateStr = item['fromDate'];
    this.name = item['name'];
    this.phoneStr = item['phone'];
    this.shopName = item['shopName'];
  }

  canSetPrice(dateForDebug=null) {
    let current: moment.Moment;
    if (dateForDebug) {
      current = dateForDebug;
    } else {
      current = moment();
    }

    if (this.fromDate) {
      if (current.isBefore(this.fromDate)) {
        return false;
      }
    }

    if (this.toDate) {
      return current.isAfter(this.toDate);
    } else {
      return true;
    }
  }

  private get fromDate(): moment.Moment {
    if(this.fromDateStr) {
      return moment(this.fromDateStr);
    }
    return null;
  }

  private get toDate(): moment.Moment {
    if(this.toDateStr) {
      return moment(this.toDateStr);
    }
    return null;
  }
}
