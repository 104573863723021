const hashTrim = (hash, key, trimTo) => {
  let ret = trimTo;
  if (hash[key]) {
    ret = hash[key];
  }
  return ret;
}

export const hashTrimBlank = (hash, key) => {
  return hashTrim(hash, key, '');
}

export const hashTrimNull = (hash, key) => {
  return hashTrim(hash, key, null);
}
