import { hashTrimBlank, hashTrimNull } from "../util/hash-util";

export class SendKeyUrl {
  address;
  device;
  sendKeyURL;
  product;
  lockingTime;
  reservedTime;
  status;
  isReserved;
  isCleaning;
  cleaningShopName;
  cleaningShopNumber;
  cleaningURL;
  startAt;
  prepaid;
  paidAt;
  hasReserved;

  constructor() {}

  applyItem(item: any, url: string) {
    this.address = hashTrimBlank(item, 'address');
    this.device = item['device']; // 必ず定義されている
    this.sendKeyURL = url;
    this.product = hashTrimNull(item, 'product'); // オブジェクトであるため、空文字ではなく NULL とする
    this.lockingTime = hashTrimBlank(item, 'lockingTime')
    this.status = hashTrimBlank(item, 'status');
    this.isReserved = hashTrimNull(item, 'isReserved'); // 予約利用の場合のみ設定される
    this.reservedTime = hashTrimBlank(item, 'reservedTime');
    this.isCleaning = hashTrimBlank(item, 'isCleaning');
    this.cleaningShopName = hashTrimBlank(item, 'cleaningShopName');
    this.cleaningShopNumber = hashTrimBlank(item, 'cleaningShopNumber');
    this.cleaningURL = hashTrimBlank(item, 'cleaningURL');
    this.startAt = hashTrimNull(item, 'startAt');
    this.prepaid = hashTrimNull(item, 'prepaid');
    this.paidAt = hashTrimNull(item, 'paidAt');
    this.hasReserved = hashTrimNull(item, 'hasReserved');
  }
}
