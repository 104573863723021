import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpJsonService } from '../http-json.service';
import { FormsModule } from "@angular/forms";

@Component({
  selector: "cleaning-dialog",
  templateUrl: './cleaning-dialog.html',
  styleUrls: ['./cleaning-dialog.scss']
})
export class CleaningDialog {
  days = this.setSelectDays();
  day: string;
  cleaningShopName: string;
  cleaningShopNumber: string;

  constructor(
    private _httpjson: HttpJsonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    alert('依頼が完了すると、' + data.spacerid + 'が使えなくなります。');
    this.cleaningShopName = data.cleaningShopName;
    this.cleaningShopNumber = data.cleaningShopNumber;
  }

  async request() {
    if (this.day === undefined) {
      alert('返却希望日をご選択下さい。');
      return;
    }
    if (new Date().getTime() > Date.parse(this.data.expired)) {
      alert('延長してからご依頼下さい。');
      return;
    }
    alert(this.day + 'で依頼します');
    const arrySelected = this.day.split('/');
    const selectedDate = (
      arrySelected[0] +
      arrySelected[1] +
      arrySelected[2]
    ).slice(0, 8);
    const url =
      'https://api.spacer.co.jp/collaborateCleaning/' +
      this.data.url +
      '/' +
      selectedDate;
    const res = await this._httpjson.getJson(url);
    if (res) {
      const result = res['result'];
      if (result === 'accept') {
        alert(this.day + 'で承りました。');
      } else if (result === 'deny') {
        alert('対応していない日程です。再度選択下さい。');
      } else if (result === 'overTime') {
        alert('時間が過ぎているため選択できませんでした。再度選択下さい。');
      } else if (result === 'urlError') {
        alert('指定されたカギが不正です。');
      } else if (result === 'unsupported') {
        alert('クリーニングがサポートされていません。');
      } else if (result === 'expired') {
        alert('延長してからご依頼下さい。');
      }
    }
  }

  private setSelectDays() {
    const days = [];
    let yyyy, mm, dd;
    const today = new Date();
    today.setDate(today.getDate() + 2);
    let is_today_available = true;

    if (today.getHours() <= 11) {
      is_today_available = true;
    } else {
      is_today_available = false;
    }
    for (let i = 0; i <= 12; i++) {
      const tempDate = new Date();
      tempDate.setDate(today.getDate() + i);
      const weekday = tempDate.getDay();
      if (Number(weekday) === 0) {
        continue;
      }
      let weekDayJ = '';
      if (weekday === 1) {
        weekDayJ = '(月)';
      } else if (weekday === 2) {
        weekDayJ = '(火)';
      } else if (weekday === 3) {
        weekDayJ = '(水)';
      } else if (weekday === 4) {
        weekDayJ = '(木)';
      } else if (weekday === 5) {
        weekDayJ = '(金)';
      } else if (weekday === 6) {
        weekDayJ = '(土)';
      }
      yyyy = tempDate.getFullYear();
      mm = tempDate.getMonth() + 1;
      dd = tempDate.getDate();
      if (!is_today_available) {
        is_today_available = true;
        continue;
      }
      //新年
      if (Number(mm) === 1 && (Number(dd) === 2 || Number(dd) === 3)) {
        continue;
      }
      days.push(
        yyyy +
          '/' +
          ('0' + mm).slice(-2) +
          '/' +
          ('0' + dd).slice(-2) +
          weekDayJ +
          '閉店後'
      );
    }
    return days;
  }
}
