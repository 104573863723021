<div id="fullview">
  <div>
    <h2>{{ h2 }}</h2>
    <p>下記のものは送れません</p>
    <p>・麻薬など非合法のもの。</p>
    <p>・賞味期限が１週間未満の生物。</p>
    <p>・その他規定に反するもの。</p>
    <p class="note">※１週間程度でメッセージが届きます。</p>
    <button  *ngIf="button_able;" class="large-black" (click)="clickSharingLastKey($event)">了解しステータス入力</button>
    <button  *ngIf="!button_able;" class="large-white" >了解しステータス入力</button>
 
 
  </div>
</div>
<app-global-menu-component></app-global-menu-component>
