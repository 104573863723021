import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { KeyURL, KeyURLMap } from "./key-url.interface";
import { Observable } from "rxjs/Observable";

@Injectable()
export class KeyControllerService {
  private keyURLMap: Observable<KeyURLMap<KeyURL>[]>;
  private tempCheckIdArr = [];
  private currentData;

  constructor(private db: AngularFireDatabase) {}

  getKeyURLData(keyid: string): Promise<any> {
    if (
      this.tempCheckIdArr.some(value => {
        return value === keyid;
      })
    ) {
      // console.log("test" + keyid);
      return Promise.resolve(this.currentData); //keyURLMap[keyid] );
    }
    // console.log("test2" + keyid);
    this.tempCheckIdArr.push(keyid);
    const itemRef = this.db.object<any>("sendKeyURL/" + keyid);
    const item = itemRef.valueChanges();
    item.subscribe(data => {
      if (data) {
        // console.log("test3");
        // console.log("aa" + data);
        // console.log("bb" + data.expired);
        //				this.keyURLMap[keyid] = data;
        this.currentData = data;
        // console.log("aa1" + data);
        // console.log("bb1" + data.expired);
        return Promise.resolve(data);
      }
    });
  }
}
