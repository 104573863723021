import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  safeSite: SafeResourceUrl;
  site = 'https://spacer.live/iframe/kiyaku/';
  h2 = '利用規約';

  constructor(private sanitizer: DomSanitizer) {
    this.safeSite = this.sanitizer.bypassSecurityTrustResourceUrl(this.site);
  }

  ngOnInit() {
  }

}
