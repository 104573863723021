import { Injectable } from '@angular/core';
import { HttpJsonService } from "./http-json.service";
import { MemberInfo } from "./entity/member-info";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MemberApiService {
  constructor(
    private httpJsonService: HttpJsonService
  ) { }

  private loggedin = false;
  async login() {
    if (!this.loggedin) {
      this.loggedin = true;
      const url = `${environment.apiUrl}member/login`;
      await this.httpJsonService.postJson(url, {});
    }
  }

  async getInfo() {
    const url = `${environment.apiUrl}member/info`;
    const response = await this.httpJsonService.getJson(url);
    if (response) {
      return new MemberInfo(response);
    } else {
      return null;
    }
  }
}
