import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {
  safeSite: SafeResourceUrl;
  site = 'https://maps.spacer.live/?appView=true';

  constructor(private sanitizer: DomSanitizer, private router: Router) {
    this.safeSite = sanitizer.bypassSecurityTrustResourceUrl(this.site);
  }
  ngOnInit() {}
}
