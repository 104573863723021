export class TutorialPage {
  hasNext: boolean;
  currentPage: boolean;
  constructor(
    public imagePath: string,
    public title: string,
    public description: string
  ) {
    this.hasNext = true;
    this.currentPage = false;
  }
}
