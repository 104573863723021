import { Component, OnInit } from '@angular/core';
import { MembersRepositoryService } from "../members-repository-service";
import * as moment from 'moment';
import { AngularFireDatabase } from "@angular/fire/database";
import { CalcPriceApiService } from "../calc-price-api.service";
import { CalcPriceParameter } from "../value-object/calc-price-parameter";
import { SendKeyUrl } from "../entity/send-key-url";
import { Observable } from "rxjs";
import { Duration } from "moment";

export class PriceInfo {
  usePrice: number;
  reservePrice: number;
  productPrice: number;
  get totalPrice(): number {
    return this.usePrice + this.reservePrice + this.productPrice;
  }
}

export interface IBoxForMyBoxes {
  // Firebase から取得した値の書き込み　ここから
  applyItem(item: any): void;
  // Firebase から取得した値の書き込み　ここまで
  // Firebaseから直接取得する値　ここから
  useType: string; // TODO implement to BaseBoxForMyBoxes
  boxId: string; // TODO implement to BaseBoxForMyBoxes
  startAt: string; // TODO implement to BaseBoxForMyBoxes
  expired: string; // TODO implement to BaseBoxForMyBoxes
  // Firebaseから直接取得する値　ここまで

  // 料金計算API呼び出しに必要な関数　ここから
  calcPriceParameter: CalcPriceParameter; // TODO implement to BaseBoxForMyBoxes
  // 料金計算API呼び出しに必要な関数　ここまで

  // 料金計算APIからの返却値反映　ここから
  updatePrice(priceInfo: any): void;
  priceInfo: PriceInfo;
  //料金計算APIからの返却値反映　ここまで

  // 画面制御用の属性　ここから
  isAutoCanceled: boolean;
  timeDiffForTick: Duration;
  // 画面制御用の属性　ここまで

  // 画面制御用の関数　ここから
  onTick(secFromZero: number): void;
  // 画面制御用の関数　ここまで
}

class BaseBoxForMyBoxes {
  address: string;
  device: string;
  sendKeyURL: string;
  product;
  startAt;
  expired;
  status;

  // 料金計算APIの返却値保持用　ここから
  private _priceInfo: PriceInfo = new PriceInfo();
  // 料金計算APIの返却値保持用　ここまで

  // クリーニング関連　ここから
  isCleaning;
  cleaningShopName;
  cleaningShopNumber;
  cleaningURL;
  // クリーニング関連　ここまで

  /**
   * sendKeyUrl に含まれる値を、このオブジェクトに反映する。
   * URL だけは明示する。
   * item の lockingTime は、mylocker の startAt へ書き込む。
   * その他の値は同名として扱う。
   */
  applySendKeyURL(sendKeyUrl: SendKeyUrl, url: string) {
    this.address = sendKeyUrl.address;
    this.device = sendKeyUrl.device;
    this.sendKeyURL = url;
    this.product = sendKeyUrl.product;
    this.startAt = sendKeyUrl.lockingTime;
    this.status = sendKeyUrl.status;
    this.isCleaning = sendKeyUrl.isCleaning;
    this.cleaningShopName = sendKeyUrl.cleaningShopName;
    this.cleaningShopNumber = sendKeyUrl.cleaningShopNumber;
    this.cleaningURL = sendKeyUrl.cleaningURL;
  }

  applyItem(item) {
    this.address = item['address'];
    this.device = item['device'];
    this.sendKeyURL = item['sendKeyURL'];
    this.product = item['product'];
    this.status = item['status'];
    this.isCleaning = item['isCleaning'];
    this.cleaningShopName = item['cleaningShopName'];
    this.cleaningShopNumber = item['cleaningShopNumber'];
    this.cleaningURL = item['cleaningURL'];
  }

  // mylocker-list.component.ts から移植
  resolveStartAt(val): string {
    const startAt = val['reservedTime'];
    if (startAt) {
      return startAt;
    } else {
      return val['startAt'];
    }
  }
  get boxId(): string {
    return this.device;
  }

  updatePrice(priceInfo: any) {
    this.priceInfo.usePrice = priceInfo['price'];
    this.priceInfo.reservePrice = priceInfo['reserved_price'];
    this.priceInfo.productPrice = priceInfo['productPrice'];
  }

  get priceInfo() { return this._priceInfo }
}

abstract class AbstractBoxForMyBoxes {
  // address;
  // device;
  // sendKeyURL;
  // product;
  // startAt;
  // status;
  // isCleaning;
  // cleaningShopName;
  // cleaningShopNumber;
  // cleaningURL;
  //
  // abstract useType: string;
  // abstract usePrice: number;
  // abstract reservePrice: number;
  // abstract productPrice: number;
  // abstract totalPrice: number;
  // abstract calcPriceParameter: CalcPriceParameter;
  // abstract expired: string;
  //
  // /**
  //  * sendKeyUrl に含まれる値を、このオブジェクトに反映する。
  //  * URL だけは明示する。
  //  * item の lockingTime は、mylocker の startAt へ書き込む。
  //  * その他の値は同名として扱う。
  //  */
  // applySendKeyURL(sendKeyUrl: SendKeyUrl, url: string) {
  //   this.address = sendKeyUrl.address;
  //   this.device = sendKeyUrl.device;
  //   this.sendKeyURL = url;
  //   this.product = sendKeyUrl.product;
  //   this.startAt = sendKeyUrl.lockingTime;
  //   this.status = sendKeyUrl.status;
  //   this.isCleaning = sendKeyUrl.isCleaning;
  //   this.cleaningShopName = sendKeyUrl.cleaningShopName;
  //   this.cleaningShopNumber = sendKeyUrl.cleaningShopNumber;
  //   this.cleaningURL = sendKeyUrl.cleaningURL;
  // }
  //
  // applyItem(item) {
  //   this.address = item['address'];
  //   this.device = item['device'];
  //   this.sendKeyURL = item['sendKeyURL'];
  //   this.product = item['product'];
  //   this.applyStartAt(item);
  //   this.status = item['status'];
  //   this.isCleaning = item['isCleaning'];
  //   this.cleaningShopName = item['cleaningShopName'];
  //   this.cleaningShopNumber = item['cleaningShopNumber'];
  //   this.cleaningURL = item['cleaningURL'];
  // }
  //
  // // mylocker-list.component.ts から移植
  // resolveStartAt(val): string {
  //   const startAt = val['reservedTime'];
  //   if (startAt) {
  //     return startAt;
  //   } else {
  //     return val['startAt'];
  //   }
  // }
  //
  // protected abstract applyStartAt(item): void;
  //
  // get boxId(): string {
  //   return this.device;
  // }
  //
  // abstract onTick(secFromZero: number);
  //
  // abstract isAutoCanceled: boolean;
}

class UseBox implements IBoxForMyBoxes {
  _baseBox: BaseBoxForMyBoxes = new BaseBoxForMyBoxes();
  readonly useType = 'use';
  readonly isAutoCanceled = false;

  applyItem(item: any) {
    this._baseBox.applyItem(item);
    this.startAt = item['startAt'];
  }

  get boxId() { return this._baseBox.boxId };

  /**
   * 利用開始日時から現在日時までの経過時間を返す
   */
  get timeDiffForTick() : moment.Duration {
    const from = moment(this.startAt);
    const to = moment();
    return moment.duration(from.diff(to));
  }

  /**
   * 通常利用料金計算用のパラメタを返す
   */
  get calcPriceParameter(): CalcPriceParameter {
    const ret = new CalcPriceParameter();
    ret.spacerid = this.boxId;
    ret.timestamp = this.startAt;
    ret.reserved_time = '';
    return ret;
  }

  onTick(secFromZero: number): void {
    console.log('DEBUG secFromZero UseBox');
  }

  get expired() {
    return null;
  }

  updatePrice(priceInfo: any): void {
    this._baseBox.updatePrice(priceInfo);
  }

  get priceInfo() { return this._baseBox.priceInfo }

  startAt: string;
}

class ReservedBox implements IBoxForMyBoxes {
  _baseBox: BaseBoxForMyBoxes = new BaseBoxForMyBoxes();
  readonly useType = 'reserved';
  isAutoCanceled = false;
  _timeDiffForTick: Duration;

  /**
   * 予約期限までの秒数。
   * この値がプラスであれば、予約ロッカーとして利用可能。
   * この値がゼロ以下であれば、自動キャンセル済。
   */
  secToExpired: number;

  applyItem(item: any) {
    this._baseBox.applyItem(item);
    this._baseBox.startAt = item['reservedTime'];
    this._baseBox.expired = item['expired'];
  }

  get boxId() { return this._baseBox.boxId };

  /**
   * 利用開始日時から現在日時までの経過時間を返す
   */
  get timeDiffForTick() : moment.Duration {
    const from = moment(this._baseBox.startAt);
    const to = moment();
    return moment.duration(from.diff(to));
  }

  /**
   * 予約料金計算用のパラメタを返す
   */
  get calcPriceParameter(): CalcPriceParameter {
    const ret = new CalcPriceParameter();
    ret.spacerid = this.boxId;
    ret.timestamp = '';
    ret.reserved_time = this._baseBox.startAt;
    return ret;
  }

  onTick(secFromZero: number): void {
    console.log('DEBUG secFromZero ReservedBox');
    console.log(`DEBUG this.secToExpired : ${this.secToExpired}`);
    const startAt = moment(this._baseBox.startAt);
    const current = moment();
    this._timeDiffForTick = moment.duration(current.diff(startAt));
    this.isAutoCanceled = moment().isAfter(moment(this.expired));
  }

  get startAt() { return this._baseBox.startAt }
  get expired() { return this._baseBox.expired }
  get priceInfo() { return this._baseBox.priceInfo }

  updatePrice(priceInfo: any): void {
    this._baseBox.updatePrice(priceInfo);
  }
}

class MyBoxes {
  _boxes: IBoxForMyBoxes[] = [];

  get boxes(): IBoxForMyBoxes[] {
    return this._boxes;
  }

  private judgeUseType(boxItem: any) {

    if(boxItem['isReserved'] && boxItem['isReserved'] === "true") {
      const current: moment.Moment = moment();
      const expiredAt: moment.Moment = moment(boxItem['expired']);
      console.log('DEBUG current, expiredAt');
      console.log(current);
      console.log(expiredAt);
      return 'reserved';
    }
    return 'use';
  }

  private createBoxFromUseType(useType: string): IBoxForMyBoxes {
    let box;
    switch (useType) {
      case 'use':
        box = new UseBox();
        break;
      case 'reserved':
        box = new ReservedBox();
        break;
    }
    if (box) {
      box.useType = useType;
    }
    return box;
  }

  applyBoxItems(items) {
    Object.keys(items).forEach(boxId => {
      // console.log(`DEBUG boxId : ${boxId}`);
      // console.log(items[boxId]);
      const boxItem = items[boxId];
      document[`DEBUG_${boxId}`] = boxItem;
      const useType: string = this.judgeUseType(boxItem);
      const newBox: IBoxForMyBoxes = this.createBoxFromUseType(useType);
      newBox.applyItem(boxItem)
      this._boxes.push(newBox);
    })
  }

  updateBoxesPrice(calcPriceApi: CalcPriceApiService) {
    const calcPriceParams = this._boxes.map(box => { return box.calcPriceParameter })
    calcPriceApi.calcMulti(calcPriceParams).then(result => {
      const priceList = result['list'];
      priceList.forEach(priceInfo => {
        const box = this.boxes.find(box => { return box.boxId === priceInfo['spacerid']});
        box.updatePrice(priceInfo);
      })
    })
  }

  onTick(secFromZero: number) {
    this.boxes.forEach(box => {
      box.onTick(secFromZero);
    })
  }
}

@Component({
  selector: 'app-myboxes',
  templateUrl: './myboxes.component.html',
  styleUrls: ['./myboxes.component.scss']
})
export class MyboxesComponent implements OnInit {
  readonly tickMilliSec = 1000;
  _myBoxes = new MyBoxes();
  _intervalSource;
  constructor(
    private membersRepository: MembersRepositoryService,
    private calcPriceApi: CalcPriceApiService,
    private db: AngularFireDatabase,
  ) {
    this._intervalSource = Observable.interval(this.tickMilliSec);
    this._intervalSource.subscribe(
      (secFromZero) => {
        console.log('DEBUG secFromZero');
        console.log(secFromZero);
        this.onTick(secFromZero);
        // 料金の再計算は 10秒ごとに行う
        if(secFromZero % 10 === 0) {
          this.calcPriceOfBoxes();
        }
      }
    )
  }

  async ngOnInit() {
    const boxItems = await this.membersRepository.getMylockers();
    this.applyBoxItems(boxItems);
    this.calcPriceOfBoxes();
    console.log('DEBUG myBOxes.component.ts ngOnInit');
    console.log(boxItems);
  }

  private applyBoxItems(item) {
    this._myBoxes.applyBoxItems(item);
  }

  private calcPriceOfBoxes() {
    this._myBoxes.updateBoxesPrice(this.calcPriceApi);
  }

  private onTick(secFromZero: number) {
    this._myBoxes.onTick(secFromZero);
  }

  get boxes() {
    return this._myBoxes.boxes;
  }

}
