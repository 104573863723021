import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-transactions-law',
  templateUrl: './transactions-law.component.html',
  styleUrls: ['./transactions-law.component.scss']
})
export class TransactionsLawComponent implements OnInit {
  safeSite: SafeResourceUrl;
  site = 'https://spacer.live/iframe/law/';
  h2 = '特定商取引法に基づく表記';

  constructor(private sanitizer: DomSanitizer) {
    this.safeSite = this.sanitizer.bypassSecurityTrustResourceUrl(this.site);
  }

  ngOnInit() {
  }

}
