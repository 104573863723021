import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpParams } from "@angular/common/http";
import { HttpJsonService } from "../http-json.service";

@Component({
  selector: "laundry-pack-dialog",
  templateUrl: "./laundry-pack-dialog.html",
  styleUrls: ["./laundry-pack-dialog.scss"]
})
export class LaundryPackDialog {
  constructor(
    public dialogRef: MatDialogRef<LaundryPackDialog>,
    private httpjson: HttpJsonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async request() {
    if (new Date().getTime() > Date.parse(this.data.expired)) {
      alert("延長してからご依頼下さい。");
      return;
    }
    const url = "https://spacer.live/idLink/laundry_pack";
    let params = new HttpParams();
    //uidとspacerid
    params = params.append("spacerid", this.data.spacerid);
    params = params.append("uid", this.data.uid);
    params = params.append("url", this.data.url);
    const res = await this.httpjson.getJsonByPost(url, params);
    if (res) {
      const result = res["result"];
      if (result === "accepted") {
        alert("で承りました。");
      } else if (result === "invalid_url") {
        alert("依頼元URLが不正です");
      } else if (result === "invalid_param") {
        alert("パラメータが不正です");
      } else if (result === "invalid_spacerid_or_url") {
        alert("SPACERIDかURLが不正です");
      } else if (result === "none_member") {
        alert("ID連携が未完了です。ランドリーパックアプリよりご連携下さい。");
      } else {
        alert("レスポンスエラーです。");
      }
      this.dialogRef.close();
    }
  }
}
