<div id="content">
  <h2>電話番号の変更</h2>
  <div class="regist-phone-num-view">
    現在登録している携帯電話番号
    <div class="text-area">
      <div>{{ phone }}</div>
    </div>

    <form #phoneNumberAuthForm="ngForm">
      <div class="form-group">
        <p>
          変更する場合は、新しい携帯電話番号を入力してください。認証コードをSMS にてお知らせします。
        </p>
        <div id="phone">
          <select name="phoneNumberPrefix" [(ngModel)]="parameters.phoneNumberPrefix">
            <option value="undefined">選択して下さい</option>
            <option
              *ngFor="let country of listCountries"
              [value]="country.phoneNumberPrefix()"
              >{{ country.nameWithPhoneNumber() }}</option
            >
          </select>
          <input
            type="tel"
            class="form-control"
            id="telephoneNumber"
            [(ngModel)]="parameters.phoneNumber"
            name="phoneNumber"
            placeholder="090-1234-5678"
          />
        </div>
        <p>※ 新しい電話番号が認証されるまで、現在の電話番号は変更はされません。</p>
        <div id="recaptcha-container"></div>
        <button
          class="large-gray"
          id="sign-in-button"
          type="submit"
          (click)="sendPhoneNumber($event)"
        >
        SMSに認証コードを送る
        </button>
      </div>
    </form>
  </div>
</div>
<app-global-menu-component></app-global-menu-component>
