<div id="content">
  <h2>お問合せ</h2>
  <form #inquiryForm="ngForm" (ngSubmit)="send()" novalidate>
    <div class="inquiryForm">
      <p>* の項目は入力必須となります。</p>
      <div class="field">
        <label for="userName">お名前 *</label>
        <input id="userName" name="userName" [(ngModel)]="inquiry.userName" type="text">
      </div>
      <div class="field">
        <label for="mailAddress">メールアドレス</label>
        <input id="mailAddress" name="mailAddress" [(ngModel)]="inquiry.mailAddress" type="email">
      </div>
      <div class="bigField">
        <label for="userName">お問い合わせ内容 *</label>
        <textArea id="inquiryText" name="inquiryText" rows="10" (input)="inquiry.inquiryText = $event.target.value"></textArea>
      </div>
    </div>
    <button class="large-black">送信</button>
  </form>
  <p><strong>電話によるお問い合わせ</strong></p>
  <p>受付時間 : 09:00 ~ 24:00</p>
  <p><a href="tel:">050-5835-2103</a></p>
</div>
<app-global-menu-component></app-global-menu-component>
