import { TutorialPage } from './tutorial-page';

export class TutorialPages {
  public tutorialPages: TutorialPage[];
  public currentPageIndex: number;

  constructor() {
    this.tutorialPages = [
      new TutorialPage(
        'tutorial_phone.svg',
        'スマホで簡単',
        'ロッカーの前で専用アプリを起動、ボタンをタップするとロッカーの鍵が開きます。鍵を持ち歩く必要はありません。'
      ),
      new TutorialPage(
        'tutorial_booking.svg',
        '予約できる',
        '今空いているロッカーを検索でき、予約をすると、その時点から最大48時間ロッカーを確保。探し歩く必要はありません。'
      ),
      new TutorialPage(
        'tutorial_share_key.svg',
        '鍵が共有できる',
        'ロッカーの鍵を共有するには、アプリで生成したURLを共有したい相手に送るだけ。鍵を開けるにはアプリへの登録が必要なので、安全です。'
      ),
      new TutorialPage(
        'tutorial_payment.svg',
        'キャッシュレス決済',
        `料金はロッカー利用後にお支払い。事前に決済方法を登録しておけば、利用後のお支払いがスムーズです。
        <br>現在ご利用いただける決済手段は、visa、MasterCard、JCB、AmericanExpress、d払い（ドコモ）、auかんたん決済、ソフトバンクまとめて支払いです。`
      )
    ];
    this.tutorialPages[0].currentPage = true;
    this.tutorialPages[this.tutorialPages.length - 1].hasNext = false;
    this.currentPageIndex = 0;
  }

  toNextPage() {
    this.currentPageIndex += 1;
    const currentPageIndex = this.currentPageIndex;
    this.tutorialPages.forEach(function(page, index) {
      page.currentPage = index === currentPageIndex;
    });
  }
}
