/**
 * 電話番号を変更する処理のパラメタ。
 */
export class PhoneNumberAuthParameter {
  public isAgreeTermOfUseAndPrivacyPolicy: boolean;
  public phoneNumber: string;
  public phoneNumberPrefix: string;
  public confirmationCode: string;

  constructor() {
    this.isAgreeTermOfUseAndPrivacyPolicy = false;
  }

  completelyPhoneNumber() {
    const telNo = this.phoneNumber || "";
    // console.log("DEBUG");
    // console.log(`${this.phoneNumberPrefix}${telNo.slice(1)}`);
    return `${this.phoneNumberPrefix}${telNo.slice(1)}`;
  }

  codeCharAt(idx): string {
    const code = this.confirmationCode || "";
    return code.charAt(idx);
  }
}
