import { SpacersUnitDetail } from './spacers-unit-detail';
import { SpacersUnitCollaboration } from './spacers-unit-collaboration'

export class SpacersUnit {
  id: string;
  details: SpacersUnitDetail[] = [];
  collaboration: SpacersUnitCollaboration;

  getDetail(spacerId: string): SpacersUnitDetail {
    return this.details.find((detail) => (detail.id === spacerId));
  }
}
