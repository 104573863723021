<ng-container [ngSwitch]="displayType">
  <ng-container *ngSwitchCase="'large'">
    <p id="timer-text">{{ message }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="'normal'">
    <span>{{ strHours }}</span
    ><span class="hms">時間</span> <span>{{ strMinutes }}</span
    ><span class="hms">分</span> <span>{{ strSeconds }}</span
    ><span class="hms">秒</span>
  </ng-container>
</ng-container>
