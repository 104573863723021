import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../dialogs/dialog.service';
import { MembersRepositoryService } from '../members-repository-service';
import { SpacersUnitsRepositoryService } from '../spacers-units-repository-service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

@Component({
  selector: 'app-choice-share-type-last',
  templateUrl: './choice-share-type.component.html',
  styleUrls: ['./choice-share-type.component.scss']
})
export class ChoiceShareTypeLastComponent implements OnInit {

  _urlKey: string;
  scriptStr: string;
  _device: string;
  _leisuretime: string;
  _address: string;
  _url: string;
  currentUrl:string;
  cleaningURL: string;
  isEnabled = false;
  collaborationName: string;
  collaborationPhoneNumber: string;
  collaborationType: string;
  vCardInfo: string;  

  constructor(
    private member: MembersRepositoryService,
    private SpacersUnitsService: SpacersUnitsRepositoryService,
    private dialog: DialogService,
    private activatedRoute: ActivatedRoute,
    private db: AngularFireDatabase,
    private router: Router
  ) { }
  get device(): string {
    return this._device;
  }


  get leisuretime(): string {
    return this._leisuretime;
  }
  get address(): string {
    return this._address;
  }
  get url(): string {
    return this._url;
  }


  get urlKey(): string {
    return this._urlKey;
  }

  gotoMylockers() {
    this.router.navigateByUrl('mylockers');
  }

  async ngOnInit() {
    this._device = this.activatedRoute.snapshot.paramMap.get('device');
    const mylocker = await this.member.getMylockerBySpacerid(this._device);
    const uid = firebase.auth().currentUser.uid;
    const regex = /[^0-9]/g;
    const result = uid.replace(regex, "");
    const uid3string = result.slice( 0, 3 ) ;
    const hiduke=new Date(); 
//年・月・日・曜日を取得する
    const year = hiduke.getFullYear();
    const month = hiduke.getMonth()+1;
    var writemonth = "0";
    var writeday = "0";
    const day = hiduke.getDate();
    if(month < 10){
     writemonth = "0" + month.toString();
    }else{
     writemonth = month.toString();
    }
    if(day < 10){
     writeday = "0" + day.toString();
    }else{
     writeday = day.toString();
    }
    const hour = hiduke.getHours();
    const minute = hiduke.getMinutes();
    this._leisuretime = mylocker['leisuretime'];
    const devicenumber = this._device.replace(regex, "");
    //const ciphnumber =  9999999999999 * 7717;
    const ciphstring =   "1" + writemonth + writeday+  uid3string ;
    const ciphnumber = parseInt(ciphstring) * 7717;
    // console.log(mylocker);
    this._address = mylocker['address'];
    this._url = mylocker['url'];
    this._urlKey = firebase.auth().currentUser.uid + " " + this._url; 
    this.cleaningURL = mylocker['sendKeyURL'];

    
    const spacersUnit = await this.SpacersUnitsService.getUnitBySpacerId(this._device);
    const spacersUnitCollaboration = spacersUnit.collaboration;

    if (spacersUnitCollaboration.isEnabled === 'true') {
      this.isEnabled = true;
      this.collaborationName = spacersUnitCollaboration.name;
      this.collaborationPhoneNumber = spacersUnitCollaboration.phoneNumber;
      this.collaborationType = spacersUnitCollaboration.type
      const urlKey = 'http://34.225.183.216/spacer/vtuber.php';
    } else {
      this.isEnabled = false;
    }


// sendkeyとmembersのDB削除
   this.currentUrl = this.router.url;
   var cuturl2 = this.currentUrl.substr(this.currentUrl.indexOf('S'));
   if(this.currentUrl.match('SPACER')){
    const uid = firebase.auth().currentUser.uid;
    const oldList = [];
     await this.db
             .object('sendKeyURL/' + cuturl2)
             .remove(); 
await sleep(10000);
      await this.db
             .object("/members/" + uid + "/myLockers/" + cuturl2)
             .remove(); 
       

}



  }

  viewQRCode() {
    const urlKey = 'http://34.225.183.216/spacer/vtuber.php';
    this.dialog.viewQRCodeDialog(urlKey);
  }

  copyText() {
    // We need to create a dummy textarea with the text to be copied in the DOM
    const textArea = document.createElement('textarea');

    // Hide the textarea from actually showing
    textArea.style.position = 'fixed';
    textArea.style.top = '-999px';
    textArea.style.left = '-999px';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    // Set the texarea's content to our value defined in our [text-copy] attribute
    // if (this.text.match("undefined")) {
    //   // alert("コピーに失敗しました。再度お試し下さい。");
    //   return;
    // }

    textArea.value = 'https://spacer.live/key/' + this.urlKey;
    document.body.appendChild(textArea);
    // // console.log(textArea.value);

    // This will select the textarea
    textArea.select();

    try {
      // Most modern browsers support execCommand('copy'|'cut'|'paste'), if it doesn't it should throw an error
      const successful = document.execCommand('copy');
      const msg = successful
        ? 'URLの鍵をコピーしました。他の人に共有できます。'
        : '鍵のコピーに失敗しました。';
      // Let the user know the text has been copied, e.g toast, alert etc.
      if (successful) {
        this.dialog.copiedKeyURLDialog();
      } else {
        alert(msg);
      }
    } catch (err) {
      // Tell the user copying is not supported and give alternative, e.g alert window with the text to copy
      // alert("鍵コピーに失敗しました。");
    }

    // Finally we remove the textarea from the DOM
    document.body.removeChild(textArea);
  }

  collaborationDialog() {
    if (this.collaborationType == "cleaning") {
      this.dialog.cleaning(this._urlKey, this._device, this.collaborationName, this.collaborationPhoneNumber);
    } else if (this.collaborationType == "pickup") {
      this.dialog.pickup(this._urlKey, this._device, this.collaborationName, this.collaborationPhoneNumber);
    }
  }

  clickMylocker() {
    this.router.navigateByUrl('mylockers')
  }
}
