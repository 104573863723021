import { Injectable } from '@angular/core';
import { HttpJsonService } from './http-json.service';
import { SecureParamService } from './secure-param.service';
import { environment } from '../environments/environment';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class ReserveCancelApiService {
  private _id;
  constructor(
    private httpJsonService: HttpJsonService,
    private secureParamService: SecureParamService
  ) {}

  set id(id: string) {
    this._id = id.replace(/[^0-9^\.]/g, '');
  }

  get id() {
    return this._id;
  }

  /**
   * 予約キャンセルAPIを呼び出す
   * 予め 予約対象ロッカーのBoxID (例: SPACER301) を設定しておく必要がある
   */
  async reserveCancel() {
    const url = `${environment.apiUrl}reserve_cancel`;
    const uid = firebase.auth().currentUser.uid;
    const random = this.secureParamService.getRandom(); // <- 改ざん検知パラメタ
    const md5_message = this.secureParamService.md5(random, uid, this._id); // <- 改ざん検知パラメタ
    const json = {
      id: this._id,
      uid: uid,
      random: random,
      md5_message: md5_message
    };
    return await this.httpJsonService.postJson(url, json);
  }
}
