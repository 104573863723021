import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MembersRepositoryService } from '../../members-repository-service';
import { FirstPaymentFreeService } from '../../first-payment-free.service';
import { MemberApiService } from '../../member-api.service';
import { DialogService } from '../../dialogs/dialog.service';
import { CommonService } from '../../service/common.service';
import { Router , ActivatedRoute } from '@angular/router';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from "firebase";

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
interface ResJSON {
    success: boolean;  
    data: any;  
}
const baseUrl     : string = 'https://us-central1-locker-c5f2c.cloudfunctions.net/usehistorymarged-4?uid=ZwUFRXckClcBgBEkd2uOFBEOq2z1';
@Component({
  selector: 'app-mylocker-list',
  templateUrl: './mylocker-list.component.html',
  styleUrls: ['./mylocker-list.component.scss'],
})
/**
 * マイロッカーの画面を表す Component 。内部で MyLockerItemComponent を利用しています。
 */
export class MylockerListComponent implements OnInit, AfterViewInit {
  dataList;

  api_url = 'https://us-central1-locker-c5f2c.cloudfunctions.net/usehistorymarged-4?uid=ZwUFRXckClcBgBEkd2uOFBEOq2z1';

  unitnumhairetu: string[] = new Array(100);
  getpricehairetu: string[] = new Array(100);
  unitnumtemp: string;
  private test_data : String[];
  unitnum:string;
  getprice:string;
  setprice:number;
  currentUrl:string;
  constructor(
    private db: AngularFireDatabase,
    private httpClient: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private _members: MembersRepositoryService,
    private dialogService: DialogService,
    private firstPaymentFreeService: FirstPaymentFreeService,
    private memberApiService: MemberApiService,
  ) { }

  async ngOnInit() {

   //URL内にSPACERのgetが存在する時はそれをsendkeyDBと照らし合わせて利用中か否かを判断。その後使ってなければマイロッカーへ登録
   this.currentUrl = this.router.url;
   if(this.currentUrl.match('SPACER')){
    this.unitnumhairetu = this.currentUrl.split('!');
    this.unitnumtemp = this.unitnumhairetu[0];
    this.getpricehairetu = this.unitnumhairetu[1].split('@');
    this.getprice = this.getpricehairetu[0].replace("%2F", "");
    this.setprice = Number(this.getprice);
    var cuturl2 = this.unitnumtemp.substr(this.unitnumtemp.indexOf('S'));
    const uid = firebase.auth().currentUser.uid;
    const oldList = [];
    // cuturl のSPACER番号がDB上のsendkeyURLに無ければ新規と判定 
    this.db.object('sendKeyURL/' + cuturl2).snapshotChanges().pipe(
        map(changes => {
          return changes.payload.val();
        })
      ).subscribe( async (res) => {
       if(res != null){
        if (res["device"] != cuturl2 && uid == res["fromId"]) {
           await this.db
             .object('sendKeyURL/' + cuturl2)
             .set({ device: cuturl2,  fromId:uid,randomURL:cuturl2, price:this.getprice, expired: "2023-08-11 20:05:30" }); 
     
           await this.db
             .object('spacersUnits/999/details/' + cuturl2 + '/price/paidPerH')
             .set({ 0: this.setprice, 1:24 }); 


            await this.db
             .object("/members/" + uid + "/myLockers/" + cuturl2)
             .set({isReserved:"false",address:"",cleaningShopName:"",cleaningShopNumber:"",cleaningURL:"",isCleaning:"", device: cuturl2,sendKeyURL:cuturl2,leisuretime:"004",securitykey:cuturl2,status:"",expired:"2023-08-11 20:03:43",pushHistory:"aa",url:this.currentUrl  }); 
        }else{
            await this.db
             .object('sendKeyURL/' + cuturl2)
             .set({ device: cuturl2,  fromId:uid,randomURL:cuturl2,price:this.getprice, expired: "2023-08-11 20:05:30" ,url:this.currentUrl }); 
            await this.db
             .object('spacersUnits/999/details/' + cuturl2 + '/price/paidPerH')
             .set({ 0: this.setprice, 1:24}); 



            await this.db
             .object("/members/" + uid + "/myLockers/" + cuturl2)
             .set({isReserved:"false",address:"",cleaningShopName:"",cleaningShopNumber:"",cleaningURL:"",isCleaning:"", device: cuturl2,sendKeyURL:cuturl2,leisuretime:"004",securitykey:cuturl2,status:"",expired:"2023-08-11 20:03:43",pushHistory:"aa" ,url:this.currentUrl  }); 
       

         }
     }else{
            await this.db
             .object('sendKeyURL/' + cuturl2)
             .set({ device: cuturl2,  fromId:uid,randomURL:cuturl2,price:this.getprice, expired: "2023-08-11 20:05:30",url:this.currentUrl  }); 
            await this.db
             .object('spacersUnits/999/details/' + cuturl2 + '/price/paidPerH')
             .set({ 0: this.setprice,1:24 }); 


            await this.db
             .object("/members/" + uid + "/myLockers/" + cuturl2)
             .set({isReserved:"false",address:"",cleaningShopName:"",cleaningShopNumber:"",cleaningURL:"",isCleaning:"",device: cuturl2,sendKeyURL:cuturl2,leisuretime:"004",securitykey:cuturl2,status:"",expired:"2023-08-11 20:03:43",pushHistory:"aa"  ,url:this.currentUrl }); 
       


      }
     }); 



   }


await sleep(2000);
     const newList = [];
         // MEMO 同期的にマイロッカーを取得してhtmlで表示。mylocker-itemで処理
     const myLockers = await this._members.getMylockers();
     if (myLockers) {
       Object.keys(myLockers).forEach(async (key) => {
         const item = myLockers[key];
         newList.push(item);
       });
     }
     this.dataList = newList;
     this.memberApiService.login();
    

  }
  async ngAfterViewInit() {
    const shouldAlertPaymentFree = await this.firstPaymentFreeService.shouldAlertPaymentFree();
    if (shouldAlertPaymentFree) {
      alert(this.firstPaymentFreeService.messagePaymentFree);
    }
  }

  // resolveStartAt(val): string {
  //   const startAt = val['reservedTime'];
  //   if (startAt) {
  //     return startAt;
  //   } else {
  //     return val['startAt'];
  //   }
  // }

  clickAboutPayment(event) {
    this.dialogService.aboutPaymentDialog();
  }
}
