import { Component } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { PaymentService } from "../payment.service";

@Component({
  selector: "main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.scss"]
})
export class MainNavComponent {
  displayName: string;

  constructor(
    public dialog: MatDialog,
    private auth: AngularFireAuth,
    private router: Router
  ) {
    this.auth.onAuthStateChanged(user => {
      if (!user) {
        return;
      }
      if (!user.displayName) {
        this.displayName = user.email;
      } else {
        this.displayName = user.displayName;
      }
    });
  }
  openDialog() {
    const dialogRef = this.dialog.open(TicketDialog);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }
  public logout() {
    this.auth.signOut().then(success => {
      this.router.navigate(["/login"]);
      window.location.reload();
    });
  }
}

@Component({
  selector: "ticket-dialog",
  templateUrl: "./ticket-dialog.html",
  styleUrls: ["./ticket-dialog.scss"]
})
export class TicketDialog {
  ticketNum = [1, 2, 3, 4, 5, 10, 15, 20, 30, 50];
  ticket: string;
  displayName: string;
  uid: string;

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    public dialogRef: MatDialogRef<TicketDialog>,
    private pService: PaymentService
  ) {
    this.auth.onAuthStateChanged(user => {
      if (!user) {
        return;
      }
      this.uid = user.uid;
      if (!user.displayName) {
        this.displayName = user.email;
      } else {
        this.displayName = user.displayName;
      }
      alert(
        "2019年11月25日より、Chrome 78をご利用のお客様でチケットを購入できない事象が確認されています。購入できない場合の対処方法は下記の通りです。\n\n[手順 1] Chrome を起動し、右上にあるメニューボタンをタップします。\n[手順 2] メニューの中にある「設定」をタップします。\n[手順 3] 詳細設定の中の「プライバシー」をタップします。\n[手順 4] プライバシーの中にある「ページをプリロードして、閲覧と検索をすばやく行えるようにします」のチェックを外します。"
      );
    });
  }
  public purchase() {
    if (this.ticket !== undefined) {
      this.pService.uid = this.uid;
      this.pService.type = "web";
      this.dialogRef.close();
      this.router.navigate(["/payment"]);
      // window.open('https://api.spacer.live/redirectToPayment/' + this.uid + '/' + this.ticket + '/web' , '_blank');
    } else {
      alert("チケットを選択して下さい");
    }
  }
}
