<h2>クリーニングを依頼する</h2>
<br /><p class="normal-size">(依頼先店での事前登録が必要です。)</p>
<p class="normal-size">依頼先店舗名：{{ cleaningShopName }}</p>
<p class="normal-size">
  店舗電話番号：<a href="tel:{{ cleaningShopNumber }}"
    >{{ cleaningShopNumber }}</a
  >
</p>
<br /><p class="normal-size">返却希望日をご選択下さい</p><br />
<form>
  <select class="select-btn" name="day" [(ngModel)]="day">
    <option *ngFor="let item of days" [value]="item">{{item}}</option>
  </select>
  <br /><br /><br />
  <button class="large-black" (click)="request()">依頼する</button>
</form>
