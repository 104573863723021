import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SpacerWebappAuthenticationService } from "./services/spacer-webapp-authentication-service";
import { RegistPhoneNumComponent } from "../regist-phone-num/regist-phone-num.component";
import { RegistPhoneNumConfComponent } from "../regist-phone-num-conf/regist-phone-num-conf.component";
// TODO DELETE(不要なため) ここから
import { ChangePhoneNumConfComponent } from "../change-phone-num-conf/change-phone-num-conf.component";
import { ChangePhoneNumComponent } from "../change-phone-num/change-phone-num.component";
import { UnsubscribeComponent } from "../unsubscribe/unsubscribe.component";
// TODO DELETE(不要なため) ここまで

// MEMO 電話番号認証に関わる機能をまとめたモジュールです。
// 現時点で、ソースコード上にGoogle や Facebook による認証を
// 行う機能が残っています。できればそれらを
// このモジュールへ移行したいのですが、現状未着手です。
@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  declarations: [
    RegistPhoneNumComponent,
    RegistPhoneNumConfComponent,
    // ChangePhoneNumComponent,
    // ChangePhoneNumComponent,
    // UnsubscribeComponent
  ],
  providers: [SpacerWebappAuthenticationService],
  exports: [
    RegistPhoneNumComponent,
    // ChangePhoneNumComponent,
    // UnsubscribeComponent
  ]
})
export class AuthenticationModule {}
