
    <div id="regist-phone">
<br>
<br>
</div>
<div *ngIf='urlKey'>
<br>
<meta http-equiv="refresh" content="1;URL=http://34.225.183.216/spacer/vtuber.php?device={{ device }}_{{urlKey}}">
決済ありがとうございました。
手続き中です..このままお待ちください。
</div>
